import {PermissionStatus} from 'react-native-permissions';
import {MotionActivityEvent} from 'react-native-background-geolocation-android';
import type {
  Birthday,
  Contact,
  EmailAddress,
  InstantMessageAddress,
  PhoneNumber,
  PostalAddress,
} from 'react-native-contacts';
import {
  Entity,
  Column,
  PrimaryColumn,
  OneToOne,
  JoinColumn,
  ColumnOptions,
  OneToMany,
  ManyToOne,
  PrimaryGeneratedColumn,
} from 'typeorm';
import {FirebaseFirestoreTypes} from '@react-native-firebase/firestore';
import {FieldValue} from '@firebase/firestore';

// TypeORM column definition for typescript number w/"milliseconds since unix epoch" to timestamps
export interface ServerTimestamp {
  // FIXME this is really firebase Timestamp type, no?
  _seconds: number;
  _nanoseconds: number;
  seconds: number;
  nanoseconds: number;
}

const timestampColumnBlock: ColumnOptions = {
  type: 'datetime',
  nullable: true,
  transformer: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    from(dbValue: Date | null): number | undefined {
      return undefined;
    },
    to(value: string | number | Birthday | ServerTimestamp): Date | null {
      if (value) {
        if (typeof value === 'number') {
          return new Date(value);
        }
        if (typeof value === 'string') {
          return new Date(parseInt(value, 10));
        }
        if ((<ServerTimestamp>value)._seconds && (<ServerTimestamp>value)._nanoseconds) {
          const timestamp = value as ServerTimestamp;
          return new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000);
        }
        // must be a Birthday then?
        if ((<Birthday>value).year && (<Birthday>value).month && (<Birthday>value).day) {
          const birthday = value as Birthday;
          return new Date(birthday.year, birthday.month - 1, birthday.day);
        }
      }
      return null;
    },
  },
};

// TypeORM column definition that fixes bad data where a promise came in, instead of a number
const safeBigIntColumnBlock: ColumnOptions = {
  type: 'bigint',
  nullable: true,
  transformer: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    from(dbValue: number | null): number | undefined {
      return undefined;
    },
    to(value: number | undefined): number | null {
      if (value && typeof value === 'number') {
        return value;
      }
      return null;
    },
  },
};

// TypeORM column definition that maps all our permission stuff into a string
const permissionStatusColumnBlock: ColumnOptions = {
  type: 'text',
  nullable: true,
  transformer: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    from(dbValue: string): string | undefined {
      return undefined;
    },
    to(value: boolean | PermissionResult): string | null {
      if (value === undefined) {
        return null;
      }
      if (typeof value === 'boolean') {
        if (value) {
          return 'true';
        }
        return 'false';
      }
      return value;
    },
  },
};

// TypeORM column definition that maps all our permission stuff into a string
const telephonyColumnBlock: ColumnOptions = {
  type: 'text',
  nullable: true,
  transformer: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    from(dbValue: string): string | undefined {
      return undefined;
    },
    to(value: TelephonyEventInfo): string | null {
      if (value === undefined) {
        return null;
      }
      return value.callState;
    },
  },
};

const arrayCountColumnBlock: ColumnOptions = {
  type: 'int',
  nullable: true,
  transformer: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    from(dbValue: []): number | undefined {
      return undefined;
    },
    to(value: [] | undefined): number | null {
      if (value) {
        return value.length;
      }
      return null;
    },
  },
};

export const enum UserType {
  Admin = 'Admin',
  User = 'User',
  Business = 'Business',
}

export const enum TransactionType {
  CalculateReport = 'CalculateReport',
  PurchaseReport = 'PurchaseReport',
  PurchaseExpiration = 'PurchaseExpiration',
}

// FIXME verify MySQL structure from new BillingTransaction object
export class BillingTransaction {
  @PrimaryGeneratedColumn() ormID?: number;
  @Column('string') type!: TransactionType;
  @Column('string') id!: string;
  @Column({...timestampColumnBlock, type: 'date'}) date!: number; // ms since start of unix epoch
  @Column({...timestampColumnBlock, type: 'date'}) expiry?: number; // ms since start of unix epoch
  @Column('number') quantity!: number;
  @Column('number') currentBalance?: number; // a running balance up to and including this transaction

  // FIXME join these and pull the object in?
  @Column('string') kullkiIdBy!: string; // The account creating the transaction "*ICESA* is calculating a score..."
  @Column('string') kullkiIdFor!: string; // The subject of the txn "...calculating *Mike*'s score."
  @Column('string') kullkiIdOnBehalfOf?: string; // The account finally responsible, "*Branch manager Mike* is calculating on behalf of Diatom..."

  // FIXME this makes this a bit of a polynomial type. Attempt to de-reference?
  @Column('string') relatedDocument?: string;
  @Column('string') note?: string;
}

// FIXME verify MySQL structure resulting from new DelegatedUser object.
export class DelegatedUser {
  @PrimaryGeneratedColumn() ormID?: number;
  @ManyToOne(() => ExportedUser, User => User.delegatedUsers)
  @Column('string')
  delegatingUser?: string;

  @Column('string') kullkId?: string;
  @Column('boolean', {nullable: true}) active?: boolean;
  @Column('text', {nullable: true}) label?: string;
  @Column('number', {nullable: true}) scoreCount?: number;
  @Column('number', {nullable: true}) scoreBalance?: number;
}

export class User {
  @PrimaryColumn('text') kullkiId: string; // a UUIDv4 string for unique user identification
  @Column('text', {nullable: true}) email?: string; // optional in the type but can be considered a requirement / an error if missing
  @Column('boolean', {nullable: true}) emailVerified?: boolean; // optional indicating if they confirmed an email
  @Column('text', {nullable: true}) contactEmail?: string; // optional contact email for an account. Used for connection requests
  @Column('text', {nullable: true}) phoneNumber?: string; // optional phone number, only available if user linked a phone
  @Column('text', {nullable: true}) firstName?: string;
  @Column('text', {nullable: true}) secondName?: string;
  @Column('text', {nullable: true}) profileName?: string; // optional profile name for profile page handle
  @Column('text', {nullable: true}) citizenNumber?: string;
  @Column({...timestampColumnBlock, type: 'date'}) dateOfBirth?: number;
  @Column('text', {nullable: true}) profileEmoji?: string;
  @Column(timestampColumnBlock) registerTime?: number; // Firebase-provided information
  @Column('text') fbAuthId: string; // The firebase-internal / firebase-controlled ID for the user, from the auth database
  @Column('text', {nullable: true}) 'ApiKey-v1'?: string; // API key, if they have one

  // An array of kullkiIds that granted permission to the user.
  // may not be removed on delete, so check for existence first
  @Column('simple-array', {nullable: true}) connectedUsers?: string[] = [];

  // An array of kullkiIds that may obtain permissions via delegation from this user
  // @Column('simple-array', {nullable: true}) delegatedUsers?: string[] = [];
  @OneToMany(() => DelegatedUser, delegatedUser => delegatedUser.delegatingUser)
  delegatedUsers?: DelegatedUser[];

  // The roles for this account. Defaults to Person if not present.
  @Column('simple-array', {nullable: true}) roles?: UserType[] = [];

  // a map of firebase.iid() device ids to cloud message tokens for user
  // may not be removed on delete, so check for existence first
  @Column('simple-json', {nullable: true}) fcmTokenMap?: {
    [key: string]: string;
  } = {};

  // These are ephemeral (not stored in firebase) convenience/cached items
  // If you add things here, also alter UserStore.cleanUserBeforeSaving
  // To make sure they do not actually end up in firestore
  // You can set these and use them but you should always expect to them
  // to be unset and be prepared to re-generate them
  dateOfBirthFormatted?: string;
  registerTimeFormatted?: string;
  tipi?: TIPI;
  demographics?: Demographics[];
  canCalculate?: string;
  currentScore?: KSocialScore;
  latestDeviceEvent?: DeviceEvent;
  profilePowerScore?: number;
  delegatingUsers?: User[];

  constructor(kullkiId: string, email: string, fbAuthId: string, delegatedUsers?: DelegatedUser[]) {
    this.kullkiId = kullkiId;
    this.email = email;
    this.fbAuthId = fbAuthId;
    this.emailVerified = false; // false by default
    if (delegatedUsers !== undefined) {
      this.delegatedUsers = delegatedUsers;
    }
  }
}

@Entity()
export class TIPIAnswer {
  @PrimaryGeneratedColumn() id?: number;
  @ManyToOne(() => TIPI, tipi => tipi.answers)
  tipi?: TIPI;

  @Column('number') key: number = -1;
  @Column('number') answer: number = -1;
}

@Entity()
export class TIPI {
  @PrimaryColumn('text') kullkiId?: string;
  @Column(timestampColumnBlock) completionTime?: number;

  @OneToMany(() => TIPIAnswer, tipiAnswer => tipiAnswer.tipi, {cascade: true})
  answers: TIPIAnswer[];
  constructor(answers: TIPIAnswer[]) {
    this.answers = answers;
  }
}

@Entity()
export class ScoreBasis {
  @PrimaryGeneratedColumn() ormID?: number;

  @JoinColumn({name: 'KSocialScore', referencedColumnName: 'ormID'})
  ksocialScore?: KSocialScore;

  @Column('boolean', {nullable: true}) contactsAuthorized?: boolean;
  @Column('boolean', {nullable: true}) eventsAuthorized?: boolean;
  @Column('boolean', {nullable: true}) appsAuthorized?: boolean;
  @Column('number', {nullable: true}) gpsLocationCount?: number;
  @Column('number', {nullable: true}) eventStreamCount?: number;
  @Column('boolean', {nullable: true}) tipiDone?: boolean;
  @Column('number', {nullable: true}) demographicsSnapshotsRecorded?: number;
  @Column('string', {nullable: true}) authPhoneNumber?: string;

  @Column(timestampColumnBlock) appsLastUpload?:
    | ServerTimestamp
    | FirebaseFirestoreTypes.Timestamp
    | Date
    | FieldValue; // millis since unix epoch reported by google when servers received event - may be delayed from device

  @Column(timestampColumnBlock) contactsLastUpload?:
    | ServerTimestamp
    | FirebaseFirestoreTypes.Timestamp
    | Date
    | FieldValue; // millis since unix epoch reported by google when servers received event - may be delayed from device

  @Column(timestampColumnBlock) deviceInfoLastUpload?:
    | ServerTimestamp
    | FirebaseFirestoreTypes.Timestamp
    | Date
    | FieldValue; // millis since unix epoch reported by google when servers received event - may be delayed from device
  @Column(timestampColumnBlock) eventsLastUpload?:
    | ServerTimestamp
    | FirebaseFirestoreTypes.Timestamp
    | Date
    | FieldValue; // millis since unix epoch reported by google when servers received event - may be delayed from device
  @Column(timestampColumnBlock) permissionsLastUpload?:
    | ServerTimestamp
    | FirebaseFirestoreTypes.Timestamp
    | Date
    | FieldValue; // millis since unix epoch reported by google when servers received event - may be delayed from device

  @OneToMany(() => DeviceInfo, deviceInfo => deviceInfo.scoreBasis, {
    cascade: true,
  })
  deviceInfos?: DeviceInfo[];

  @Column('simple-array', {nullable: true}) eventStreamHistogram?: number[];
  @Column('simple-array', {nullable: true}) gpsLocationHistogram?: number[];
}

@Entity()
export class ScoreResults {
  @PrimaryGeneratedColumn() ormID?: number;

  @JoinColumn({name: 'KSocialScore', referencedColumnName: 'ormID'})
  ksocialScore?: KSocialScore;

  @Column('number', {nullable: true}) Ovalue?: number;
  @Column('number', {nullable: true}) Cvalue?: number;
  @Column('number', {nullable: true}) Evalue?: number;
  @Column('number', {nullable: true}) Avalue?: number;
  @Column('number', {nullable: true}) stabilityValue?: number;
  @Column('number', {nullable: true}) impulseCntrl?: number;
  @Column('number', {nullable: true}) interest?: number;
  @Column('number', {nullable: true}) interaction?: number;
  @Column('number', {nullable: true}) learning?: number;
}

@Entity()
export class DeviceInfo {
  @PrimaryGeneratedColumn() ormID?: number;
  eventStream?: DeviceEvent[];
  deviceInstance?: DeviceInstance;

  // Not always attached to a ScoreBasis, but after calculating, all DeviceInfos related to the score are attached
  @ManyToOne(() => ScoreBasis, scoreBasis => scoreBasis.deviceInfos)
  scoreBasis?: ScoreBasis;

  @Column('boolean', {nullable: true}) isLocationEnabled?: boolean;
  @Column('number', {nullable: true}) APILevel?: number;
  @Column('text', {nullable: true}) IPAddress?: number;
  @Column('text', {nullable: true}) model?: string;
  @Column('text', {nullable: true}) bundleId?: string;
  @Column('text', {nullable: true}) product?: string;
  @Column('text', {nullable: true}) host?: string;
  @Column('text', {nullable: true}) codename?: string;
  @Column('text', {nullable: true}) tags?: string;
  @Column('text', {nullable: true}) securityPatch?: string;
  @Column('text', {nullable: true}) fingerprint?: string;
  @Column('text', {nullable: true}) deviceToken?: string;
  @Column('text', {nullable: true}) type?: string;
  @Column('text', {nullable: true}) androidId?: string;
  @Column('text', {nullable: true}) display?: string;
  @Column('text', {nullable: true}) bootloader?: string;
  @Column('text', {nullable: true}) incremental?: string;
  @Column('text', {nullable: true}) baseOS?: string;
  @Column('text', {nullable: true}) previewSdkInt?: string;
  @Column('text', {nullable: true}) hardware?: string;
  @Column('simple-json', {nullable: true}) powerState?: {
    [key: string]: boolean | number | string;
  };
  @Column('boolean', {nullable: true}) carrierAllowsVOIP?: boolean;
  // @Column('text', { nullable: true }) powerState?: string;
  @Column(safeBigIntColumnBlock) freeDiskStorage?: number;
  @Column('simple-array', {nullable: true})
  getSystemAvailableFeatures?: string[];
  @Column('text', {nullable: true}) uniqueID?: string;
  @Column('text', {nullable: true}) userAgent?: string;
  @Column('bigint', {nullable: true}) totalMemory?: number;
  @Column('text', {nullable: true}) phoneNumber?: string;
  @Column('boolean', {nullable: true}) isCameraPresent?: boolean;
  @Column('float', {nullable: true}) batteryLevel?: number;
  @Column('text', {nullable: true}) version?: string;
  @Column('simple-json', {nullable: true}) localizeBestAvailableLanguageEN?: {
    [key: string]: string;
  };
  @Column('text', {nullable: true}) deviceCountry?: string;
  @Column('text', {nullable: true}) deviceId?: string;
  @Column('text', {nullable: true}) buildNumber?: number;
  @Column('text', {nullable: true}) deviceName?: string;
  @Column('text', {nullable: true}) timezone?: string;
  @Column('text', {nullable: true}) carrierMobileNetworkOperator?: string;
  @Column('text', {nullable: true}) deviceType?: string;
  @Column('text', {nullable: true}) systemVersion?: string;
  @Column('number', {nullable: true}) maxMemory?: number;
  @Column('simple-json', {nullable: true}) locationFeatures?: {
    [key: string]: boolean;
  };
  @Column('simple-array', {nullable: true}) localizeCurrencies?: string[];
  @Column('text', {nullable: true}) installReferrer?: string;
  @Column('boolean', {nullable: true}) hasNotch?: boolean;
  @Column('simple-json', {nullable: true}) localizeLocales?: {
    [key: string]: string | boolean;
  }[];
  @Column('text', {nullable: true}) instanceID?: string;
  @Column('text', {nullable: true}) manufacturer?: string;
  @Column('simple-json', {nullable: true}) localizeNumberFormatSettings?: {
    [key: string]: string;
  };
  @Column('text', {nullable: true}) carrierName?: string;
  @Column('text', {nullable: true}) carrierMobileCountryCode?: string;
  @Column('boolean', {nullable: true}) isBatteryCharging?: boolean;
  @Column('text', {nullable: true}) readableVersion?: string;
  @Column('text', {nullable: true}) brand?: string;
  @Column('boolean', {nullable: true}) localizeUse24HourClock?: boolean;
  @Column('boolean', {nullable: true}) localizeUsesMetricSystem?: boolean;
  @Column('text', {nullable: true}) localizeTemperatureUnit?: string;
  @Column('text', {nullable: true}) MACAddress?: string;
  @Column('boolean', {nullable: true}) isPinOrFingerprintSet?: boolean;
  @Column('boolean', {nullable: true}) isTablet?: boolean;
  @Column('text', {nullable: true}) localizeTimeZone?: string;
  @Column('text', {nullable: true}) carrier?: string;
  @Column('simple-array', {nullable: true}) supportedABIs?: string[];
  @Column('text', {nullable: true}) systemName?: string;
  @Column('text', {nullable: true}) carrierISOCountryCode?: string;
  @Column('text', {nullable: true}) serialNumber?: string;
  @Column('boolean', {nullable: true}) isAirplaneMode?: boolean;
  @Column(timestampColumnBlock) firstInstallTime?: number;
  @Column('text', {nullable: true}) localizeCalendar?: string;
  @Column('boolean', {nullable: true}) isLandscape?: boolean;
  @Column('float', {nullable: true}) fontScale?: number;
  @Column('text', {nullable: true}) deviceLocale?: string;
  @Column('boolean', {nullable: true}) isEmulator?: boolean;
  @Column('simple-json', {nullable: true}) localizeBestAvailableLanguageES?: {
    [key: string]: boolean | string;
  };
  @Column('bigint', {nullable: true}) totalDiskCapacity?: number;
  @Column(timestampColumnBlock) lastUpdateTime?: number;
  @Column('text', {nullable: true}) carrierMobileNetworkCode?: string;
  @Column('simple-array', {nullable: true}) preferredLocales?: string[];
  @Column('text', {nullable: true}) localizeCountry?: string;
  @Column('text', {nullable: true}) id?: string;
  @Column('simple-array', {nullable: true}) eventStreamHistogram?: number[]; // events-by-day for last 6 weeks
  @Column('simple-json', {nullable: true}) gpsLocationHistogram?: number[]; // events-by-day for last 6 weeks
  @Column('number', {nullable: true}) eventStreamCount?: number;
  @Column('number', {nullable: true}) gpsLocationCount?: number;
}

export interface Permission {
  key: string;
  value: PermissionStatus;
}

@Entity()
export class KSocialScore {
  @PrimaryGeneratedColumn() ormID?: number;
  @ManyToOne(() => ExportedUser, user => user.scoreSnapshotsORM)
  @JoinColumn({name: 'kullkiId', referencedColumnName: 'kullkiId'})
  @Column('string')
  kullkiId: string;

  @Column('string') ksocial: string;
  @Column('string', {nullable: true}) ksocialAdjusted?: string;
  @Column({...timestampColumnBlock, nullable: false}) timestamp: number;
  timestampFormatted?: string;
  error?: string;
  description?: string;
  @Column('string', {nullable: true}) 'Correlation-ID'?: string;

  @Column('simple-json', {nullable: true}) scoreHistory?: {[key: number]: string};

  @OneToOne(() => ScoreBasis, {cascade: true})
  @JoinColumn()
  scoreBasis?: ScoreBasis; // information about data used to calculate the score

  @OneToOne(() => ScoreResults, {cascade: true})
  @JoinColumn()
  scoreResults?: ScoreResults; // information about the various subcomponents of the score

  constructor(kullkiId: string, ksocial: string, timestamp: number) {
    this.kullkiId = kullkiId;
    this.ksocial = ksocial;
    this.timestamp = timestamp;
  }
}

export type QueueStatus = 'requested' | 'in-progress' | 'failed' | 'complete';
export class ScoreBatch {
  id?: string;
  name?: string;
  createdDate?: number;
  createdDateFormatted?: string; // ephemeral
  createdBy?: string; // kullkiId
  validation?: QueueStatus;
  calculation?: QueueStatus;
}

export type DemographicsCivilStatus = 'married' | 'divorced' | 'single' | 'other relationship';
export type DemographicsHousing = 'own with mortgage' | 'own no mortgage' | 'rent' | 'other';
export type DemographicsEducation =
  | 'primary'
  | 'secondary'
  | 'technical'
  | 'bachelors'
  | 'postgraduate';
export type DemographicsGender = 'male' | 'female';

@Entity()
export class Demographics {
  @ManyToOne(() => ExportedUser, user => user.scoreSnapshotsORM)
  @JoinColumn({name: 'kullkiId', referencedColumnName: 'kullkiId'})
  @PrimaryColumn('string')
  kullkiId: string;
  @PrimaryColumn({...timestampColumnBlock, nullable: false}) timestamp: number;
  @Column('boolean', {nullable: true}) auto?: boolean;
  @Column('string', {nullable: true}) civilStatus?: DemographicsCivilStatus;
  @Column('string', {nullable: true}) gender?: DemographicsGender;
  @Column('number', {nullable: true}) children?: number;
  @Column('number', {nullable: true}) salaryLowerBound?: number;
  @Column('number', {nullable: true}) salaryUpperBound?: number;
  @Column('string', {nullable: true}) housing?: DemographicsHousing;
  @Column('string', {nullable: true}) education?: DemographicsEducation;
  @Column('boolean', {nullable: true}) creditCard?: boolean;

  constructor(kullkiId: string, timestamp: number) {
    this.kullkiId = kullkiId;
    this.timestamp = timestamp;
  }
}

@Entity()
export class DeviceInstance {
  @PrimaryGeneratedColumn() ormID?: number;
  @Column('text', {nullable: true}) manufacturer?: string;
  @Column('text', {nullable: true}) brand?: string;
  @Column('text', {nullable: true}) model?: string;
  @Column('text', {nullable: true}) product?: string; // android-specific
  @Column('text', {nullable: true}) androidSdkInt?: number; // android-specific operating system version (e.g. 29 for current)
  @Column('text', {nullable: true}) iosVersion?: string; // ios-specific operating system version (e.g. '13.4.1')
  @Column('boolean', {nullable: true}) isPinOrFingerprintSet?: boolean; // whether the user has a screen lock
  @Column('text', {nullable: true}) appVersion?: string; // our internal app version
  @Column(timestampColumnBlock) appInstallTime?: number; // android-specific millis since unix epoch app was installed
  @Column(timestampColumnBlock) appUpdateTime?: number; // android-specific millis since unxi epoch since app was last updated
  @Column('text', {nullable: true}) kullkiId?: string;
  @Column('text', {nullable: true}) id?: string;
  @Column(timestampColumnBlock) timestamp?:
    | ServerTimestamp
    | FirebaseFirestoreTypes.Timestamp
    | Date; // millis since unix epoch reported by device when event happened - under user control
  @Column(timestampColumnBlock) serverTimestamp?:
    | ServerTimestamp
    | FirebaseFirestoreTypes.Timestamp
    | Date
    | FieldValue; // millis since unix epoch reported by google when servers received event - may be delayed from device
  @Column(timestampColumnBlock) lastCullTimestamp?:
    | ServerTimestamp
    | FirebaseFirestoreTypes.Timestamp
    | Date
    | FieldValue; // millis since unix epoch when deviceInstance was last examined for data expiration
  @Column('simple-array', {nullable: true}) bluetoothPairedDevices?: string[]; // android-specific, array of bluetooth device names, under user control
}

@Entity()
export class BluetoothEventInfo {
  @PrimaryGeneratedColumn() ormID?: number;

  @Column('boolean', {nullable: true}) bluetoothEnabled?: boolean; // android-specific, whether bluetooth is enabled
  @Column('text', {nullable: true}) bluetoothDevice?: string; // android-specific, current bluetooth device name (if any), under user control
}

@Entity()
export class NetInfoStateDetailsORM {
  @PrimaryGeneratedColumn() ormID?: number;
  @JoinColumn({name: 'NetInfoStateORM', referencedColumnName: 'ormID'})
  netInfoState?: NetInfoStateORM;

  @Column('text', {nullable: true}) ipAddress?: string | null;
  @Column('text', {nullable: true}) subnet?: string | null;
  @Column('text', {nullable: true}) ssid?: string | null;
  @Column('text', {nullable: true}) bssid?: string | null;
  @Column('float', {nullable: true}) strength?: number | null;
  @Column('float', {nullable: true}) frequency?: number | null;
  @Column('text', {nullable: true}) cellularGeneration?: string | null;
  @Column('text', {nullable: true}) carrier?: string | null;
  @Column('boolean', {nullable: true}) isConnectionExpensive?: boolean;
}

@Entity()
export class NetInfoStateORM {
  @PrimaryGeneratedColumn() ormID?: number;
  @JoinColumn({name: 'DeviceEvent', referencedColumnName: 'ormID'})
  deviceEvent?: DeviceEvent;

  @Column('text', {nullable: true}) backgroundRefresh?: string;
  @Column('text', {nullable: true}) type?: string;
  @Column('boolean', {nullable: true}) isConnected?: boolean | null;
  @Column('boolean', {nullable: true}) isInternetReachable?: boolean | null;

  @OneToOne(() => NetInfoStateDetailsORM, {cascade: true})
  @JoinColumn()
  details?: NetInfoStateDetailsORM | null; // information about the power status of the device
}

export type PowerEventRunMode =
  | 'unknown' // if for some reason we cannot determine runMode
  | 'interactive' // user is interacting with device (screen might be off though, e.g. phone call next to ear)
  | 'idle' // device is not being used, might be restricting power usage etc
  | 'idleRunWindow'; // user is not interacting, but apps are allowed a window to perform tasks

export type PowerEventPlugged =
  | 'unknown' // if for some reason we cannot determine plugged state
  | 'unplugged' // device is not charging
  | 'charging' // charging from unknown power
  | 'ac' // device is charging high power
  | 'usb' // device is charging low power
  | 'wireless' // device is charging wirelessly
  | 'full'; // battery no longer charging because full, also check if batteryPercent === 100

export type PowerEventStatus =
  | 'unknown' // if for some reason we cannot determine power status
  | 'notCharging' // power is simply not charging
  | 'discharging' // device is consuming battery charge
  | 'full'; // battery is full

export type PowerEventBatteryHealth =
  | 'unknown' // battery health cannot be determined
  | 'cold' // battery is notably cold, performance will be degraded, damage possible
  | 'dead' // battery is unusably dead
  | 'good' // battery is normal
  | 'overVoltage' // battery is showing signs of incorrect charging
  | 'overheat' // battery is notably hot, performance degraded, damage possible
  | 'unspecifiedFailure'; // exactly what it says

@Entity()
export class PowerEventInfo {
  @PrimaryGeneratedColumn() ormID?: number;
  @Column('text', {nullable: true}) runMode?: PowerEventRunMode; // android-specific, detailed above
  @Column('number', {nullable: true}) locationPowerSaveMode?: number; // android-specific, if power save mode is on, how location updates will change
  @Column('boolean', {nullable: true}) restricted?: boolean; // android-specific / iOS always restricted, whether the app will have background tasks restricted in low power mode
  @Column('number', {nullable: true}) batteryPercent?: number; // integer 0-100 representing battery percent
  @Column('text', {nullable: true}) plugged?: PowerEventPlugged; // Plug status, and for iOS general power status defined separately
  @Column('text', {nullable: true}) status?: PowerEventStatus; // android-specific general power status defined separately
  @Column('text', {nullable: true}) batteryHealth?: PowerEventBatteryHealth; // android-specific, defined separately
  @Column('boolean', {nullable: true}) lowPowerMode?: boolean; // If the user or system engaged system wide power restriction. Compare with 'restricted' value to see if it applies to our app
}

export type EventLocationProvider =
  | 'unknown' // unable to determine the provider for some reason
  | 'gps' // most accurate provider, should have accuracy between 15-60m
  | 'network'; // cell tower / wifi locations, can be quite inaccurate, be careful using points from this

@Entity()
export class EventLocation {
  @PrimaryGeneratedColumn() ormID?: number;
  @JoinColumn({name: 'LocationEventInfo', referencedColumnName: 'ormID'})
  locationEventInfo?: LocationEventInfo;

  @Column('number', {nullable: true}) accuracy?: number; // radius in meters defining area point is in, be careful when using inaccurate points (>1000m from wifi etc)
  @Column('float', {nullable: true}) latitude?: number;
  @Column('float', {nullable: true}) longitude?: number;
  @Column('number', {nullable: true}) velocity?: number; // sometimes not available
  @Column('number', {nullable: true}) altitude?: number; // sometimes not available
  @Column('string', {nullable: true}) provider?: EventLocationProvider; // source of the event, if known
}

export type PermissionResult = 'unavailable' | 'denied' | 'blocked' | 'granted' | 'limited';

@Entity()
export class LocationEventInfo {
  @PrimaryGeneratedColumn() ormID?: number;
  @JoinColumn({name: 'DeviceEvent', referencedColumnName: 'ormID'})
  deviceEvent?: DeviceEvent;

  @Column('boolean', {nullable: true}) enabled?: boolean; // true or false indicating if location services are available
  @Column(permissionStatusColumnBlock) permission?: boolean | PermissionResult; // true/false or more detail indicating persmission status of general location services
  @Column(permissionStatusColumnBlock) backgroundPermission?: boolean | PermissionResult; // true/false or more detail indicating if app has permission to access locations in the background

  @OneToOne(() => EventLocation, {cascade: true})
  @JoinColumn()
  lastKnown?: EventLocation; // the last known location, this is received for some location events but not all
}

// Only available on Android
export type TelephonyCallState =
  | 'unknown' // if cellular call state is unavailable
  | 'idle' // cellular call state is unused / no call is in progress
  | 'offHook' // cellular call is in progress
  | 'ringing'; // incoming cellular call is ringing the device, but call has not been answered

export interface TelephonyEventInfo {
  callState: TelephonyCallState;
}

// Message information about the Firebase Cloud Message that triggered a device event
export interface DeviceEventMessageExtra {
  messageId?: string; // android-specific apparently
  sentTime?: number; // milliseconds since unix epoch cloud message was sent
  sender?: string; // kullkiId of cloud message sender
}

export interface DeviceEventFCMTokenExtra {
  fcmToken: string; // the Firebase Cloud Messaging token of the sender
}

// Some device events come with extra event-specific information
export type DeviceEventExtras =
  | DeviceEventMessageExtra
  | DeviceEventFCMTokenExtra
  | MotionActivityEvent // https://transistorsoft.github.io/react-native-background-geolocation-android/interfaces/_react_native_background_geolocation_android_.motionactivityevent.html
  | null; // no event-specific extras were provided

@Entity()
export class DeviceEvent {
  @PrimaryColumn('string') id?: string;
  @ManyToOne(() => ExportedDeviceData, device => device.eventStream)
  exportedDeviceData?: ExportedDeviceData;

  @OneToOne(() => BluetoothEventInfo, {cascade: true})
  @JoinColumn()
  bluetooth?: BluetoothEventInfo; // android-specific, bluetooth event info

  @OneToOne(() => PowerEventInfo, {cascade: true})
  @JoinColumn()
  powerInfo?: PowerEventInfo; // information about the power status of the device

  @OneToOne(() => LocationEventInfo, {cascade: true})
  @JoinColumn()
  location?: LocationEventInfo; // information about the power status of the device

  @OneToOne(() => NetInfoStateORM, {cascade: true})
  @JoinColumn()
  net?: NetInfoStateORM;

  @Column(telephonyColumnBlock) telephony?: TelephonyEventInfo; // android-specific, information about cellular call status
  // Note 'backgroundRefresh' in net object indicates if we are allowed to perform data transfers in the background

  @Column('simple-json', {nullable: true}) extras?: DeviceEventExtras; // event-specific information, defined separately
  @Column(timestampColumnBlock) timestamp?:
    | ServerTimestamp
    | FirebaseFirestoreTypes.Timestamp
    | Date; // millis since unix epoch event ocurred in device time, under user control
  @Column(timestampColumnBlock) serverTimestamp?:
    | ServerTimestamp
    | FirebaseFirestoreTypes.Timestamp
    | Date
    | FieldValue; // millis since unix epoch that firebase received event, potentially delayed from actual event happening

  // Mostly self-explanatory, but best definitions are the constants here:
  // https://developer.android.com/reference/android/content/Intent
  // Additionally there are these types of most interest:
  //   BACKGROUND_FETCH - periodic task that may wake the iOS app (a rare thing!), and runs on a timer
  //   DIRECT_MESSAGE - the device received a Firebase Cloud Message, either from us or another user
  //   SCREEN_TURNING_ON|OFF - should be on/off pair though event may be missed
  //   USER_PRESENT - this means the user unlocked the screen
  //   BOOT of any type means the device was restarted
  //   POWER_STATE - low power mode was engaged or disengaged
  //   MOTION_ACTIVITY - the motion sensors triggered, may be associated with location, may not
  //   CONNECTIVITY_CHANGE - something changed about the network status, consult net object for current state
  @Column('text', {nullable: true}) type?: string;

  // These are ephemeral, it is calculated and cached for display purposes
  serverTimestampFormatted?: string;
}

@Entity()
export class AppEntry {
  @ManyToOne(() => ExportedDeviceData, device => device.appsORM)
  exportedDeviceData?: ExportedDeviceData;
  @PrimaryGeneratedColumn() id?: number;

  @Column(timestampColumnBlock) lastUpdateTime?: number;
  @Column('string') name?: string;
  @Column(timestampColumnBlock) firstInstallTime?: number;
}

@Entity()
export class PermissionSnapshot {
  @PrimaryGeneratedColumn() id?: number;
  @Column({...timestampColumnBlock, nullable: false}) timestamp?: number;

  @ManyToOne(() => ExportedDeviceData, device => device.permissionSnapshots)
  exportedDeviceData?: ExportedDeviceData; // mapped in from TypeORM

  @OneToMany(() => PermissionEntry, permissionEntry => permissionEntry.permissionSnapshot, {
    cascade: true,
  })
  permissions?: PermissionEntry[]; // processed from JSON before sending into ORM
}

// The JSON entry is just an array mapped into object, but TypeORM needs more info
export interface PermissionSnapshotJSON {
  [key: string]: PermissionEntry;
}

@Entity()
export class PermissionEntry {
  @PrimaryGeneratedColumn() id?: number;
  @ManyToOne(() => PermissionSnapshot, permissionSnapshot => permissionSnapshot.permissions)
  permissionSnapshot?: PermissionSnapshot;

  @Column('string') key?: string;
  @Column('boolean') value?: boolean;
}

@Entity()
export class ContactORM implements Contact {
  @PrimaryGeneratedColumn() id?: number;
  @ManyToOne(() => ExportedDeviceData, device => device.contacts)
  exportedDeviceData?: ExportedDeviceData;

  recordID = '';
  backTitle = '';
  @Column('text', {nullable: true}) company = '';
  @Column(arrayCountColumnBlock) emailAddresses: EmailAddress[] = [];
  @Column('text', {nullable: true}) familyName = '';
  @Column('text', {nullable: true}) givenName = '';
  @Column('text', {nullable: true}) middleName = '';
  @Column('text', {nullable: true}) displayName = '';
  @Column('text', {nullable: true}) jobTitle = '';
  @Column('boolean', {nullable: true}) isStarred: boolean = false;
  @Column(arrayCountColumnBlock) phoneNumbers: PhoneNumber[] = [];
  hasThumbnail = false;
  thumbnailPath = '';
  @Column(arrayCountColumnBlock) postalAddresses: PostalAddress[] = [];
  prefix = '';
  suffix = '';
  department = '';
  @Column(timestampColumnBlock) birthday: Birthday;
  @Column(arrayCountColumnBlock) imAddresses: InstantMessageAddress[] = [];
  note = '';
  rawContactId = '';
  @Column(arrayCountColumnBlock) urlAddresses: string[] = [];

  constructor(birthday: Birthday) {
    this.birthday = birthday;
  }
}

@Entity()
export class CalendarEventORM {
  @PrimaryGeneratedColumn() eventId?: number;
  @ManyToOne(() => ExportedDeviceData, device => device.calendarEventsORM)
  exportedDeviceData?: ExportedDeviceData;

  @Column('text', {nullable: true}) location?: string;
  @Column('text', {nullable: true}) description?: string;
  @Column('text', {nullable: true}) title?: string;
  @Column('string') availability: string = 'busy';
  @Column(arrayCountColumnBlock) attendees?: {[key: string]: string}[];
  @Column('string', {nullable: true}) recurrence?: string;
  @Column('timestamp') endDate = '';
  @Column('timestamp') startDate = '';
  @Column('string', {nullable: true}) calendarId?: string;
  @Column('boolean', {nullable: true}) isDetached?: boolean;
  @Column('string', {nullable: true}) url?: string;
  @Column('text', {nullable: true}) notes?: string;
  @Column('boolean') allDay: boolean = false;
  @Column('simple-json', {nullable: true}) recurrenceRule?: {
    [key: string]: string;
  };
  @Column(arrayCountColumnBlock) alarms?: {[key: string]: any}[];
  @Column('simple-json', {nullable: true}) calendar?: {[key: string]: string};
}

@Entity()
export class GPSRecordORM {
  @PrimaryGeneratedColumn() entryId?: number;
  @ManyToOne(() => ExportedDeviceData, device => device.gpsData)
  exportedDeviceData?: ExportedDeviceData;

  @Column('text', {nullable: true}) company_token?: string;
  @Column('text', {nullable: true}) uuid?: string;
  @Column('text', {nullable: true}) device_id?: string;
  @Column('text', {nullable: true}) device_model?: string;
  @Column('float', {nullable: true}) latitude?: number;
  @Column('float', {nullable: true}) longitude?: number;
  @Column('number', {nullable: true}) accuracy?: number;
  @Column('number', {nullable: true}) speed?: number;
  @Column('number', {nullable: true}) heading?: number;
  @Column('float', {nullable: true}) odometer?: number;
  @Column('text', {nullable: true}) event?: string;
  @Column('text', {nullable: true}) activity_type?: string;
  @Column('number', {nullable: true}) activity_confidence?: number;
  @Column('float', {nullable: true}) battery_level?: number;
  @Column('number', {nullable: true}) battery_is_charging?: number;
  @Column('number', {nullable: true}) is_moving?: number;
  @Column('text', {nullable: true}) geofence?: string;
  @Column('text', {nullable: true}) provider?: string;
  @Column('text', {nullable: true}) extras?: string;
  @Column('timestamp', {nullable: true}) recorded_at?: string;
  @Column('timestamp', {nullable: true}) created_at?: string;
}

@Entity()
export class ExportedDeviceData {
  @PrimaryColumn('string') kullkiId?: string;
  @PrimaryColumn('string') id?: string;

  @OneToMany(() => ContactORM, contact => contact.exportedDeviceData, {
    cascade: true,
  })
  contactsORM?: ContactORM[]; // These are processed from JSON before sending into ORM
  contacts?: {[key: string]: ContactORM}; // These are in the exported JSON for calculator etc

  @OneToMany(() => CalendarEventORM, event => event.exportedDeviceData, {
    cascade: true,
  })
  calendarEventsORM?: CalendarEventORM[]; // These are processed from JSON before sending into ORM
  events?: {events: CalendarEventORM[]}; // These are in the exported JSON for calculator etc

  @OneToMany(() => GPSRecordORM, gpsRecord => gpsRecord.exportedDeviceData, {
    cascade: true,
  })
  gpsData?: GPSRecordORM[]; // These are in the exported JSON for calculator etc

  @OneToOne(() => DeviceInfo, {cascade: true})
  @JoinColumn({name: 'DeviceInfo', referencedColumnName: 'ormID'})
  deviceInfo?: DeviceInfo;

  @OneToOne(() => DeviceInstance, {cascade: true})
  @JoinColumn({name: 'DeviceInstance', referencedColumnName: 'ormID'})
  deviceInstance?: DeviceInstance;

  @OneToMany(() => DeviceEvent, deviceEvent => deviceEvent.exportedDeviceData, {
    cascade: true,
  })
  eventStream?: DeviceEvent[]; // These are in the exported JSON for calculator etc

  @OneToMany(() => AppEntry, appEntry => appEntry.exportedDeviceData, {
    cascade: true,
  })
  appsORM?: AppEntry[];
  apps?: {allApps?: AppEntry[]};

  @Column('number', {nullable: true}) permissionsSnapshotsRecorded?: number;
  @OneToMany(
    () => PermissionSnapshot,
    permissionSnapshot => permissionSnapshot.exportedDeviceData,
    {
      cascade: true,
    },
  )
  permissionSnapshotsORM?: PermissionSnapshot[]; // These are processed from JSON before sending into ORM
  permissionSnapshots?: {[key: string]: PermissionSnapshotJSON}; // These are in the exported JSON for calculator etc
}

@Entity()
export class ExportedUser extends User {
  @Column('boolean', {nullable: true}) tipiDone?: boolean;

  @OneToOne(() => TIPI, {cascade: true})
  @JoinColumn({name: 'TIPI', referencedColumnName: 'kullkiId'})
  tipiResults?: TIPI;

  @Column('number', {nullable: true}) scoreSnapshotsRecorded?: number;
  @OneToMany(() => KSocialScore, scoreSnapshot => scoreSnapshot.kullkiId, {
    cascade: true,
  })
  scoreSnapshotsORM?: KSocialScore[]; // These are processed from JSON before sending into ORM
  scoreSnapshots?: {[key: string]: KSocialScore}; // These are in the exported JSON for calculator etc

  @Column('number', {nullable: true}) demographicsSnapshotsRecorded?: number;
  @OneToMany(() => Demographics, demographicsSnapshot => demographicsSnapshot.kullkiId, {
    cascade: true,
  })
  demographicsSnapshotsORM?: Demographics[]; // These are processed from JSON before sending into ORM
  demographicsSnapshots?: {[key: string]: Demographics}; // These are in the exported JSON for calculator etc

  @Column('number', {nullable: true}) devicesAuthorized?: number;
  @OneToMany(() => ExportedDeviceData, exportedDeviceData => exportedDeviceData.kullkiId, {
    cascade: true,
  })
  deviceData?: ExportedDeviceData[];

  @Column(timestampColumnBlock) appsLastUpload?:
    | ServerTimestamp
    | FirebaseFirestoreTypes.Timestamp
    | Date
    | FieldValue; // millis since unix epoch reported by google when servers received event - may be delayed from device

  @Column(timestampColumnBlock) contactsLastUpload?:
    | ServerTimestamp
    | FirebaseFirestoreTypes.Timestamp
    | Date
    | FieldValue; // millis since unix epoch reported by google when servers received event - may be delayed from device

  @Column(timestampColumnBlock) deviceInfoLastUpload?:
    | ServerTimestamp
    | FirebaseFirestoreTypes.Timestamp
    | Date
    | FieldValue; // millis since unix epoch reported by google when servers received event - may be delayed from device
  @Column(timestampColumnBlock) eventsLastUpload?:
    | ServerTimestamp
    | FirebaseFirestoreTypes.Timestamp
    | Date
    | FieldValue; // millis since unix epoch reported by google when servers received event - may be delayed from device
  @Column(timestampColumnBlock) permissionsLastUpload?:
    | ServerTimestamp
    | FirebaseFirestoreTypes.Timestamp
    | Date
    | FieldValue; // millis since unix epoch reported by google when servers received event - may be delayed from device

  @Column('string', {nullable: true}) systemName?: string; // name of the mobile operating system
  @Column('boolean', {nullable: true}) contactsAuthorized?: boolean;
  @Column('boolean', {nullable: true}) eventsAuthorized?: boolean;
  @Column('boolean', {nullable: true}) appsAuthorized?: boolean;
  @Column('number', {nullable: true}) gpsLocationCount?: number;
  @Column('number', {nullable: true}) eventStreamCount?: number;

  eventStreamHistogram?: number[]; // part of KsocialScore.scoreBasis - in JSON for calculator - events-by-day for last 6 weeks
  gpsLocationHistogram?: number[]; // part of KsocialScore.scoreBasis - in JSON for calculator - events-by-day for last 6 weeks

  // FK out to device data
}
