import XDate from 'xdate';
import {useContext} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {Avatar, Button, Headline, Subheading} from 'react-native-paper';
import {useLinkTo} from '@react-navigation/native';

import {useAppSettings} from '../components/AppSettings';
import Hero from '../components/Hero';
import {displayNameFromUser, useScores} from '../hooks/useUserInfo';
import {ScoringInfoContext} from './Stack';
import {format} from '../translations';

export const UserDetailsScreen = () => {
  const linkTo = useLinkTo();
  const scoringInfo = useContext(ScoringInfoContext);
  const user = scoringInfo?.scoringUserInfo?.scoringOnUser;
  const ksocials = useScores(user?.kullkiId);
  const ksocial = ksocials !== undefined && ksocials.length > 0 ? ksocials[0] : undefined;
  const userDisplayName = displayNameFromUser(user);
  const appSettings = useAppSettings();

  return (
    <ScrollView>
      <Hero height={60} style={{backgroundColor: appSettings.currentTheme.colors.primary}} />
      <View style={[styles.content, styles.profile]}>
        {/* {user.photoURL ? (
          <Avatar.Image size={80} source={{uri: user.photoURL}} />
        ) : ( */}
        <Avatar.Text
          size={80}
          label={userDisplayName.substring(0, 2).toLocaleUpperCase()}
          style={[styles.avatar, {borderColor: appSettings.currentTheme.colors.background}]}
        />
        {/* )} */}
      </View>
      <View style={styles.pageContainer}>
        <Headline style={styles.appBarButtonPadding}>{userDisplayName}</Headline>
        <View style={styles.content}>
          {!!user?.citizenNumber && (
            <Subheading>
              {appSettings.t('ProfileCedulaLabel')}: {user.citizenNumber}
            </Subheading>
          )}
          {user?.profileName && user.profileName !== '' && (
            <Subheading>
              {format(appSettings.t('ProfileProfileNameLabel'), [user.profileName])}
            </Subheading>
          )}
          {!!user?.dateOfBirth && (
            <Subheading>
              {`${appSettings.t('ProfileBirthdateLabel')}: ${
                user?.dateOfBirth &&
                (user.dateOfBirthFormatted = new XDate(user.dateOfBirth).toString(
                  appSettings.t('ProfileBirthdateFormat'),
                ))
              }`}
            </Subheading>
          )}
          {!!user?.registerTime && (
            <Subheading>
              {`${appSettings.t('ProfileRegisterDateLabel')}: ${
                user?.registerTime &&
                (user.registerTimeFormatted = new XDate(user.registerTime).toString(
                  appSettings.t('ProfileBirthdateFormat'),
                ))
              }`}
            </Subheading>
          )}
        </View>
        <View style={styles.centeredPadded} />
        <Button
          style={styles.appSettingsButtonStyle}
          mode="contained"
          onPress={() => linkTo('/scoring/score/generate')}>
          {appSettings.t('CalculateButton')}
        </Button>
        {ksocial && (
          <>
            <View style={styles.centeredPadded} />
            <Button
              style={styles.appSettingsButtonStyle}
              mode="contained"
              onPress={() => linkTo('/scoring/score')}>
              {appSettings.t('ShowScore')}
            </Button>
          </>
        )}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  appBarButtonPadding: {
    padding: 10,
  },
  appSettingsButtonStyle: {
    height: 30,
    width: '80%',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  content: {
    paddingHorizontal: 20,
  },
  centeredPadded: {
    alignSelf: 'center',
    alignContent: 'center',
    padding: 15,
  },
  pageContainer: {
    flex: 1,
    padding: 20,
  },
  profile: {
    marginTop: -50,
    paddingVertical: 10,
  },
  avatar: {
    borderWidth: 5,
    elevation: 4,
  },
});
