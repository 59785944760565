import {useContext, useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import {Button, DataTable, Headline, Searchbar, Text} from 'react-native-paper';

import {
  displayNameFromUser,
  useConnectedUsers,
  useUsersByCitizenNumber,
} from '../hooks/useUserInfo';
import {User} from '../models/IdentityModels';
import {ScoringInfoContext} from './Stack';
import {format} from '../translations';
import {useAppSettings} from '../components/AppSettings';

export const UserListScreen = () => {
  const appSettings = useAppSettings();
  const linkTo = useLinkTo();
  const scoringInfo = useContext(ScoringInfoContext);
  const scoringAsUser = scoringInfo?.scoringUserInfo?.scoringAsUser;
  const scoringAsUserDisplayName = displayNameFromUser(scoringAsUser);
  const connectedUsers = useConnectedUsers(scoringInfo?.scoringUserInfo?.scoringAsUser?.kullkiId);
  // console.warn('UserList connectedUsers: ' + JSON.stringify(connectedUsers));
  const [searchQuery, setSearchQuery] = useState('');
  const [searchUsersQueryString, setSearchUsersQueryString] = useState<string | undefined>(
    undefined,
  );
  let searchUsers = useUsersByCitizenNumber(searchUsersQueryString, scoringAsUser?.kullkiId);
  const onChangeSearch = (query: string) => {
    setSearchQuery(query);
    if (query === '' && searchUsers !== undefined) {
      searchUsers = undefined;
      setSearchUsersQueryString(undefined);
    }
  };

  const handleSearch = () => {
    if (searchQuery !== '') {
      console.log('UserList should search for ' + searchQuery);
      setSearchUsersQueryString(searchQuery);
    }
  };

  let userList: User[] | undefined = connectedUsers;

  if (searchUsers !== undefined) {
    userList = searchUsers;
  }

  const handleUserInformation = (user: User) => {
    scoringInfo?.setScoringUserInfo({
      ...scoringInfo.scoringUserInfo,
      scoringOnUser: user,
    });
    linkTo('/scoring/user/detail');
  };

  return (
    <ScrollView contentContainerStyle={styles.pageContainer}>
      <Headline style={styles.appBarButtonPadding}>
        {format(appSettings.t('ConsultScoresUserCount'), [
          (connectedUsers?.length ?? 0) + '',
          scoringAsUserDisplayName,
        ])}
      </Headline>

      <View style={styles.centeredPadded} />
      <Searchbar
        onIconPress={handleSearch}
        placeholder={format(appSettings.t('ConsultScoresSearchPlaceholder'), [
          appSettings.t('ProfileCedulaLabel'),
        ])}
        onChangeText={onChangeSearch}
        value={searchQuery}
      />
      <View style={styles.centeredPadded} />

      <DataTable>
        <DataTable.Header>
          <DataTable.Title sortDirection="descending">
            {appSettings.t('ProfileFirstNameLabel')}
          </DataTable.Title>
          <DataTable.Title>{appSettings.t('ProfileSecondNameLabel')}</DataTable.Title>
          <DataTable.Title>{appSettings.t('ProfileCedulaLabel')}</DataTable.Title>
          <DataTable.Title>{appSettings.t('Actions')}</DataTable.Title>
        </DataTable.Header>

        {!!searchUsersQueryString && userList?.length === 0 && (
          <Text style={styles.centeredPadded}>{appSettings.t('ConsultScoresSearchNoResults')}</Text>
        )}

        {userList?.map(connectedUser => {
          return (
            <DataTable.Row key={connectedUser.kullkiId}>
              <DataTable.Cell>{connectedUser.firstName}</DataTable.Cell>
              <DataTable.Cell>{connectedUser.secondName}</DataTable.Cell>
              <DataTable.Cell>{connectedUser.citizenNumber}</DataTable.Cell>
              <DataTable.Cell>
                <Button
                  mode="contained"
                  // @ts-ignore FIXME type the navigator...
                  onPress={() => handleUserInformation(connectedUser)}>
                  {appSettings.t('ConsultScoresViewDetails')}
                </Button>
              </DataTable.Cell>
            </DataTable.Row>
          );
        })}
      </DataTable>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  appBarButtonPadding: {
    padding: 10,
  },
  centeredPadded: {
    alignSelf: 'center',
    alignContent: 'center',
    padding: 15,
  },
  pageContainer: {
    flex: 1,
    padding: 20,
  },
});
