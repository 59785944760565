import auth from '@react-native-firebase/auth';
import {useAppSettings} from '../components/AppSettings';
import {displayNameFromUser} from '../hooks/useUserInfo';
import {format} from '../translations';
import {useContext} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {Divider, Text} from 'react-native-paper';
import {ScoringInfoContext} from './Stack';

export const HomeScreen = () => {
  const scoringInfo = useContext(ScoringInfoContext);
  const appSettings = useAppSettings();

  const scoringAsUser = scoringInfo?.scoringUserInfo?.scoringAsUser;
  let scoringAsUserDisplayName = displayNameFromUser(scoringAsUser);

  return (
    <ScrollView contentContainerStyle={styles.fullyCenteredPageContainer}>
      <Text>
        {appSettings.t('HomePageGreeting')}
        {auth().currentUser?.displayName ? `, ${auth().currentUser?.displayName}` : ''}
      </Text>
      {scoringAsUser?.kullkiId !== scoringInfo?.scoringUserInfo?.authUser?.kullkiId && (
        <>
          <Divider style={{backgroundColor: appSettings.currentTheme.colors.primary}} />
          <View style={styles.centeredPadded} />
          <Text>
            {format(appSettings.t('ScoringDelegateGreeting'), [scoringAsUserDisplayName])}
          </Text>
        </>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  centeredPadded: {
    alignSelf: 'center',
    alignContent: 'center',
    padding: 15,
  },
  fullyCenteredPageContainer: {
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
