import {useContext, useEffect, useState} from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import {ActivityIndicator, Button, Dialog, Paragraph, Portal, Text} from 'react-native-paper';
import {v4} from 'uuid';
import auth from '@react-native-firebase/auth';
import analytics from '@react-native-firebase/analytics';
// import functions from '@react-native-firebase/functions';
import {useLinkTo, useNavigation} from '@react-navigation/native';
import {useIsFetching} from 'react-query';

import config from '../AppConfig';
import {ScoringInfoContext} from './Stack';
import {displayNameFromUser, useDemographics, useTIPI} from '../hooks/useUserInfo';
import {User} from '../models/IdentityModels';
import {useAppSettings} from '../components/AppSettings';
import {format} from '../translations';

//   render(): JSX.Element {
//     return (
//       <HeaderContainer loadingMessage={!this.state.user}>
//         <RN.View
//           style={{
//             flex: 1,
//             maxHeight: 40,
//             minHeight: 40,
//             flexDirection: 'row',
//             alignContent: 'center',
//             backgroundColor: '#00ACE6',
//           }}
//         >
//           <RN.Text style={[rnStyles.topHeaderText, { flex: 1 }]}>
//             {I18NService.translate('Calculate')}
//           </RN.Text>
//         </RN.View>

//         {!this.state.enabled && (
//           <RN.View style={rnStyles.topHeaderView}>
//             <RN.Text style={rnStyles.defaultText}>
//               {I18NService.translate('CalculateDisabled')}
//             </RN.Text>
//           </RN.View>
//         )}

//         {this.state.enabled && !this.state.meetsCalculateDataRequirements && (
//           <RN.View style={rnStyles.topHeaderView}>
//             <RN.Text style={rnStyles.defaultText}>
//               {this.state.ksocial !== -1
//                 ? this.state.ksocial
//                 : `${I18NService.translate('CalculateInsufficientData')} ${
//                     this.state.loggedInUser?.kullkiId !== this.state.kullkiId &&
//                     I18NService.format(
//                       I18NService.translate('CalculateInsufficientDataSecondaryUser'),
//                       [`${this.state.user?.firstName} ${this.state.user?.secondName}`]
//                     )
//                   }`}
//             </RN.Text>
//           </RN.View>
//         )}

//         {this.state.enabled && this.state.meetsCalculateDataRequirements && (
//           <RN.View style={rnStyles.topHeaderView}>
//             <RN.Text style={rnStyles.defaultText}>
//               {this.state.ksocial !== -1
//                 ? this.state.ksocial
//                 : I18NService.format(I18NService.translate('CalculateText'), [
//                     `${this.state.user?.firstName} ${this.state.user?.secondName}`,
//                   ])}
//             </RN.Text>
//           </RN.View>
//         )}
//         <RN.View style={rnStyles.topHeaderView}>
//           {this.state.enabled && this.state.message && (
//             <RN.Text style={[rnStyles.defaultText, { margin: 30 }]}>{this.state.message}</RN.Text>
//           )}
//         </RN.View>
//         <RN.Image
//           fadeDuration={0}
//           style={[
//             {
//               maxHeight: 75,
//               maxWidth: 250,
//               paddingTop: 50,
//               padding: 20,
//               alignItems: 'center',
//               alignSelf: 'center',
//               justifyContent: 'center',
//             },
//           ]}
//           source={Images.ksocialLogo}
//         />
//         {this.state.calculating && (
//           <RN.View style={rnStyles.topHeaderView}>
//             <WaitingText
//               textStyle={rnStyles.defaultText}
//               i18nKeyCount={3}
//               i18nKeyPrefix="calculate_wait"
//               navigation={this.props.navigation}
//             />
//           </RN.View>
//         )}
//         <RN.View style={rnStyles.lowHorizontalSplit}>
//           {this.state.enabled && this.state.meetsCalculateDataRequirements && (
//             <SimpleButton
//               buttonStyle={Styles.loginIngresarButton}
//               textStyle={rnStyles.buttonText}
//               onPress={this.calculate}
//               disabled={this.state.calculating}
//               activityIndicator={this.state.calculating}
//               text={I18NService.translate('CalculateButton')}
//             />
//           )}
//         </RN.View>
//       </HeaderContainer>
//     );
//   }

const calculate = async (
  kullkiIdFor: string,
  kullkiIdBy: string,
  kullkiOnBehalfOf: string,
): Promise<void> => {
  console.log(`calculate on kullkiId: ${kullkiIdFor}`);

  // We should use functions().getHttpsCallableFromURL but that is firebase-js-sdk v9 only
  // So call directly:
  const token = await auth().currentUser!!.getIdToken();

  const functionsAPIURL = `${config.getWebAppURIRoot()}/api/v1/scoring/calculate`;
  // if (AppConfig.isDebugBuild()) {
  //   functionsAPIURL = 'http://localhost:5000/kscoreappdev/us-central1/calculate');
  //   console.log('emulator set');
  // }
  const result = await fetch(functionsAPIURL, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    method: 'post',
    body: JSON.stringify({
      data: {
        kullkiId: kullkiIdFor,
        kullkiIdBy,
        kullkiOnBehalfOf,
        'Correlation-ID': v4(),
      },
    }),
  });

  const data = (await result.json()).data;
  console.log('calculate result:', data);

  if (data?.error) {
    console.log('data: ', JSON.stringify(data, null, 2));
    console.log('error: ', JSON.stringify(data.error, null, 2));
    // FIXME need to log this for analytics + reproduction - kullki-id? correlation-id ?
    throw new Error(data.error);
  }
};

export const canCalculate = (user?: User): boolean => {
  if (
    user &&
    user.tipi &&
    user.citizenNumber &&
    user.dateOfBirth &&
    user.demographics &&
    user.demographics.length > 0
  ) {
    return true;
  }

  return false;
};

export const GenerateScore = () => {
  const appSettings = useAppSettings();
  const linkTo = useLinkTo();
  const navigation = useNavigation();
  const isFetching = useIsFetching();
  const scoringInfo = useContext(ScoringInfoContext);
  const user = scoringInfo?.scoringUserInfo?.scoringOnUser;
  const tipi = useTIPI(user?.kullkiId);
  const demographics = useDemographics(user?.kullkiId);
  const [calculating, setCalculating] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [meetsRequirements, setMeetsRequirements] = useState<boolean | undefined>(undefined);

  if (user) {
    user.tipi = tipi;
    user.demographics = demographics;
  }

  if (!meetsRequirements && canCalculate(user)) {
    console.log('GenerateScores we meet data requirements');
    setMeetsRequirements(true);
  }

  const handleCalculate = () => {
    setCalculating(true);
    setErrorMessage('');
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      setErrorMessage('');
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    if (calculating && user && scoringInfo.scoringUserInfo?.authUser) {
      console.log('GenerateScores starting calculation...');
      calculate(
        user.kullkiId,
        scoringInfo.scoringUserInfo.authUser.kullkiId,
        scoringInfo.scoringUserInfo?.scoringAsUser?.kullkiId ??
          scoringInfo.scoringUserInfo.authUser.kullkiId,
      )
        .then(() => {
          console.log('GenerateScores done calculating');
          setCalculating(false);
          analytics().logEvent('successCalculate');
          linkTo('/scoring/score');
        })
        .catch(e => {
          let message = appSettings.t('CalculatingError');

          if (
            e.message === 'error/lambda-2 not enough data' ||
            e.message === 'error/no-device-data'
          ) {
            message = appSettings.t('CalculatingErrorNotEnoughData');
            if (scoringInfo.scoringUserInfo?.authUser?.kullkiId !== user?.kullkiId) {
              message = `${message} ${format(
                appSettings.t('CalculateInsufficientDataSecondaryUser'),
                [`${displayNameFromUser(user)}`],
              )}`;
            }
            analytics().logEvent('failureCalculateLowData');
          } else if (e.message === 'error/invalid-kullkiId') {
            message = appSettings.t('CalculatingErrorDeletedUser');
          } else {
            // errorMessage = I18NService.translate('CalculatingError');
            analytics().logEvent('failureCalculateGeneral');
          }

          setErrorMessage(message);
          setCalculating(false);
        });
    }
  }, [calculating, navigation, user, errorMessage, scoringInfo, appSettings, linkTo]);

  console.log(`GenerateScores kullkiId ${user?.kullkiId}`);

  return (
    <ScrollView contentContainerStyle={styles.fullyCenteredPageContainer}>
      <Portal>
        <Dialog visible={errorMessage !== ''} onDismiss={() => setErrorMessage('')}>
          <Dialog.Title>{appSettings.t('ErrorAlert')}</Dialog.Title>
          <Dialog.Content>
            <Paragraph>{errorMessage}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={() => setErrorMessage('')}>OK</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
      {calculating && (
        <>
          <Text>{format(appSettings.t('CalculateText'), [displayNameFromUser(user)])}</Text>
          <ActivityIndicator size="large" />
        </>
      )}
      {isFetching > 0 && <Text>{appSettings.t('loading')}</Text>}
      {user !== undefined && isFetching === 0 && !meetsRequirements && (
        <>
          <Text>{appSettings.t('CalculateInsufficientData')}</Text>
          {scoringInfo?.scoringUserInfo?.authUser?.kullkiId !== user?.kullkiId && (
            <Text>
              {format(appSettings.t('CalculateInsufficientDataSecondaryUser'), [
                displayNameFromUser(user),
              ])}
            </Text>
          )}
        </>
      )}
      {!calculating && meetsRequirements && (
        <>
          <Text style={styles.textContainer}>{appSettings.t('CalculateCostExplanation')}</Text>
          <Button mode="contained" onPress={() => handleCalculate()}>
            {appSettings.t('CalculateButton')}
          </Button>
        </>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  fullyCenteredPageContainer: {
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 80,
  },
});
