import {Platform, StyleSheet, View} from 'react-native';
import {createContext, Fragment, ReactNode, useEffect, useState} from 'react';
import {Headline, ActivityIndicator, Provider as PaperProvider} from 'react-native-paper';
import auth, {FirebaseAuthTypes} from '@react-native-firebase/auth';
import firestore from '@react-native-firebase/firestore';
import {AlertsProvider} from 'react-native-paper-alerts';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {
  NavigationContainer,
  // getPathFromState,
  // getStateFromPath,
  createNavigationContainerRef,
} from '@react-navigation/native';
import RNBootSplash from 'react-native-bootsplash';

import appJson from '../app.json';
import SignedInStack from './signed-in/Stack';
import SignedOutStack from './signed-out/Stack';
import {useAppSettings} from './components/AppSettings';
import AppConfig from './AppConfig';
import functions from 'shims/firebase-functions-web';

// Use these to go against the firestore emulator
if (process.env.REACT_APP_FIREBASE_EMULATOR === 'true') {
  auth().useEmulator('http://localhost:9099');
  firestore().settings({persistence: false});
  firestore().useEmulator('localhost', 8080);
  functions().useEmulator('localhost', 5001);
  // so on-device storage doesn't contain things wiped when emulator shuts down
  // end emulator settings
}

/**
 * Types
 */
type User = FirebaseAuthTypes.User | null;

/**
 * Contexts
 */
export const UserContext = createContext<User>(null);

export const mainNavigatorRef = createNavigationContainerRef();

function App(): JSX.Element {
  const [initializing, setInitializing] = useState(true);
  const [listenUser, setListenUser] = useState(false);
  const [user, setUser] = useState<User>(null);
  const appSettings = useAppSettings();

  useEffect(() => {
    const init = async () => {
      // …do multiple sync or async tasks
    };

    init().finally(async () => {
      if (Platform.OS !== 'web') {
        await RNBootSplash.hide({fade: true});
        console.log('Bootsplash has been hidden successfully');
      }
    });
  }, []);

  /** Listen for auth state changes */
  useEffect(() => {
    const authListener = auth().onAuthStateChanged(result => {
      setUser(result);
      if (initializing && !listenUser) {
        setInitializing(false);
        setListenUser(true);
      }
    });

    return () => {
      if (authListener) {
        authListener();
      }
    };
  }, [initializing, listenUser]);

  /** Listen for user changes */
  useEffect(() => {
    let userListener: () => void;

    if (listenUser) {
      // TODO @react-native-firebase/auth provides `onUserChanged` which is this and more.
      // what else can we add and still be web-compatible?
      userListener = auth().onIdTokenChanged(result => {
        setUser(result);
      });
    }

    return () => {
      if (userListener) {
        userListener();
      }
    };
  }, [listenUser]);

  if (initializing) {
    let waiting = true;
    setTimeout(() => {
      waiting = false;
    }, 1000);

    return (
      <View
        style={[
          styles.loadingContainer,
          {backgroundColor: appSettings.currentTheme.colors.background},
        ]}>
        {!waiting && (
          <Fragment>
            <Headline style={[styles.padded, {color: appSettings.currentTheme.colors.text}]}>
              {appSettings.t('loading')}...
            </Headline>
            <ActivityIndicator
              size={'large'}
              theme={{
                ...appSettings.currentTheme,
                colors: {primary: appSettings.currentTheme.colors.accent},
              }}
            />
          </Fragment>
        )}
      </View>
    );
  }

  const getNavigationScreens = () => {
    if (!user) {
      return {
        screens: {
          // Our signed-out stack has these:
          SignIn: 'account/login',
          CreateAccount: 'account/create',
          ForgotPassword: 'account/password/forgot',
          PhoneSignIn: 'account/phone/login',

          // Used as catch-all - we try for a public profile, and go 404 if it does not work
          PublicProfile: '*',
        },
      };
    } else {
      return {
        screens: {
          // FIXME signed-in stack isn't configured at all yet
          App: {
            path: '',
            screens: {
              HomeScreen: 'home',
              ScoringStack: {
                path: 'scoring',
                screens: {
                  DashboardScreen: '',
                  UserListScreen: 'user/list',
                  UserDetailsScreen: 'user/detail',
                  ScoreDisplay: 'score',
                  GenerateScore: 'score/generate',
                  DelegatesListScreen: 'delegate/list',
                  DelegateDetailsScreen: 'delegate/detail',
                  ScoreBatchesListScreen: 'batches/list',
                  ScoreBatchDetailsScreen: 'batches/:id',
                },
              },
              BillingStack: {
                path: 'billing',
                screens: {
                  BillingDashboard: '',
                  BillingReportScreen: 'report',
                },
              },
            },
          },
          ProfileSettingsModal: {
            path: 'settings',
            screens: {
              ProfileSettingsScreen: 'profile',
              AccountSwitchScreen: 'profile/switch',
              Settings: 'profile/edit',
            },
          },

          // Used as catch-all - there is a "Home" in signed-in and signed-out stacks!
          // FIXME this is horrible at the moment - if you hit the 404, the nav menus are messed up.
          PublicProfile: '*',
        },
      };
    }
  };
  function container(children: ReactNode | ReactNode[]) {
    return (
      <SafeAreaProvider>
        <PaperProvider theme={appSettings.currentTheme}>
          <AlertsProvider>
            <NavigationContainer
              linking={{
                prefixes: [AppConfig.getWebAppURIRoot(), 'localhost'],
                config: getNavigationScreens(),
                // getStateFromPath: (path, options) => {
                //   // Return a state object here
                //   // You can also reuse the default logic by importing `getStateFromPath` from `@react-navigation/native`
                //   console.log('examining path: ' + path);
                //   if (path.lastIndexOf('/') === 0 && path.length > 8 + 1) {
                //     // the +1 is the slash...
                //     console.log('we have a public profile URL!');
                //     // path = ''; // TODO implement real resolution to ProfilePublic
                //     return {
                //       routes: [
                //         {
                //           name: path,
                //           state: {
                //             routes: [{name: 'PublicProfile'}],
                //           },
                //         },
                //       ],
                //     };
                //   }
                //   return getStateFromPath(path, options);
                // },
                // getPathFromState(state, config) {
                //   // Return a path string here
                //   // You can also reuse the default logic by importing `getPathFromState` from `@react-navigation/native`
                //   return getPathFromState(state, config);
                // },
              }}
              ref={mainNavigatorRef}
              documentTitle={{
                formatter: (options, route) =>
                  `${appJson.displayName}${
                    options?.title || route?.name ? ' - ' + options?.title ?? route?.name : ' '
                  }`,
              }}
              theme={appSettings.currentTheme}>
              {children}
            </NavigationContainer>
          </AlertsProvider>
        </PaperProvider>
      </SafeAreaProvider>
    );
  }

  return container(
    user ? (
      <UserContext.Provider value={user}>
        <SignedInStack />
      </UserContext.Provider>
    ) : (
      <SignedOutStack />
    ),
  );
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    // alignSelf: 'center',
    alignItems: 'center',
    // textAlignVertical: true,
  },
  padded: {
    padding: 40,
  },
});

export default App;
