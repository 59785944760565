import {useContext} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import {Button, DataTable} from 'react-native-paper';

// import {
//   // displayNameFromUser,
//   // useConnectedUsers,
//   // useUsersByCitizenNumber,
// } from '../hooks/useUserInfo';
// import {ScoreBatch} from '../models/IdentityModels';
// import {ScoringInfoContext} from './Stack';
// import {format} from '../translations';
import {useAppSettings} from '../components/AppSettings';
import {useScoreBatches} from '../hooks/useUserInfo';
import {ScoringInfoContext} from './Stack';
import {ScoreBatch} from '../models/IdentityModels';
// import {useAlerts} from 'react-native-paper-alerts';

// const dateNow = Date.now();

// async function fetchScoreBatches(): Promise<ScoreBatch[]> {
// async function useScoreBatches(): Promise<ScoreBatch[]> {
//   return [
//     {
//       id: 'big-id-string-here',
//       createdDate: 12341234,
//       createdBy: 'kullkiId-here',
//     },
//   ];
// }

export const ScoreBatchesListScreen = () => {
  // const [initializing, setInitializing] = useState(true);
  const scoringInfo = useContext(ScoringInfoContext);
  const scoringAsUser = scoringInfo?.scoringUserInfo?.scoringAsUser;
  // const scoringAsUserDisplayName = displayNameFromUser(scoringAsUser);
  // const connectedUsers = useConnectedUsers(scoringInfo?.scoringUserInfo?.scoringAsUser?.kullkiId);
  const batches = useScoreBatches(scoringAsUser?.kullkiId);
  const appSettings = useAppSettings();
  // const Alert = useAlerts();
  // setBatches(fetchScoreBatches);
  const linkTo = useLinkTo();
  // console.warn('UserList connectedUsers: ' + JSON.stringify(connectedUsers));
  // const [searchQuery, setSearchQuery] = useState('');
  // const [searchUsersQueryString, setSearchUsersQueryString] = useState<string | undefined>(
  //   undefined,
  // );
  // let searchUsers = useUsersByCitizenNumber(searchUsersQueryString, scoringAsUser?.kullkiId);
  // const onChangeSearch = (query: string) => {
  //   setSearchQuery(query);
  //   if (query === '' && searchUsers !== undefined) {
  //     searchUsers = undefined;
  //     setSearchUsersQueryString(undefined);
  //   }
  // };

  // const handleSearch = () => {
  //   if (searchQuery !== '') {
  //     console.log('UserList should search for ' + searchQuery);
  //     setSearchUsersQueryString(searchQuery);
  //   }
  // };

  // let userList: User[] | undefined = connectedUsers;

  // if (searchUsers !== undefined) {
  //   userList = searchUsers;
  // }

  const handleBatchCreate = () => {
    console.log('should create new batch...');
    // TODO do a react query that mutates by creating the thing and invalidates the cache
    const batch = {id: '43c5ea72-a43d-480a-9502-5760558aa666'};
    linkTo(`/scoring/batches/${batch.id}`);
  };

  const handleBatchInformation = (batch: ScoreBatch) => {
    linkTo(`/scoring/batches/${batch.id}`);
  };

  return (
    <ScrollView contentContainerStyle={styles.pageContainer}>
      {/* <Headline style={styles.appBarButtonPadding}>
        {format(appSettings.t('ConsultScoresUserCount'), [
          (connectedUsers?.length ?? 0) + '',
          scoringAsUserDisplayName,
        ])}
      </Headline> */}

      {/* <View style={styles.centeredPadded} />
      <Searchbar
        onIconPress={handleSearch}
        placeholder={format(appSettings.t('ConsultScoresSearchPlaceholder'), [
          appSettings.t('ProfileCedulaLabel'),
        ])}
        onChangeText={onChangeSearch}
        value={searchQuery}
      /> */}
      <Button
        mode="contained"
        // @ts-ignore FIXME type the navigator...
        onPress={() => handleBatchCreate()}>
        {appSettings.t('ScoreBatchCreate')}
      </Button>

      <View style={styles.centeredPadded} />

      <DataTable>
        <DataTable.Header>
          <DataTable.Title sortDirection="descending">
            {appSettings.t('ScoreBatchCreatedDate')}
          </DataTable.Title>
          <DataTable.Title>{appSettings.t('ScoreBatchId')}</DataTable.Title>
          <DataTable.Title>{appSettings.t('Actions')}</DataTable.Title>
        </DataTable.Header>

        {/* {!!searchUsersQueryString && userList?.length === 0 && (
          <Text style={styles.centeredPadded}>{appSettings.t('ConsultScoresSearchNoResults')}</Text>
        )} */}
        {/* {initializing && (
          <DataTable.Row>
            <DataTable.Cell>{appSettings.t('Loading')}</DataTable.Cell>
          </DataTable.Row>
        )} */}

        {batches?.map(scoreBatch => {
          return (
            <DataTable.Row key={scoreBatch.id}>
              <DataTable.Cell>{scoreBatch.createdDate}</DataTable.Cell>
              <DataTable.Cell>{scoreBatch.id}</DataTable.Cell>
              <DataTable.Cell>
                <Button
                  mode="contained"
                  // @ts-ignore FIXME type the navigator...
                  onPress={() => handleBatchInformation(scoreBatch)}>
                  {appSettings.t('ConsultScoresViewDetails')}
                </Button>
              </DataTable.Cell>
            </DataTable.Row>
          );
        })}
      </DataTable>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  appBarButtonPadding: {
    padding: 10,
  },
  centeredPadded: {
    alignSelf: 'center',
    alignContent: 'center',
    padding: 15,
  },
  pageContainer: {
    flex: 1,
    padding: 20,
  },
});
