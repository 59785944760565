export default class appleAuth {
  // foo
}

export class AppleButton {
  /* foo */
}

export enum AppleRequestOperation /* foo */ {}

export enum AppleRequestScope /* foo */ {}
