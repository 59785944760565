import {useContext} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {Button, Subheading} from 'react-native-paper';
import {useLinkTo, useRoute} from '@react-navigation/native';
import XDate from 'xdate';

import {useAppSettings} from '../components/AppSettings';
import {useScoreBatch} from '../hooks/useUserInfo';
import {ScoringInfoContext} from './Stack';

export const ScoreBatchDetailsScreen = () => {
  const linkTo = useLinkTo();
  const appSettings = useAppSettings();
  const scoringInfo = useContext(ScoringInfoContext);
  const scoringAsUser = scoringInfo?.scoringUserInfo?.scoringAsUser;
  const route = useRoute();
  const routeParams = route.params as Record<string, any>;
  console.log('routeParams is ' + JSON.stringify(routeParams));
  console.log('batch id is ' + routeParams.id);
  const scoreBatch = useScoreBatch(scoringAsUser?.kullkiId, routeParams.id);

  console.log('route is: ' + JSON.stringify(route, null, 2));
  console.log('score batch is: ' + JSON.stringify(scoreBatch));

  return (
    <ScrollView>
      <View style={styles.pageContainer}>
        <View style={styles.content}>
          <Subheading>{`${appSettings.t('ScoreBatchId')}: ${scoreBatch?.id}`}</Subheading>
          <Subheading>
            {`${appSettings.t('ScoreBatchCreatedDate')}: ${
              scoreBatch?.createdDate &&
              (scoreBatch.createdDateFormatted = new XDate(scoreBatch.createdDate).toString(
                appSettings.t('ProfileBirthdateFormat'),
              ))
            }`}
          </Subheading>
        </View>
        <View style={styles.centeredPadded} />
        <Button
          style={styles.appSettingsButtonStyle}
          mode="contained"
          onPress={() => linkTo('/scoring/score/generate')}>
          {appSettings.t('CalculateButton')}
        </Button>
        {/* {ksocial && (
          <>
            <View style={styles.centeredPadded} />
            <Button
              style={styles.appSettingsButtonStyle}
              mode="contained"
              onPress={() => linkTo('/scoring/score')}>
              {appSettings.t('ShowScore')}
            </Button>
          </>
        )} */}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  appBarButtonPadding: {
    padding: 10,
  },
  appSettingsButtonStyle: {
    height: 30,
    width: '80%',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  content: {
    paddingHorizontal: 20,
  },
  centeredPadded: {
    alignSelf: 'center',
    alignContent: 'center',
    padding: 15,
  },
  pageContainer: {
    flex: 1,
    padding: 20,
  },
  profile: {
    marginTop: -50,
    paddingVertical: 10,
  },
  avatar: {
    borderWidth: 5,
    elevation: 4,
  },
});
