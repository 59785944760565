import {useLinkTo, useNavigation} from '@react-navigation/native';
import {Image, StyleSheet, View} from 'react-native';
import {Button, Text} from 'react-native-paper';
import {useAppSettings} from './AppSettings';

export const NotFound = () => {
  const appSettings = useAppSettings();
  const linkTo = useLinkTo();
  const navigation = useNavigation();

  return (
    <View style={styles.detailsContainer}>
      <Text style={styles.sectionTitle}>{appSettings.t('PageNotFoundText')}</Text>
      <View style={styles.spacer} />
      <Image
        fadeDuration={0}
        style={styles.slothiness}
        source={require('../../static/assets/images/Lazy_Sloth.jpg')}
      />
      <View style={styles.spacer} />
      <Button
        onPress={() => {
          // FIXME this is horrible at the moment
          if (!navigation.canGoBack()) {
            linkTo('/');
          } else {
            while (navigation.canGoBack()) {
              navigation.goBack();
            }
          }
        }}>
        {appSettings.t('home')}
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  detailsContainer: {
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 24,
  },
  slothiness: {
    // justifyContent: 'center',
    // alignSelf: 'center',
    padding: 20,
    width: 223,
    maxWidth: 223,
    height: 161,
    maxHeight: 161,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: '600',
  },
  spacer: {
    height: 20,
  },
});
