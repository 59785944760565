import dayjs from 'dayjs';
import {Fragment, useContext, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {Button, Caption, Divider, Headline, Subheading, Title} from 'react-native-paper';
import auth from '@react-native-firebase/auth';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useLinkTo} from '@react-navigation/native';
import {GoogleSignin} from '@react-native-google-signin/google-signin';

import {fallbackLanguageLocale, useAppSettings} from '../components/AppSettings';
import {displayNameFromUser, useAuthUserInfo, useDelegatingUsers} from '../hooks/useUserInfo';
import {ScoringInfoContext} from './Stack';
import {getProviders} from '../util/helpers';
import Provider from '../components/AuthProvider';

export async function signOut() {
  await GoogleSignin.signOut();
  await auth().signOut();
}

export const ProfileSettingsScreen = () => {
  const linkTo = useLinkTo();
  const appSettings = useAppSettings();
  const user = auth().currentUser;
  const kullkiUser = useAuthUserInfo();
  const delegatingUsers = useDelegatingUsers(kullkiUser?.user.kullkiId);
  const scoringInfo = useContext(ScoringInfoContext);
  const [signingOut, setSigningOut] = useState(false);

  const scoringAsUser = scoringInfo?.scoringUserInfo?.scoringAsUser;
  let scoringAsUserDisplayName = displayNameFromUser(scoringAsUser);

  if (!user) {
    return null;
  }

  // Array of providers the the user is linked with
  const providers = getProviders(user);

  return (
    <Fragment>
      <View style={styles.content}>
        {(delegatingUsers?.length ?? 0) > 0 && (
          <>
            <Divider style={{backgroundColor: appSettings.currentTheme.colors.primary}} />
            <Headline>{appSettings.t('ScoringCurrentProfileTitle')}: </Headline>
            <Subheading style={styles.centeredPadded}>{scoringAsUserDisplayName}</Subheading>
            <Button
              style={[styles.appSettingsButtonStyle]}
              mode="contained"
              onPress={() => {
                linkTo('/settings/profile/switch');
              }}>
              {appSettings.t('ScoringSelectProfile')}
            </Button>
            <View style={styles.centeredPadded} />
          </>
        )}
        <Divider style={{backgroundColor: appSettings.currentTheme.colors.primary}} />
        <Headline>{appSettings.t('ProfileUserSettingsUserHeader')}: </Headline>

        <Title>
          {user.displayName ? user.displayName : user.email?.split('@')[0]}{' '}
          {user.emailVerified && <Icon name="check-decagram" color="#2196f3" size={26} />}
        </Title>
        {!!user.displayName && <Title>{user.email}</Title>}
        {!!user.phoneNumber && <Subheading>{user.phoneNumber}</Subheading>}
        {!!kullkiUser?.user.roles && <Caption>{kullkiUser.user.roles.join(', ')}</Caption>}
        {!!user.metadata.lastSignInTime && (
          <Caption>
            {`${appSettings.t('profileLastSignIn')}: ${dayjs(user.metadata.lastSignInTime)
              .locale(appSettings.languageLocale?.languageTag ?? fallbackLanguageLocale.languageTag)
              .format()}`}
          </Caption>
        )}
      </View>
      <View style={[styles.providers, {backgroundColor: appSettings.currentTheme.colors.surface}]}>
        <Provider type="password" active={providers.includes('password')} />
        <Provider type="facebook" active={providers.includes('facebook.com')} />
        <Provider type="google" active={providers.includes('google.com')} />
        <Provider type="phone" active={providers.includes('phone')} />
      </View>
      <Button
        style={styles.appSettingsButtonStyle}
        mode="contained"
        onPress={() => {
          linkTo('/settings/profile/edit');
        }}>
        {appSettings.t('profile_btnEditProfile')}
      </Button>
      <View style={styles.centeredPadded} />
      <Button
        mode="contained"
        loading={signingOut}
        onPress={() => {
          if (!signingOut) {
            setSigningOut(true);
            signOut();
          }
        }}
        style={styles.appSettingsButtonStyle}>
        {appSettings.t('userSignOut')}
      </Button>
    </Fragment>
  );
};

const styles = StyleSheet.create({
  appBarButtonPadding: {
    padding: 10,
  },
  appSettingsButtonStyle: {
    // padding: 10,
    height: 30,
    width: '80%',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  content: {
    paddingHorizontal: 20,
  },
  centeredPadded: {
    alignSelf: 'center',
    alignContent: 'center',
    padding: 15,
  },
  fullyCenteredPageContainer: {
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageContainer: {
    flex: 1,
    padding: 20,
  },
  providers: {
    elevation: 4,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginVertical: 30,
    padding: 20,
  },
  divider: {
    height: 4,
  },
  profile: {
    marginTop: -50,
    paddingVertical: 10,
  },
  avatar: {
    borderWidth: 5,
    elevation: 4,
  },
});
