import {createContext, useContext, useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {createStackNavigator} from '@react-navigation/stack';
import {Text} from 'react-native-paper';
import {useAppSettings} from '../components/AppSettings';
import {QueryClient, QueryClientProvider} from 'react-query';

import Settings from './Settings';
import {useDelegatingUsers} from '../hooks/useUserInfo';
import {User} from '../models/IdentityModels';
import ScoreDisplay from './ScoreDisplay';
import {GenerateScore} from './GenerateScore';
import {CustomAppBar} from './AppBar';
import {ProfileSettingsModal} from './ProfileSettingsModal';
import {ProfileSettingsScreen} from './ProfileSettingsScreen';
import {AccountSwitchScreen} from './AccountSwitch';
import {UserListScreen} from './UserList';
import {ScoreBatchesListScreen} from './ScoreBatchesList';
import {ScoreBatchDetailsScreen} from './ScoreBatchDetails';
import {UserDetailsScreen} from './UserDetails';
import {DashboardScreen} from './ScoringDashboard';
import {BillingDashboard} from './BillingDashboard';
import {BillingReportScreen} from './BillingReportScreen';
import {HomeScreen} from './Home';
import {NotFound} from '../components/NotFound';
import {PublicProfile} from '../components/PublicProfile';
import {FirestoreUserGate} from '../components/FirestoreGate';

// This context contains the idea of who we are, who we work as (delegates), who we are working on
export type ScoringUserInfo = {
  authUser?: User;
  scoringAsUser?: User;
  scoringOnUser?: User;
};

export const ScoringInfoContext = createContext<
  | {
      scoringUserInfo?: ScoringUserInfo;
      setScoringUserInfo: (scoringUserInfo: ScoringUserInfo) => void;
    }
  | undefined
>(undefined);

// By default we do not want to query APIs that are billable per call, disable focus re-fetch
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const RootStackNav = createStackNavigator();
export const RootStack = () => {
  const appSettings = useAppSettings();
  const scoringInfo = useContext(ScoringInfoContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const okayToIgnorePreFetch = useDelegatingUsers(scoringInfo?.scoringUserInfo?.authUser?.kullkiId);

  return (
    <RootStackNav.Navigator initialRouteName="App" screenOptions={{headerShown: false}}>
      <RootStackNav.Screen name="App" component={MainDrawer} />
      <RootStackNav.Screen
        name="ProfileSettingsModal"
        component={ProfileSettingsModal}
        options={{
          presentation: 'transparentModal',
          // cardOverlayEnabled: true,
        }}
      />
      <RootStackNav.Screen
        name="NotFound"
        component={NotFound}
        options={{title: appSettings.t('NotFound')}}
      />
      <RootStackNav.Screen
        name="PublicProfile"
        component={PublicProfile}
        options={{title: appSettings.t('profile_btnViewPublicProfile')}}
      />
    </RootStackNav.Navigator>
  );
};

// Custom App Bar
// -- has switch for tab nav
// -- has branding
// -- has profile on right side / switch for end tab nav

const ProfileSettingsStackNav = createStackNavigator();
export const ProfileSettingsStack = () => {
  const appSettings = useAppSettings();
  return (
    <ProfileSettingsStackNav.Navigator
      // screenOptions={{cardStyle: {width: '100%', height: '100%', flex: 1}}}
      initialRouteName="ProfileSettingsScreen">
      <ProfileSettingsStackNav.Screen
        name="ProfileSettingsScreen"
        options={{title: appSettings.t('ProfileUserAppSettingsTitle')}}
        component={ProfileSettingsScreen}
      />
      <ProfileSettingsStackNav.Screen
        options={{title: appSettings.t('ProfileSwitchTitle')}}
        name="AccountSwitchScreen"
        component={AccountSwitchScreen}
      />
      <ProfileSettingsStackNav.Screen
        options={{title: appSettings.t('ProfileUserSettingsTitle')}}
        name="Settings"
        component={Settings}
      />
    </ProfileSettingsStackNav.Navigator>
  );
};

//
// -- end tab nav (fully custom contents):
//   -- ProfileSettingsStack
//     -- ProfileSettingsScreen
//       -- X at top right to close
//       -- Account Switch w/Icons
//       -- Account Info Chunk
//       -- Sign Out button
//     -- AccountSwitchScreen
//     -- AccountSettingsScreen

//
// -- MainDrawer
const MainDrawerNav = createDrawerNavigator();
export const MainDrawer = () => {
  const appSettings = useAppSettings();
  return (
    <MainDrawerNav.Navigator
      initialRouteName="ScoringStack"
      screenOptions={{
        drawerStyle: {width: 180},
        headerShown: false,
        drawerType: 'slide',
      }}>
      <MainDrawerNav.Screen
        options={{title: appSettings.t('DashboardLabel')}}
        name="HomeScreen"
        component={HomeScreen}
      />
      <MainDrawerNav.Screen
        options={{title: appSettings.t('ScoringTitle')}}
        name="ScoringStack"
        component={ScoringStack}
      />
      <MainDrawerNav.Screen
        options={{title: appSettings.t('BillingTitle')}}
        name="BillingStack"
        component={BillingStack}
      />
    </MainDrawerNav.Navigator>
  );
};
//   -- HomeScreen -- just a panel of important stuff, help etc
//   -- ScoringStack
const ScoringStackNav = createStackNavigator();

// TODO Implement bradcrumbs https://stackoverflow.com/questions/63219028/how-can-i-make-breadcrumbs-show-screens-path-in-react-native-i-am-using-react-n
const ScoringStack = () => {
  const appSettings = useAppSettings();

  return (
    <ScoringStackNav.Navigator initialRouteName="DashboardScreen">
      <ScoringStackNav.Screen
        options={{title: appSettings.t('ScoringTitle')}}
        name="DashboardScreen"
        component={DashboardScreen}
      />
      <ScoringStackNav.Screen
        options={{title: appSettings.t('ConsultScoresUserListTitle')}}
        name="UserListScreen"
        component={UserListScreen}
      />
      <ScoringStackNav.Screen
        options={{title: appSettings.t('ProfileTitle')}}
        name="UserDetailsScreen"
        component={UserDetailsScreen}
      />
      <ScoringStackNav.Screen
        options={{title: appSettings.t('ShowScoreTitle')}}
        name="ScoreDisplay"
        component={ScoreDisplay}
      />
      <ScoringStackNav.Screen
        options={{title: appSettings.t('Calculate')}}
        name="GenerateScore"
        component={GenerateScore}
      />
      <ScoringStackNav.Screen
        options={{title: appSettings.t('ScoringDelegateList')}}
        name="DelegatesListScreen"
        component={DelegatesListScreen}
      />
      <ScoringStackNav.Screen
        options={{title: appSettings.t('ScoringDelegateDetails')}}
        name="DelegateDetailsScreen"
        component={DelegateDetailsScreen}
      />
      <ScoringStackNav.Screen
        options={{title: appSettings.t('ScoreBatchesListTitle')}}
        name="ScoreBatchesListScreen"
        component={ScoreBatchesListScreen}
      />
      <ScoringStackNav.Screen
        options={{title: appSettings.t('ScoreBatchDetailsTitle')}}
        name="ScoreBatchDetailsScreen"
        component={ScoreBatchDetailsScreen}
      />
    </ScoringStackNav.Navigator>
  );
};

//   -- BillingStack
const BillingStackNav = createStackNavigator();

// TODO Implement bradcrumbs https://stackoverflow.com/questions/63219028/how-can-i-make-breadcrumbs-show-screens-path-in-react-native-i-am-using-react-n
const BillingStack = () => {
  const appSettings = useAppSettings();

  return (
    <BillingStackNav.Navigator initialRouteName="BillingHome">
      <BillingStackNav.Screen
        options={{title: appSettings.t('BillingTitle')}}
        name="BillingDashboard"
        component={BillingDashboard}
      />
      <ScoringStackNav.Screen
        options={{title: appSettings.t('BillingReport')}}
        name="BillingReportScreen"
        component={BillingReportScreen}
      />
      {/* <ScoringStackNav.Screen
        options={{title: appSettings.t('ProfileTitle')}}
        name="UserDetailsScreen"
        component={UserDetailsScreen}
      /> */}
    </BillingStackNav.Navigator>
  );
};

//     -- DashboardScreen -- default -- general info about account's score status
//     -- ConsultStack
//       -- UserListScreen
//         -- UserDetailsScreen

//         -- DelegatesListScreen
const DelegatesListScreen = () => {
  // const authUser = useAuthUserInfo();
  // const navigation = useNavigation();

  // TODO not implemented yet
  return <View />;
  // return (
  //   <ScrollView contentContainerStyle={styles.fullyCenteredPageContainer}>
  //     <Text>List of delegates in this business account.</Text>
  //     <Button
  //       mode="contained"
  //       // @ts-ignore FIXME type the navigator...
  //       onPress={() => linkTo('/delegate/detail')}>
  //       Pretend this is a delegate you want more details on.
  //     </Button>
  //   </ScrollView>
  // );
};

//         -- DelegateDetailsScreen
const DelegateDetailsScreen = () => {
  // const authUser = useAuthUserInfo();
  // const navigation = useNavigation();

  return (
    <ScrollView contentContainerStyle={styles.fullyCenteredPageContainer}>
      <Text>(Lots of information about this business account scoring delegate)</Text>
    </ScrollView>
  );
};

const SignedIn = () => {
  const [scoringUserInfo, setScoringUserInfo] = useState<ScoringUserInfo>();
  const updateScoring = (newInfo: ScoringUserInfo) => {
    setScoringUserInfo(newInfo);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ScoringInfoContext.Provider value={{scoringUserInfo, setScoringUserInfo: updateScoring}}>
        <FirestoreUserGate>
          <CustomAppBar>
            <RootStack />
          </CustomAppBar>
        </FirestoreUserGate>
      </ScoringInfoContext.Provider>
    </QueryClientProvider>
  );
};

const styles = StyleSheet.create({
  appBarButtonPadding: {
    padding: 10,
  },
  appSettingsButtonStyle: {
    // padding: 10,
    height: 30,
    width: '80%',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  content: {
    paddingHorizontal: 20,
  },
  centeredPadded: {
    alignSelf: 'center',
    alignContent: 'center',
    padding: 15,
  },
  fullyCenteredPageContainer: {
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageContainer: {
    flex: 1,
    padding: 20,
  },
  providers: {
    elevation: 4,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginVertical: 30,
    padding: 20,
  },
  divider: {
    height: 4,
  },
  profile: {
    marginTop: -50,
    paddingVertical: 10,
  },
  avatar: {
    borderWidth: 5,
    elevation: 4,
  },
});
export default SignedIn;
