import AppConfig from '../AppConfig';
import {translation} from './index';
require('dayjs/locale/es');

// Also check iOS permissions string translations in the file:
// <root>/ios/KScoreApp/es.lproj/InfoPlist.strings

export const spanish: translation = {
  loading: 'Cargando',
  Success: 'Éxito',
  OK: 'OK',
  forgotPassword: 'Olvidó Contrasena?',
  createAnAccount: 'Crea una Cuenta',
  phoneSignIn: 'Entra con número de móvil',
  phoneSignInTitle: 'Entra con Número de Móvil',
  signIn: 'Entra',

  home: 'Inicio',
  NotFound: 'Página No encontrada',
  PageNotFoundText: 'Nos esforzamos mucho, pero no pudimos encontrar esa página.',
  gettingStarted: 'Como Empezar',
  userInfo: 'Usuario',
  settings: 'Configuración',

  createAccountError: 'Crea Cuenta - Error',
  createAccountPasswordsDifferent: 'Las contraseñas son diferente',
  createAccountInstructions:
    'Crea una cuenta con su correo electrónico y contraseña. Una vez creada, entrará automáticamente en su perfil,',
  emailLabel: 'Correo Electrónico',
  passwordLabel: 'Contraseña',
  createAccountPasswordConfirmLabel: 'Confirma Contraseña',
  passwordsDoNotMatch: 'Contraseñas no estan de acuerdo',
  createAccountCreating: 'Creando Cuenta',
  createAccountCreate: 'Crea Cuenta',

  forgotPasswordInstructions:
    'Entra su correo electrónico debajo para enviar un email para reestablecer su contraseña',
  forgotPasswordLabel: 'Correo Electrónico',
  forgotPasswordError: 'Olvodó Password - Error',
  forgotPasswordSending: 'Enviando correo',
  forgotPasswordSend: 'Envia Correo',

  phoneVerificationCode: 'Código de Verificación',
  phoneVerificationConfirm: 'Confirma',
  phoneVerificationCountryInstructions: 'Marca para elegir pais de telefono',
  phoneVerificationNumberInstructions: 'Entra su número de móvil:',
  phoneVerificationNumberLabel: 'Número de Móvil',
  phoneVerificationNumberSubmit: 'Entra',

  signInSigningIn: 'Entrando',
  signInSignIn: 'Entra',

  profileLastSignIn: 'Último ingreso',

  userUpdateError: 'Error Actualizando Usuario',
  userEmailVerify: 'Re-enviar',
  userEmailVerification: 'Verificación',
  userEmailVerificationInstructions1: 'Se ha enviado un correo electrónico de verificación a',
  userEmailVerificationInstructions2:
    'Siga las instrucciones para verificar su dirección de correo electrónico',
  userEmailVerificationBanner:
    'Por favor, verifique su dirección de correo electrónico para utilizar todas las funciones de esta aplicación. Haz clic en el botón re-enviar de abajo para re-enviar un correo electrónico de verificación. Si ya ha verificado, toca el botón re-verificar para actualizar su estado en la app.',
  userEmailVerifyTitle: 'Verifificación de correo',
  userEmailVerificationSuccess: 'Ha verificado su dirección de correo electrónico con éxito.',
  userEmailVerificationFailure:
    'Se parece que su correo no esta verificado. Intenta re-enviar el correo otra vez y sigue las instrucciones en el correo.',
  userEmailVerificationVerifyButton: 'Re-verify',
  userDisplayLabel: 'Configuración',
  userNameDisplayLabel: 'Nombre para mostrar',
  userNameDisplayInstructions:
    'Establecer un nombre de visualización para un saludo personalizado.',
  userNameDisplaySave: 'Guardar',
  userNameDisplayUpdatedTitle: 'Nombre para mostrar Cambiado',
  userNameDisplayUpdateMessage: 'Su nombre para mostrar ya esta cambiado',
  userPasswordUpdateLabel: 'Actualiza Contraseña:',
  userPasswordInstructions:
    'Actualice la contraseña de su cuenta. Por razones de seguridad, introduzca la contraseña actual de su cuenta',
  userPasswordCurrent: 'Contraseña Actual',
  userPasswordNew: 'Contraseña Nueva',
  userPasswordConfirm: 'Contraseña Nueva Confirmada',
  userPasswordUpdate: 'Cambie',
  userSignOut: 'Salir',

  // Google auth messages
  googleAuthErrorTitle: 'Error con Google',
  googleAuthCancelled: 'Google autenticación cancelado.',
  googleAuthInProgress: 'La autenticación de Google ya está en marcha.',
  googleAuthPlayServices: 'La autenticación de Google requiere los servicios de Google Play.',
  googleAuthConfigError: 'Google Auth no se ha configurado correctamente para esta aplicación.',
  // TODO get catalog of google error messages and translate them

  // Facebook auth messages
  facebookAuthErrorTitle: 'Error con Facebook',
  facebookAuthCancelled: 'Facebook autenticación cancelado.',
  facebookAuthErrorMessage: 'No obtenemos token de acceso por parte de Facebook.',
  // TODO get catalog of facebook error messages and translate them

  // Apple auth messages
  appleAuthErrorTitle: 'Error con Apple Auth',
  appleAuthErrorMessage: 'No obtenemos un token de identidad desde Apple.',
  // TODO get catalog of Apple auth error codes and translate them

  // Firebase auth error messages
  unknownError: 'Se ha producido un error inesperado. por favor, inténtelo de nuevo',
  'invalid-session': 'Sesión inválida',
  'invalid-session-message': 'No has iniciado sesión, ingresa de nuevo por favor',
  'registration-error': 'Error de registro',
  'user-created': 'Usuario registrado',
  'user-created-message': 'Usuario nuevo registrado con éxito',
  'change-password-email': 'Vínculo enviado para cambio de contraseña',
  'change-password-email-message':
    'Revisa tu correo y cambia la contraseña a través del link que te acabamos de enviar',
  'change-password-email-error': 'Error al enviar correo para cambio de contraseña',
  'change-password-email-error-message':
    'Disculpa, ha habido un error enviando el correo para cambiar tu contraseña. Por favor rectifica tu correo para cambiar tu contraseña e intenta ingresar de nuevo.',
  'change-password-error': 'Error al cambiar contraseña',
  'change-password-successful': 'Constraseña actualizada',
  'change-password-successful-message':
    'Has cambiado tu contraseña con éxito. Por favor intenta ingresar con tu nueva contraseña.',
  'logout-error': 'Error cerrando sesión',
  'login-error': 'Error iniciando sesión',
  'email-send': 'Email de verificación enviado',
  'email-send-message': 'Hemos enviado un nuevo link para verificar tu cuenta',
  'email-send-error': 'Error verificando Email',
  'email-not-verified': 'Email no verificado',
  'email-not-verified-message': 'Estimado usuario, tu email aún no ha sido verificado',
  'email-verified': 'Email verificado',
  'email-verified-message': 'Tu email ha sido verificado con éxito',
  'phone-link-error': 'Error verificando dispositivo',
  'phone-verify-error': 'Error verificando dispositivo',
  'phone-auth-error': 'Debes ingresar tu número completo con el código de país, ej: +59398111xxxx',
  'phone-code-sent': 'Código SMS Enviado',
  'phone-code-sent-message': 'El código SMS para verificar tu dispositivo fue enviado con éxito',
  'phone-code-auto': 'Código SMS recibido',
  'phone-code-auto-message': 'El código SMS para verificar tu dispositivo fue recibido con éxito',
  'phone-link-success': 'Dispositivo verificado',
  'phone-link-success-message': 'Tu dispositivo ha sido verificado con éxito',
  'auth/firebase-auth': 'Esta applicación solo funciona en dispositivos con Google Play Services',
  'auth/app-not-authorized':
    'Esta applicación no tiene permiso para servicios de autenticación. Contacte servicio al cliente.',
  'auth/captcha-check-failed':
    'Error en control de CAPTCHA. Por favor espere un momento e intente de nuevo',
  'auth/credential-already-in-use':
    'Su dispositivo ya está en uso. Elija otro o contáctenos para solucionar.',
  'auth/session-expired': 'Sesión caducado, por favor intente de nuevo',
  'auth/invalid-action-code': 'Código caducado, por favor intente de nuevo',
  'auth/code-expired': 'Código caducado, por favor intente de nuevo',
  'auth/network-request-failed': 'Problema con la red, por favor intente de nuevo',
  'auth/expired-action-code': 'Código usado o malformado, por favor intente de nuevo',
  'auth/invalid-phone-number': 'Formato incorrecto de número de teléfono',
  'auth/provider-already-linked': 'Ya verificó con este método de inicio de sesión',
  'auth/invalid-verification-code':
    'El código SMS que ha entrado es inválido. Enviar de nuevo por favor',
  'auth/invalid-verification-id':
    'El código SMS que ha entrado es inválido. Enviar de nuevo por favor',
  'auth/invalid-email': 'Formato incorrecto de email',
  'auth/email-already-in-use':
    'Este email ya está en uso. Por favor elija otro email o haga clik en "Olvido contraseña',
  'auth/quota-exceeded':
    'Esta applicación ha superado cuota de SMS. Por favor contacte servicio al cliente',
  'auth/user-disabled': 'Esta cuenta ha sido desactivada',
  'auth/user-not-found': 'Email o contraseña incorrecta',
  'auth/unknown':
    'Disculpe, ha ocurrido un problema con su solicitud. Usualmente significa que necesita esperar unos minutos e intentar de nuevo',
  'auth/wrong-password': 'Email o contraseña incorrecta',
  'auth/weak-password': 'LA contraseña debe tener mínimo 6 carácteres',
  'auth/too-many-requests':
    'Demasiado códigos pedidos demasiado rápido. Por favor espere un rato y intente de nuevo más tarde.',
  'auth/invalid-credential':
    'Error en validación. Por favor cierre la app, ábrela de nuevo, y re-intentar',

  Close: 'Cerrar',
  GenderLabel: 'Género',
  FAQTitle: 'Komp.ai FAQ',
  UpdateNotificationChannel: 'Actualizaciones',
  UpdateNotificationChannelDesc: 'Notificaciones acerca actualizaciones de la applicacion',
  NoUpdateAvailableTitle: 'Ya esta corriente',
  NoUpdateAvailableText: 'Ya tiene la versión más reciente',
  UpdateAvailableTitle: `Komp.ai${
    AppConfig.getReleaseStream() === 'prod' ? '' : ` ${AppConfig.getReleaseStream()}`
  } versión nueva disponible!`,
  UpdateAvailableText: 'Hay una versión nueva disponible. Actualiza por favor',
  UpdateLater: 'Mas tarde',
  NewsPageName: 'Noticias',
  Cancel: 'Cancelar',
  Update: 'Actualiza',
  GPSNotificationChannel: 'Komp.ai GPS',
  GPSNotificationTitle: `Komp.ai${
    AppConfig.getReleaseStream() === 'prod' ? '' : ` ${AppConfig.getReleaseStream()}`
  } GPS`,
  GPSNotificationText: 'Mejorando tu Komp.ai Score con tus movimientos...',
  About: 'Acerca',
  NotificationsLabel: 'Novedades',
  DashboardLabel: 'Inicio',
  DashboardProfileTitle: 'Actualiza tu perfil',
  DashboardProfileText:
    'Mientras más datos tengamos de ti, más fácil sera calcular tu Komp.ai score',
  Quiz: 'Encuesta',
  DashboardQuizText:
    'En tan solo 2 minutos podrás mejorar tu Komp.ai score contestando 10 preguntas.',
  Participate: 'Participar',
  Permissions: 'Permisos',
  DashboardPermissionsText: 'Necesitamos ciertos permisos para poder calcular tu Komp.ai score.',
  Authorize: 'Autorizar',
  EmailVerify: 'Verifica Email',
  EmailVerifyTitle: 'Verificar Email',
  EmailSent: 'Email enviado a',
  EmailCheckMail: 'Busca por tu correo para cumplir registración',
  EmailCheckButton: 'Verificado, siga',
  EmailResendButton: 'Enviar de nuevo',
  EmailCorrectAddress: 'Necesitas corregir tu correo?',
  EnterEmail: 'Ingresa tu Email',
  NeedHelp: 'Necesitas ayuda?',
  ForgotPassword: 'Olvidó Contraseña',
  ForgotPasswordTitle: 'Contraseña Olvidada',
  ForgotPasswordSend: 'Enviar Correo Para Cambiar',
  ForgotPasswordLink: 'Olvidaste tu contraseña?',
  AlreadyRegisteredLink: 'Ya estás registrado?',
  History: 'Historial',
  RegisterButton: 'Registrar con correo',
  LandingPhrase1: 'Construyendo ',
  LandingPhrase2: 'confianza',
  LandingPhrase3: 'a donde vayas',
  LandingButtonPreamble: 'Regístrate. Es fácil y gratis!',
  LoginGoogleButton: 'Continuar con Google',
  LoginFacebookButton: 'Continuar con Facebook',
  SigninGoogleButton: 'Continuar con Google',
  SigninFacebookButton: 'Continuar con Facebook',
  LoginButton: 'Iniciar sesión',
  LoginTitle: 'Iniciar Sesión',
  LoginFirstLoginTitle: 'Bienvenidos!',
  LoginFirstLoginText:
    'Si es tu primera vez aquí, haga clic en "Crear una cuenta".\n\nPero si ya tienes una cuenta con otro método de inicio, haz clic en "Cancelar", inicie sesión con tus métodos de inicio existentes, utilices "Conectar cuenta" para vincularlos a este método.  De lo contrario, tendrás dos cuentas de Komp.ai',
  LoginFirstLoginOtherAccountsButton: 'Cancelar',
  LoginFirstLoginPleaseContinueButton: 'Crea Cuenta',
  LoginApplePrivacyTitle: 'Notificación de privacidad de Apple',
  LoginApplePrivacyText:
    'Komp.ai te permite generar una calificación que es útil en el mundo real, basada en información no anónima como su número de teléfono, y posiblemente conectada con otras cuentas sociales que tampoco son anónimas. Si haces clic en "Aceptar", aceptarás la conexión de tu ID de Apple con información no anónima.',
  LoginEmailRequiredTitle: 'Email Requirido',
  LoginEmailRequiredText:
    'El uso de Komp.ai servicios se require un email válido, por favor aseguras que nos otorgas permiso a tu email y intentas ese método de ingresar de nuevo.',
  Email: 'Email',
  Password: 'Contraseña',
  Enter: 'Ingresar',
  NotRegisteredLink: 'No tiene una cuenta? Regístrate aquí',
  MandatoryFieldError: 'campo obligatorio',
  Logout: 'Salir',
  LogoutText: 'Cierra su sesión?',
  LogoutButton: 'Cerrar sesión',
  DeleteAccount: 'Eliminar cuenta',
  DeleteAccountText: 'Eliminar tu cuenta y todos los datos relacionados',
  DeleteAccountButton: 'Eliminar cuenta ahora',
  DeleteAccountWarning:
    'Tenga en cuenta que nuestra encriptación orientada a la privacidad significa que no hay posibilidad de recuperación de cuentas o datos.\n\nLa cuenta se elminará inmediatamente, pero la eliminación completa de los datos toma aproximadamente un día. Datos relativos a transacciones específicas con otro usuario seguirán siendo visibles para ese usuario como parte de su historial.',
  DeleteAccountSuccess:
    'La solicitud de eliminación de cuenta se ha realizado con éxito. La eliminación de la cuenta está en curso. Eres bienvenido en cualquier momento que necesites un Komp.ai score en el futuro.',
  DeleteAccountReauthenticateTitle: 'Se requiere una sesión fresca',
  DeleteAccountReauthenticateText:
    'Lo sentimos, algunas operaciones delicadas requieren un inicio de sesión reciente. Esta sesión es lo suficientemente antigua como para que tenga que cerrar sesión, iniciar sesión de nuevo y volver a intentarlo.',
  DeleteAccountPopup:
    'Debido a que esta acción es irrevocable, requerimos una confirmación para asegurarnos de que es lo que deseas.',
  PermissionBlockedDialogText:
    'Algunos permisos están bloqueados en tu dispositivo. Komp.ai se puede calcular un score de más calidad si todos los permisos estan otorgados. Por favor cámbialos en Preferencias del Sistema y haz clic aquí otra vez.',
  PermissionDialogText: 'Mientras más información compartas, mejor será tu Komp.ai score',
  PermissionTermsLink: 'Komp.ai Términos y Condiciones',
  SharingPermissionTermsLink: 'Compartir Komp.ai Score Términos y Condiciones',
  PermissionText:
    'Necesitamos permiso para procesar los datos para calcular un Komp.ai score. Todo opcional pero en general menos fuentes hacen un score más bajo.\n\nCreemos que tus datos son tuyos, y que todos los usos de los mismos están bajo tu completo control',
  PermissionAcceptStart: '¿Aceptar',
  PermissionAcceptFinish: 'términos y condiciones?',
  PermissionGrant: 'Permitir',
  PhoneVerify: 'Verifica Móvil',
  PhoneVerifySwitch:
    'Ya tienes un número celular verificado. Ingresa uno nuevo si has cambiado de número celular',
  DashboardPhoneVerifyText: 'Verificar tu número celular para aumentar confianza en los datos',
  PhoneNumberPrefix: '+593',
  PhoneVerifyNotLoggedIn: 'Debe ser con sesión antes de verificar móvil. Ingresa primero...',
  PhoneVerifyLogin: 'Va a ingresar',
  PhoneVerifySendCodeError: 'enviar código ante verificar',
  PhoneNumberInstructions: 'Número celular (formato +593xxyyyzzzz)',
  PhoneNumberSendCode: 'Enviar código SMS',
  PhoneNumberCodeWait:
    'Espera para código por SMS antes de reintentarlo. Si no te llega en unos minutos, comunicate con servicio al cliente.',
  PhoneNumberEnterCode: 'Ingresa el código SMS recibido',
  PhoneNumberVerify: 'Verificar',
  ReturnToLogin: 'Regresa a Inicio',
  Register: 'Registrarse',
  True: 'Verdad',
  False: 'Falso',
  DemographicsCompleteTitle: 'Información completada',
  DemographicsCompleteText: 'Hemos guardado tus respuestas con éxito',
  DemographicsAnswers: 'Respuestas',
  DemographicsInstructions: 'Cuéntanos más sobre ti con las siguientes preguntas sociodemográficas',
  DemographicsStartButton: 'Añadir información',
  DemographicsQuestion1: 'Tienes tu propio carro?',
  DemographicsQuestion1Answer1: 'No',
  DemographicsQuestion1Answer2: 'Si',
  DemographicsQuestion2: 'Cuál es tu género?',
  DemographicsQuestion2Answer1: 'Femenino',
  DemographicsQuestion2Answer2: 'Masculino',
  DemographicsQuestion3: 'Cuál es tu estado civil?',
  DemographicsQuestion3Answer1: 'Casado',
  DemographicsQuestion3Answer2: 'Divorciado',
  DemographicsQuestion3Answer3: 'Soltero/Soltera',
  DemographicsQuestion3Answer4: 'Unión libre',
  DemographicsQuestion4: 'Cuántos hijos tienes?',
  DemographicsQuestion4Answer1: '0',
  DemographicsQuestion4Answer2: '1',
  DemographicsQuestion4Answer3: '2',
  DemographicsQuestion4Answer4: '3',
  DemographicsQuestion4Answer5: '4+',
  DemographicsQuestion5: 'Cuál es tu sueldo?',
  DemographicsQuestion5Answer1: '$0-500 USD/mes',
  DemographicsQuestion5Answer2: '501-1000 USD/mes',
  DemographicsQuestion5Answer3: '1001-2000 USD/mes',
  DemographicsQuestion5Answer4: '2001+ USD/mes',
  DemographicsQuestion5Answer5: 'No responder',
  DemographicsQuestion6: 'Eres dueño o arrendador?',
  DemographicsQuestion6Answer1: 'Arrendador/Arrendadora',
  DemographicsQuestion6Answer2: 'Vivienda propia no hipotecada',
  DemographicsQuestion6Answer3: 'Vivienda propia hipotecada',
  DemographicsQuestion6Answer4: 'Otro (vives con padres, etc)',
  DemographicsQuestion6Answer5: 'No responder',
  DemographicsQuestion7: 'Cuál es tu nivel de educación?',
  DemographicsQuestion7Answer1: 'Primario',
  DemographicsQuestion7Answer2: 'Secundario',
  DemographicsQuestion7Answer3: 'Tecnológico',
  DemographicsQuestion7Answer4: 'Universitario',
  DemographicsQuestion7Answer5: 'Posgrado',
  DemographicsQuestion8: 'Has tenido una tarjeta de credito?',
  DemographicsQuestion8Answer1: 'No',
  DemographicsQuestion8Answer2: 'Si',
  QuizQuestion1: 'Extrovertida, entusiasta',
  QuizQuestion2: 'Colérica, discutidora',
  QuizQuestion3: 'Fiable, auto-disciplinada',
  QuizQuestion4: 'Ansiosa, fácilmente alterable',
  QuizQuestion5: 'Abierta a nuevas experiencias',
  QuizQuestion6: 'Reservada, callada',
  QuizQuestion7: 'Comprensiva, amable',
  QuizQuestion8: 'Desorganizada, descuidada',
  QuizQuestion9: 'Serena, emocionalmente estable',
  QuizQuestion10: 'Tradicional, poco imaginativa',
  QuizAnswer1: 'Totalmente en desacuerdo',
  QuizAnswer2: 'Bastante en desacuerdo',
  QuizAnswer3: 'En desacuerdo',
  QuizAnswer4: 'Ni de acuerdo ni en desacuerdo',
  QuizAnswer5: 'De acuerdo',
  QuizAnswer6: 'Bastante de acuerdo',
  QuizAnswer7: 'Totalmente de acuerdo',
  QuizCompleteTitle: 'Encuesta completada',
  QuizCompleteText: 'Hemos guardado tus respuestas con éxito',
  QuizAnswers: 'Respuestas',
  QuizInstructionsQuestion: '¿Qué tipo de personalidad tienes?',
  QuizInstructionsQuestionPrompt: '¿Me veo a mi mismo/a como a una persona...',
  QuizInstructions:
    'En solo 2 minutos puedes mejorar tu Komp.ai score contestando 10 preguntas.\n\nMueve el slider para escoger tu respuesta, según el ejemplo debajo.',
  QuizLegend: 'Legend:',
  QuizStartButton: 'Empieza Ahora',
  Send: 'Enviar',
  QuizResults: 'Encuesta Resultados',
  QuizOTitle: 'Apertura',
  QuizOInformation: 'La apertura refleja tu disposición a aceptar experiencias nuevas e inusuales.',
  QuizOHigh:
    'Aprecias las ideas creativas, originales e innovadoras. Buscas experiencias que te saquen de la rutina y te alienten a pensar de una manera diferente.',
  QuizOMedium:
    'No eres especialmente poco convencional ni demasiado convencional en tu forma de pensar. Tiendes a pensar en ti mismo como una persona práctica, pero abierta a nuevas ideas y puntos de vista.',
  QuizOLow:
    'Eres una persona pragmática, directa y convencional. Te enfocas en los hechos por lo que tienes poco interés en teorías e ideas abstractas que no tengan aplicaciones prácticas para el mundo real. En tu tiempo libre prefieres actividades en las que encuentres una recompensa tangible.',
  QuizCTitle: 'Responsabilidad',
  QuizCInformation:
    'La responsabilidad se trata de cómo controlamos y actuamos sobre nuestros impulsos.',
  QuizCHigh:
    'Eres organizado y auto disciplinado. Te gusta hacer planes, seguir rutinas y horarios. Al momento de tomar decisiones, prefieres tomarte un tiempo antes para considerar los resultados y todas tus alternativas.',
  QuizCMedium:
    'A menudo te distraes con facilidad, lo que causa que postergues tus tareas o pierdas el interés en cumplirlas. Pero cuando la situación lo requiere, eres capaz de controlar tus impulsos y enfocar tu atención para alcanzar tus propósitos.',
  QuizCLow:
    'Eres espontaneo y reaccionas bien ante situaciones y cambio inesperados. A menudo encuentras que las reglas son arbitrarias y asfixiantes, por lo que no te importa romperlas.',
  QuizETitle: 'Extraversión',
  QuizEInformation:
    'La extraversión refleja la energía con la que te relacionas con el mundo exterior.',
  QuizEHigh:
    'Te gusta estar en constante movimiento, disfrutas de grandes fiestas y multitudes. Eres alegre, optimista y haces amigos fácilmente. Debido a que las emociones positivas te conmueven tan fácilmente, estás particularmente interesado en lograr éxitos que te traigan felicidad o emoción.',
  QuizEMedium:
    'No te molesta hablar en público, si el objetivo es expresar tu opinión para defender tu punto de vista. Prefieres los lugares tranquilos en los que puedas disfrutar con tu círculo cercano de amigos.',
  QuizELow:
    'Eres reservado, tranquilo y discreto. Tiendes a evitar ambientes ruidosos ya que los encuentras abrumadores. A menudo te resulta difícil expresarte y prefieres que otros hablen.',
  QuizATitle: 'Amabilidad',
  QuizAInformation: 'La amabilidad indica su preocupación por la felicidad de los grupos sociales.',
  QuizAHigh:
    'Eres muy empático y te identificas naturalmente con las emociones de los demás. Valoras la cooperación, la armonía social y asumes que la mayoría de las personas son justas y honestas.',
  QuizAMedium:
    'Eres respetuoso con las opiniones de los demás por lo que las personas recurren a ti para ser escuchados. Prefieres evitar los conflictos, razonablemente, pero no te sientes incómodo por la necesidad de hacer frente a otras personas si es necesario.',
  QuizALow:
    'Priorizas tus propias necesidades e intereses. Eres directo en tu estilo de comunicación y no te importa confrontar abiertamente o desafiar a los demás. Valoras la verdad, la justicia y los juicios objetivos basados únicamente en la razón.',
  QuizNTitle: 'Estabilidad Emocional',
  QuizNInformation:
    'La estabilidad emocional muestra su tendencia a responder emocionalmente a los eventos',
  QuizNHigh:
    'Tiendes a permanecer tranquilo y relajado incluso en situaciones estresantes y que provocan ansiedad a los demás. Rara vez te preocupas por cómo resultarán las cosas y enfrentas las situaciones sin temor a las consecuencias negativas.',
  QuizNMedium:
    'En más de una ocasión necesitas sentirte anclado a la realidad a través del ejemplo que ves en otras personas. El estado emocional del momento influye mucho en ti, pero no permites que esto afecte con el cumplimento de tus actividades diarias.',
  QuizNLow:
    'Tiendes a ser más vulnerable en situaciones de estrés y menos capaz de superar situaciones problemáticas. Eres sensible a lo que otras personas piensan de ti y su opinión puede llegar a desanimarte fácilmente.',
  QuizYourResult: 'Tu resultado:',
  QuizAverageResult: 'Resultado promedio:',
  QuizPersonalizedInformation: 'Información personalizada:',
  UpdateLabel: 'Actualizar App',
  UpdateAlertText: 'Hay una nueva versión disponible. ¿Quisieras actualizar?',
  ErrorAlert: 'Fue un error.',
  UpdateApplicationText: 'Actualiza la aplicación',
  UpdateInstalledVersion: 'Versión instalado:',
  UpdateProgress: 'Descargado:',
  UpdateButton: 'Intenta Actualizar',
  HomePageGreeting: 'Bienvenidos',
  WelcomeTitle: 'Bienvenidos!',
  Actions: 'Acciones',
  WelcomeText:
    'El Komp.ai score es una nueva manera de construir confianza entre personas.\n\nEn pocos minutos podrás calcular tu score y compartirlo con otros.\n\nSigue los siguientes pasos, con más explicación en cada paso:',
  WelcomeTaskList:
    '1. Completa tu perfíl\n2. Otorga permisos\n3. Registra información sociodemográfica\n4. Toma una encuesta corta\n5. Usa tu score para aprovechar las promos',
  WelcomeContinueButton: 'Vamos!',
  OnboardingCompleteTitle: 'Enhorabuena!',
  OnboardingCompleteText:
    'Has logrado! Te damos la bienvenida a un mundo donde podrás calcular y compartir scores cuando quieras, todo bajo tu completo control.\n\nAquí estan algunas cosas que puedes hacer:',
  OnboardingCompleteQuizText: 'Ver tus resultados de encuesta',
  OnboardingCompleteQuizButton: 'Ver Encuesta',
  OnboardingCompleteScoreNotYet: 'Calcular tu primer Komp.ai score',
  OnboardingCompleteScoreShow: 'Ya tienes tu primer score!',
  ProfileLabel: 'Perfil',
  ProfileUpdatedTitle: 'Perfil Actualizado',
  ProfileUpdatedText: 'Hemos actualizado tu perfil con éxito',
  ProfileCedulaLengthError: '10 dígitos solamente',
  ProfileCedulaInvalid: 'formato inválido',
  ProfileBirthdateFormat: 'dd/MM/yyyy',
  ProfileFirstNameLabel: 'Nombres',
  ProfileSecondNameLabel: 'Apellidos',
  ProfileFullNameLabel: 'Nombre',
  ProfileProfileNameLabel: `Usuario: ${AppConfig.getWebAppURIRoot()}/{0}`,
  ProfileNameNotUnique: 'No es disponible',
  ProfileNameResolutionFailure: 'Error chequar si es disponible',
  ProfileNameTooShort: 'minimo 8 letras',
  ProfileNameInvalidChars: 'solo usa letras a-z, A-Z, y 0-9',
  ProfileCedulaLabel: 'Gob. ID / Pasaporte',
  ProfileCedulaJustNumbersLabel: '(solo números)',
  ProfileBirthdateLabel: 'F. Nacimiento',
  ProfileRegisterDateLabel: 'F. Registración',
  ProfileYearLabel: ' A',
  ProfileMonthLabel: ' M',
  ProfileDayLabel: ' D',
  ProfileNoPhone: '(Agregarlo después en Cuentas Conectadas)',
  ProfileAlreadyPermitted: 'Este usuario puede ver tu score',
  ProfileAlreadyGranted: 'Puedes ver score de este usuario',
  ProfileOwnPublicProfile: 'Este es tu perfíl',
  ProfileReadRedirectExternalTitle: 'Link Externo',
  ProfileReadRedirectExternalText:
    "Esto no parace como un Komp.ai Profile link. Abre '{0}' en navegador?",
  ProfileReadNotALinkTitle: 'No es Link',
  ProfileReadNotALinkText: 'No hay URL en código. Escanea un Komp.ai Perfíl link',
  ProfileVisitProfileInApp: 'Konectemos en la App para los detalles!',

  // MemberSinceLabel: 'Miembro desde',
  MemberSinceLabel: '#SocialmenteKonfiable desde',
  ProfilePower: 'Poder de Perfíl',

  LatestDeviceEventDateLabel: 'Contacto con Dispositivo más Recién',
  LatestDeviceEventDateFormat: 'dd/MM/yyyy HH:mm', //:ss', // 'UTC'zz",
  ProfileSave: 'Actualizar Perfíl',
  BirthdayQuestion: '¿Cuándo naciste?',
  Calculate: 'Calcular',
  CalculateDisabled:
    'La función de calcular solo está disponible para algunas personas por ahora. Por favor inténtalo de nuevo más tarde.',
  CalculateText:
    'Calcula un Komp.ai score para {0} de manera inmediata.\n\nPor favor ten paciencia, puede tardar unos minutos.',
  CalculateButton: 'Calcular Ahora',
  CalculateInsufficientData:
    'Debes llenar tu perfíl, completar la encuesta y información sociodemográfica al menos para calcular tu Komp.ai score.',
  CalculateInsufficientDataSecondaryUser:
    'Usuario {0} debe cumplir con estos requisitos minimos antes de puedes calcular un Komp.ai score.',
  CalculatingMessage: 'Estamos calculando ahora...',
  CalculatingError:
    'Discúlpanos, hubo un error calculando tu Komp.ai score. Por favor inténtalo de nuevo más tarde.',
  CalculatingErrorNotEnoughData:
    'Discúlpanos, no hay suficiente datos para calculatar un score. La app debe ser instalado con permisos otorgados.',
  CalculatingErrorDeletedUser:
    'Discúlpanos, este usuario ha borrado su cuenta, y no es posible calcular nuevos scores.',
  CalculateCostExplanation:
    'Pulse "Calcular ahora" para generar un nuevo informe basado en los datos más recientes del usuario. Se cargará en su cuenta un cálculo',
  ScoringTitle: 'Puntuación',
  ScoringCurrentProfileTitle: 'Perfil Actual de Puntuación',
  ScoringSelectProfile: 'Elige Perfil de Puntuación',
  ScoringDelegateGreeting: 'Estas haciendo puntuación en nombre del {0}',
  ScoringDelegateList: 'Delegados de Puntuación',
  ScoringDelegateDetails: 'Detalles del Delegado de Puntuación',
  ScoreTitle: 'Score',
  ShowScore: 'Ver Reporte',
  ShowScoreTitle: 'Reporte de Score',
  ShowScoreBeta: 'en entrenamiento',
  ShowScoreDate: 'Fecha de Calcula',
  ShowScoreFirstName: 'Nombre',
  ShowScoreLastName: 'Apellido',
  ShowScoreDOB: 'Fecha de Nac.',
  ShowScorePhoneNumber: 'Teléfono',
  ShowScoreNoScore: 'No hay Komp.ai scores para',
  ShowScoreUserDetailsTitle: 'User Datos',
  ShowScoreScoreDetailsTitle: 'Score Datos',
  ShowScoreContactsDetail: 'Contactos Analizados',
  ShowScoreCalendarDetail: 'Calendario Analizado',
  ShowScoreGPSDetail: 'Eventos GPS Analizados',
  ShowScoreGPSHistogram: 'Histograma de Eventos GPS',
  ShowScoreEventStreamDetail: 'Eventos Analizados en Total',
  ShowScoreEventStreamHistogram: 'Histograma de Eventos',
  ShowScorePersonalityDetail: 'Encuesta de Personalidad Analizada',
  ShowScoreDemographicsDetail: 'Datos Demográficos Analizados',
  ShowScoreDevicesDetailTitle: 'Información del Dispositivos',
  ShowScoreDevicesDetail: 'Dispositivos Analizados',
  ShowScorePhoneType: 'Tipo del Dispositiva',
  ShowScorePhoneOSVersion: 'Versión del sistema operativa',
  ShowScorePhoneAppVersion: 'Versión de la app Komp.ai',
  ShowScoreCarrierInfo: 'Carrier Celular',
  ShowScoreDetailYes: 'Sí',
  ShowScoreDetailNo: 'No',
  ShowScoreScoreHistory: 'Historia del Score',
  ShowScoreDataUploadDate: 'Actualizado',
  ShowScoreDataStale: 'Datos Caducados, App inactiva',
  ShowScoreMoreInfo: '¿Qué significa el score? ¿Cómo puedo mejorarlo?',
  ScoreInfoTitle: 'Información de Score',
  ScoreInfoMeaningTitle: '¿Qué significa el Komp.ai score?',
  ScoreInfoMeaningText:
    'El Kompai score tiene como objetivo predecir si una persona cumplirá o no con sus obligaciones financieras o morales. En un rango de 0 al 1000, un score hasta 850 significa alto riesgo. Entre 851-900 el riesgo es medio-alto. Entre 901-950 el riesgo es medio y consideramos que si el score es mayor a 950 el riesgo es bajo.',
  ScoreInfoImproveTitle: '¿Cómo puedo mejorarlo?',
  ScoreInfoImproveText:
    'El primer paso es asegurarse de que hay suficientes datos para calcular bien, lo que significa habilitar permisos en la aplicación si es posible. La otra forma es el trabajo duro a la antigua: tener patrones de vida regulares cuando sea posible y usar el teléfono para ayudar a organizar tu vida.',
  ScoreInfoButton: 'Regresar',
  Alliances: 'Promociones',
  AllianceEstablish: 'Establecer Alianza',
  UserAllianceEstablish: 'Permitir Ver Tu Score',
  AllianceDescription: 'Obtén beneficios especiales con tu Komp.ai score. Válido solo en ',
  alliances_btnAlliancesEC: 'Ecuador',
  alliances_btnAlliancesCR: 'Costa Rica',

  Sorteamos: 'Sorteamos ',
  DOS_PASAJES_GRATIS: '2 PASAJES GRATIS',

  Super_descuento_en_tus: 'Listo para tus ',
  AIRPODS_NUEVOS: 'NUEVOS AIRPODS?',

  AmazonEcho_Gratis: 'Amazon Echo GRATIS',
  Bono50_Gratis: 'Bono de $50',
  PremiosSemanales: 'Premios semanales',

  // CTA
  Los_Quiero: 'Los quiero',
  Participar: 'Participar',
  Me_interesa: 'Me interesa',
  SoliciteCreditoAqui: 'Solicite su crédito aquí',

  Completar: 'Completar',
  KnowOur: 'Conoce nuestras',
  GrantApp: 'Otorgar',
  Promotions: 'PROMOCIONES',
  CompleteYour: 'Completa tu',
  CompleteThe: 'Completa la',
  ProfileCaps: 'PERFIL',
  QuizCaps: 'ENCUESTA',

  Deal: 'Promo',
  ApplyForDeal: 'Me interesa',
  AllianceDetailSuccessTitle: 'Alianza hecha con éxito',
  AllianceDetailSuccessText: "Puedes cambiar estos permisos en 'Usuarios Permitidos'",
  AllianceEstablishedSuccessTitle: 'Conectaste Komp.ai !!',
  AllianceEstablishedSuccessText: "Puedes cambiar estos permisos en 'Usuarios Permitidos'",

  label_ValidOnly: 'Válido solo en',

  Alliance_Andalucia_ValidOnly: 'Válido solo en Ecuador',
  Alliance_CuencaCLUB_ValidOnly: 'Válido solo en Ecuador',
  Alliance_Pago46_ValidOnly: 'Válido solo en Chile',

  PermittedUsers: 'Usuarios Permitidos',
  PermittedUsersDesc: 'Usuarios a quienes has otorgado permiso consultar tu score',
  PermittedUsersNoUsers: 'No has otorgado permiso a nadie',
  PermittedUsersRemoveConnection: 'Eliminar Permiso',
  ConsultScores: 'Consultar Scores',
  ConsultScoresUserListTitle: 'List del Usuarios Vinculados',
  ConsultScoresUserCount: '{0} usuarios vinculados con {1}',
  ConsultScoresNoUsers: 'No hay usuarios vinculados',
  ConsultScoresSearchPlaceholder: 'Busca por {0}',
  ConsultScoresSearchNoResults: 'Busqueda sin resultados',
  ConsultScoresViewDetails: 'Ver Detalles',
  ConsultScoresConsult: 'Ver Score De Usuario',
  ConsultScoresSilentPush: 'Contacta dispositivo',
  ConsultScoresSendNotification: 'Envia Notificación',
  ConsultScoresRemoveConnection: 'Eliminar',
  ConsultScoresRemoveConnectionText:
    'Si eliminas esta permiso, no puedes consultar este usuario nunca más',
  ConsultScoresGetProfileLinkStats: 'Stats de perfíl',

  ScoreBatchesListTitle: 'List del Scores Batches',
  ScoreBatchId: 'Batch ID',
  ScoreBatchCreatedDate: 'Fecha de Creación',
  ScoreBatchDetailsTitle: 'Detalles del Score Batch',
  ScoreBatchCreate: 'Crea nuevo Score Batch',

  LoadingDataMessage: 'Cargando datos desde el servidor, por favor sea paciente',
  FetchingUsersMessage: 'buscar usuarios',
  FunctionErrorTitle: 'Server error',
  FunctionErrorMessage: 'Problema  de conexión con el servidor, por favor inténtalo otra vez.',
  ConnectedAccountsLabel: 'Conectar Cuentas',
  ConnectedAccountsConnect: 'Conectar',
  ConnectedAccountsDisconnect: 'Desconectar',
  ConnectedAccountsPhone: 'SMS Verificación',
  ConnectedAccountsEmail: 'Email Verificación',
  ConnectedAccountsTitle: 'Inicio de sesión social y correo',
  ConnectedAccountsDesc:
    'Usa tus inicios de sesión en redes sociales para acceder de manera rápida, fácil y segura a tus Komp.ai servicios',
  ConnectedAccountsNoticeTitle: 'Desconectar una cuenta social',
  ConnectedAccountsNoticeText:
    'Si desconectas esta cuenta, ya no podrás usarla para iniciar sesión en tu cuenta Komp.ai',
  ConnectedAccountsMustHaveLoginTitle: 'Se necesita inicio de sesión',
  ConnectedAccountsMustHaveLoginText: 'No puedes eliminar el último método de acceso',
  ConnectedAccountsConnectionError: 'Error de conexión',

  DebugChannel: 'Debug Notificaciones',
  DebugChannelDescription: 'En las versiones debug y entorno Dev, hay notificaciones debug',
  ConnectionRequestChannel: 'Solicitudes de conexión',
  ConnectionRequestChannelDescription:
    'Te enviamos notificaciones cúando otro Komp.ai usuarios quieren conectar contigo para compartir sus scores',
  ImportantChannel: 'Notificaciones Importantes',
  ImportantChannelDescription:
    'Usando solo para notificaciones importantes desde otros usuarios o Komp.ai',

  ProfileShareSocialYour: '@',
  ProfileShareSocialDescription: 'Conecta conmigo por Komp.ai',

  BillingTitle: 'Facturación',
  BillingReport: 'Informe de Facturación',
  BillingTransaction: 'Transacción',
  BillingTransactionId: 'ID',
  BillingTransactionDate: 'Fecha',
  BillingTransactionQuantity: 'Cantidad',
  BillingTransactionBalance: 'Balanza Corriente',
  BillingTransactionType: 'Tipo',
  BillingTransactionUserBy: 'Comprador',
  BillingTransactionUserFor: 'Sujeto',
  BillingTransactionNote: 'Nota',
  BillingTransactionDocument: 'Relación',
  BillingTransactionCount: '{0} transacciónes para {1}',
  BillingTransactionTypeCalculateReport: 'Calcula',
  BillingTransactionTypePurchaseReport: 'Compra',
  BillingTransactionTypePurchaseExpiration: 'Caduca',

  // Dashboard Profile

  ProfileUserAppSettingsTitle: 'Ajustes de App y Perfil',
  ProfileTitle: 'Datos del Usuario',
  ProfileSwitchTitle: 'Perfiles de Puntuación',
  ProfileUserSettingsTitle: 'Ajustes del Perfil del Usuario',
  ProfileUserSettingsUserHeader: 'Perfil Personal',
  profile_btnEditProfile: 'Editar Perfil',
  profile_btnSharingProfile: 'Compartir / Leer',
  profile_btnSharingProfileShare: 'Compartir',
  profile_btnSharingProfileRead: 'Leer',
  profile_btnViewPublicProfile: 'Ver Perfil Público',
  profile_btnViewPublicConnectedProfile: 'Vista de Usuarios Permitidos',
  profile_btnViewPublicUnconnectedProfile: 'Vista de Usuarios Generales',

  profile_btnTIPI_title: 'Test de Personalidad',
  profile_btnTIPI_subtitle: 'Mejora tu Komp.ai score en 2 minutos ',

  profile_btnDemographics_title: 'Info Sociodemográfica',
  profile_btnDemographics_subtitle: 'Cuéntanos más sobre ti',

  profile_btnUsuariosAliados_title: 'Usuarios Aliados',
  profile_btnUsuariosAliados_subtitle: 'Pueden consultar tu Komp.ai score',

  profile_btnPermisosAsignados_title: 'Permisos Asignados',
  profile_btnPermisosAsignados_subtitle: 'A qué datos accedemos',

  profile_btnCuentasConectadas_title: 'Cuentas Conectadas',
  profile_btnCuentasConectadas_subtitle: 'Inicia sesión fácilmente',

  quiz_btnTakeQuiz_title: 'Realizar el Test',
  quiz_btnTakeQuiz_subtitle: '10 preguntas para mejorar tu score',

  quiz_btnReTakeQuiz_title: 'Tomar el Test otra vez',
  quiz_btnReTakeQuiz_subtitle: 'Ok, te daremos otro chance',

  quiz_btnQuizResults_title: 'Resultados del Test',
  quiz_btnQuizResults_subtitle: 'Veamos como lo hiciste',

  Score_congratulations_title: 'Felicitaciones',
  Score_detail_subtitle: 'Tu Komp.ai score es de',

  Score_YouAreReady_title: 'Ya estás listo',
  Score_socialmentekonfiable_subtitle: 'Para ser #socialmentekonfiable',

  Score_CalculateKS_title: 'Calcula tu Komp.ai Score',
  Score_YouAreReady_subtitle: 'Ya estás listo para calcular',

  Score_NotReady_title: 'Oops, algo te faltó',
  Score_ReviewSteps_subtitle: 'Revisalo aquí para calcular tu score',

  Score_CalculateScores_title: 'Consulta scores de otros',
  Score_CalculateScores_subtitle: 'Ver usuarios que te otorgaron permiso',

  Score_ViewReport_subtitle: 'Revisa tu informe',
  Score_Calculate_subtitle: 'Usa datos actuales para calcular un score',

  NoPermittedUsers_title: 'Aplica para promos',
  NoPermittedUsers_subtitle: 'Usa tu Komp.ai score para promos',

  ConsultScoresWebMissingLabel: 'Falta',
  DownloadConnectedUsers: 'Exportar Base de Datos',
  MeetsCalculationRequirements: 'Requisitos de calcular hecho?',

  // card Conocer mas sobre ti
  cardConocerMas_title: 'Tú eres la ',
  cardConocerMas_subtitle: 'ESTRELLA',
  cardConocerMas_CTA: 'Cuéntanos más de ti',

  calculate_wait_0: 'Agregando datos para la calculación...',
  calculate_wait_1: 'Enviando datos al servidor para calcular...',
  calculate_wait_2: 'Calculando score...',

  // If you add jokes, change array of joke indices to match in ../components/controls/WaitingText.tsx
  waiting_joke_0: 'Contemplando que significa la vida...',
  waiting_joke_1: 'Calculando distancia al sol...',
  waiting_joke_2: 'Dando cariños a la mascota...',
  waiting_joke_3: 'Preparando almuerzo...',
  waiting_joke_4: 'Ya voy...',
  waiting_joke_5: 'Difundiendo memes por chat...',
  waiting_joke_6: 'Planeando vacaciones...',
  waiting_joke_7: 'Yendo de compras a la tienda...',
  waiting_joke_8: 'Aplastando amigos en videojuego...',
  waiting_joke_9: 'Fingiendo escuchar al profe...',

  // Notification translations
  NewConnectionNotificationTitle: 'Tienes un Komp.ai Nuevo!',
  NewConnectionNotificationBody: 'Ahora puedes calcular scores de {0}',
};
