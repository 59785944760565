import {DrawerActions} from '@react-navigation/core';
import {Fragment, useContext} from 'react';
import {Appbar, Avatar, Button, TouchableRipple} from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import {displayName} from '../../app.json';
import {UserContext} from '../App';
import {useAppSettings} from '../components/AppSettings';
import {mainNavigatorRef} from '../App';
import {darkTheme} from '../theme';
import {Image, StyleSheet} from 'react-native';

export const CustomAppBar = (props: {
  // Wrap the whole app so React.Context for app settings drawer toggle is in children's DOM tree.
  children: JSX.Element;
}): JSX.Element => {
  const authUser = useContext(UserContext);
  const appSettings = useAppSettings();

  const closeAppSettings = (): boolean => {
    let closedSettings = false;
    if (!mainNavigatorRef.isReady()) {
      return closedSettings;
    }
    let navState = mainNavigatorRef.getRootState();

    // There is a case where we cannot even go back, that means a user deep-linked to
    // a screen inside the app settings. In that case, reset completely like initial login.
    if (!mainNavigatorRef.canGoBack()) {
      if (navState.routes[navState.index].name === 'ProfileSettingsModal') {
        mainNavigatorRef.reset({
          index: 0,
          routes: [{name: 'App'}],
        });
        return true;
      } else {
        return false;
      }
    }
    while (
      mainNavigatorRef.canGoBack() &&
      navState.routes[navState.index].name === 'ProfileSettingsModal'
    ) {
      closedSettings = true;
      mainNavigatorRef.goBack();
      navState = mainNavigatorRef.getRootState();
    }
    return closedSettings;
  };
  return (
    <Fragment>
      <Appbar.Header>
        <Button
          onPress={async () => {
            if (!mainNavigatorRef.isReady()) {
              return;
            }
            closeAppSettings();
            setTimeout(() => {
              mainNavigatorRef.dispatch(DrawerActions.toggleDrawer());
            }, 100);
          }}>
          <Icon name="menu" color={darkTheme.colors.text} size={23} />
        </Button>

        <Image style={styles.icon} source={require('../..//static/assets/images/logos/KS.png')} />
        <Appbar.Content title={displayName} />
        <TouchableRipple
          style={styles.appBarButtonPadding}
          onPress={() => {
            if (!mainNavigatorRef.isReady()) {
              return;
            }
            const settingsWereOpen = closeAppSettings();

            if (!settingsWereOpen) {
              // @ts-ignore FIXME type the navigator
              mainNavigatorRef.navigate('ProfileSettingsModal');
            }
            // Only one drawer open at a time...
            mainNavigatorRef.dispatch(DrawerActions.closeDrawer());
          }}>
          {authUser?.photoURL ? (
            <Avatar.Image size={34} source={{uri: authUser?.photoURL}} />
          ) : (
            <Avatar.Text
              size={34}
              color={appSettings.currentTheme.colors.primary}
              style={{
                backgroundColor: appSettings.currentTheme.colors.surface,
              }}
              label={
                authUser?.displayName
                  ? authUser.displayName.substring(0, 2).toUpperCase()
                  : authUser?.email
                  ? authUser.email.substring(0, 2).toUpperCase()
                  : 'AI'
              }
            />
          )}
        </TouchableRipple>
      </Appbar.Header>
      {props.children}
    </Fragment>
  );
};

const styles = StyleSheet.create({
  appBarButtonPadding: {
    padding: 10,
  },
  appSettingsButtonStyle: {
    // padding: 10,
    height: 30,
    width: '80%',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  icon: {
    alignSelf: 'center',
    paddingStart: 20,
    marginStart: 20,
    width: 30,
    height: 30,
  },
});
