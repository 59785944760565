import {Animated, Pressable, StyleSheet, View} from 'react-native';
// import {Button} from 'react-native-paper';
import {useNavigation} from '@react-navigation/core';
// import {useCardAnimation} from '@react-navigation/stack';

import {useAppSettings} from '../components/AppSettings';
import {ProfileSettingsStack} from './Stack';

export function ProfileSettingsModal() {
  // const {current} = useCardAnimation();
  const navigation = useNavigation();
  const appSettings = useAppSettings();

  return (
    <View
      // eslint-disable-next-line react-native/no-inline-styles
      style={{
        flex: 1,
        // alignItems: 'center',
        // justifyContent: 'center',
      }}>
      <Pressable
        style={[
          StyleSheet.absoluteFill,
          // eslint-disable-next-line react-native/no-inline-styles
          {backgroundColor: 'rgba(0, 0, 0, 0.8)'},
        ]}
        onPress={navigation.goBack}
      />
      <Animated.View
        // eslint-disable-next-line react-native/no-inline-styles
        style={{
          position: 'absolute',
          borderColor: appSettings.currentTheme.colors.border,
          borderWidth: 1,
          top: 0,
          right: 0,
          width: '90%',
          height: '100%',
          maxWidth: 400,
          borderRadius: 3,
          backgroundColor: appSettings.currentTheme.colors.card,
          // transform: [
          //   {
          //     scale: current.progress.interpolate({
          //       inputRange: [0, 1],
          //       outputRange: [0.9, 1],
          //       extrapolate: 'clamp',
          //     }),
          //   },
          // ],
        }}>
        {/* <Button
          color={appSettings.currentTheme.colors.primary}
          // eslint-disable-next-line react-native/no-inline-styles
          style={{alignSelf: 'flex-end'}}
          onPress={navigation.goBack}>
          X
        </Button> */}
        <ProfileSettingsStack />
      </Animated.View>
    </View>
  );
}
