import {useContext} from 'react';
import {Image, Linking, ScrollView, StyleSheet, View} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import {Button, Text} from 'react-native-paper';
import XDate from 'xdate';
import {LineChart} from 'react-native-chart-kit';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory-native';
import {FirebaseFirestoreTypes} from '@react-native-firebase/firestore';

import {ScoringInfoContext} from './Stack';
import {useAppSettings} from '../components/AppSettings';
import {useDemographics, useScores, useTIPI} from '../hooks/useUserInfo';
import {DeviceInfo} from '../models/IdentityModels';

const NOW_MINUS_SIX_WEEKS_MS = Date.now() - 1000 * 60 * 60 * 24 * 7 * 6;
const NOW = new Date();

function firstDayOfWeek(dateObject: Date, firstDayOfWeekIndex: number) {
  const dayOfWeek = dateObject.getDay();
  const returnDate = new Date(dateObject);
  const diff = dayOfWeek >= firstDayOfWeekIndex ? dayOfWeek - firstDayOfWeekIndex : 6 - dayOfWeek;
  returnDate.setDate(dateObject.getDate() - diff);
  returnDate.setHours(0, 0, 0, 0);
  return returnDate;
}
const CURRENT_MONDAY = firstDayOfWeek(NOW, 1);

const ONE_YEAR_OF_MONDAYS = Array.from(Array(52).keys())
  .map(weekNum => CURRENT_MONDAY.getTime() - 1000 * 60 * 60 * 24 * 7 * weekNum)
  .reverse();

const openURL = async (url: string): Promise<void> => {
  try {
    const supported = await Linking.canOpenURL(url);
    if (!supported) {
      console.log(`ScoreDisplay::callNumber - Can't handle url: ${url}`);
      return;
    }
    const result = await Linking.openURL(url);
    console.log('ScoreDisplay::callNumber - got result from Linking.openUrl:', result);
  } catch (err) {
    console.error('ScoreDisplay::callNumber - Linking.openUrl return error', err);
  }
};

const scoreHistoryArrayTransform = (seriesData: {
  [key: number]: string;
}): {x: Date; y: number}[] => {
  return Object.keys(seriesData)
    .sort()
    .filter(timestamp => {
      // Drop data earlier than one year ago
      return new Date(Number(timestamp)).getTime() >= ONE_YEAR_OF_MONDAYS[0];
    })
    .map(timestamp => {
      const timestampNumber = Number(timestamp);
      return {x: new Date(timestampNumber), y: Number(seriesData[timestampNumber])};
    });
};

const ScoreDisplay = () => {
  const scoringInfo = useContext(ScoringInfoContext);
  const user = scoringInfo?.scoringUserInfo?.scoringOnUser;
  const appSettings = useAppSettings();
  const linkTo = useLinkTo();
  const ksocials = useScores(user?.kullkiId);
  const ksocial = ksocials !== undefined && ksocials.length > 0 ? ksocials[0] : undefined;

  if (user === undefined) {
    return <View />;
  }

  if (user.dateOfBirth) {
    user.dateOfBirthFormatted = new XDate(user.dateOfBirth).toString(
      appSettings.t('ProfileBirthdateFormat'),
    );
  }
  if (user.registerTime) {
    user.registerTimeFormatted = new XDate(user.registerTime).toString(
      appSettings.t('ProfileBirthdateFormat'),
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  user.tipi = useTIPI(user?.kullkiId);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  user.demographics = useDemographics(user?.kullkiId);

  // console.log('Score: ' + JSON.stringify(ksocial));
  // console.log('User: ' + JSON.stringify(user));

  const calculate = (): void => {
    linkTo('/score/generate');
  };

  const themeStyles = StyleSheet.create({
    textBanner: {
      flex: 1,
      maxHeight: 40,
      minHeight: 40,
      margin: 10,
      flexDirection: 'row',
      alignContent: 'center',
      color: '#ffffff',
      backgroundColor: appSettings.currentTheme.colors.primary,
    },
    topHeaderTextFlex: {
      flex: 1,
      fontSize: 32,
      maxHeight: 40,
      color: appSettings.currentTheme.colors.background,
      textAlign: 'center',
    },
    textStyleScore: {
      fontSize: 45,
      alignSelf: 'center',
      alignContent: 'center',
      padding: 15,
      color: appSettings.currentTheme.colors.primary,
      fontWeight: '800',
    },
    insetDivider: {
      height: 4,
      backgroundColor: appSettings.currentTheme.colors.primary,
      marginHorizontal: 20,
      margin: 10,
    },
  });

  // let appsUploadTimeFormatted = appSettings.t('ShowScoreDataStale');
  let contactsUploadTimeFormatted = appSettings.t('ShowScoreDataStale');
  let eventsUploadTimeFormatted = appSettings.t('ShowScoreDataStale');
  // let permissionsUploadTimeFormatted = appSettings.t('ShowScoreDataStale');
  let deviceInfoUploadTimeFormatted = appSettings.t('ShowScoreDataStale');

  if (ksocial === undefined) {
    return (
      <ScrollView style={styles.pageContainer}>
        <View>
          <Text>
            {appSettings.t('ShowScoreNoScore')}
            {` ${user?.firstName} ${user?.secondName}`}
          </Text>
          <View style={styles.centeredPadded}>
            <Button onPress={() => calculate()}>
              <Text>{appSettings.t('Calculate')}</Text>
            </Button>
          </View>
        </View>
      </ScrollView>
    );
  } else {
    if (ksocial.scoreBasis?.contactsLastUpload) {
      const contactsLastUpload = (
        ksocial.scoreBasis.contactsLastUpload as FirebaseFirestoreTypes.Timestamp
      ).toDate();
      if (contactsLastUpload >= new Date(NOW_MINUS_SIX_WEEKS_MS)) {
        contactsUploadTimeFormatted =
          appSettings.t('ShowScoreDataUploadDate') +
          ' ' +
          new XDate(
            (ksocial.scoreBasis?.contactsLastUpload as FirebaseFirestoreTypes.Timestamp).toDate(),
          ).toString(appSettings.t('LatestDeviceEventDateFormat'));
      }
    }

    if (ksocial.scoreBasis?.eventsLastUpload) {
      const contactsLastUpload = (
        ksocial.scoreBasis.eventsLastUpload as FirebaseFirestoreTypes.Timestamp
      ).toDate();
      if (contactsLastUpload >= new Date(NOW_MINUS_SIX_WEEKS_MS)) {
        eventsUploadTimeFormatted =
          appSettings.t('ShowScoreDataUploadDate') +
          ' ' +
          new XDate(
            (ksocial.scoreBasis?.eventsLastUpload as FirebaseFirestoreTypes.Timestamp).toDate(),
          ).toString(appSettings.t('LatestDeviceEventDateFormat'));
      }
    }

    if (ksocial.scoreBasis?.deviceInfoLastUpload) {
      const deviceInfoLastUpload = (
        ksocial.scoreBasis.deviceInfoLastUpload as FirebaseFirestoreTypes.Timestamp
      ).toDate();
      if (deviceInfoLastUpload >= new Date(NOW_MINUS_SIX_WEEKS_MS)) {
        deviceInfoUploadTimeFormatted =
          appSettings.t('ShowScoreDataUploadDate') +
          ' ' +
          new XDate(
            (ksocial.scoreBasis?.deviceInfoLastUpload as FirebaseFirestoreTypes.Timestamp).toDate(),
          ).toString(appSettings.t('LatestDeviceEventDateFormat'));
      }
    }

    // console.log('events upload? ' + ksocial.scoreBasis?.eventsLastUpload);
    // console.log('permissions last upload? ' + ksocial.scoreBasis?.permissionsLastUpload);
    // console.log('device info upload? ' + ksocial.scoreBasis?.deviceInfoLastUpload);
    // console.log('demographics timestamp? ' + ksocial.)
  }

  // const normalizedScoreHistory = scoreHistoryTimeNormalizer(ksocial.scoreHistory ?? []);

  return (
    <ScrollView style={styles.pageContainer}>
      <View>
        <View
          style={[
            styles.simpleHorizontalSplit,
            // {flex: 0, paddingBottom: 0, margin: 0},
          ]}>
          <Image
            fadeDuration={0}
            style={styles.logoStyle}
            source={require('../../static/assets/images/logos/logoKompaiScore.png')}
          />
          <Text style={themeStyles.textStyleScore}>
            {ksocial?.ksocialAdjusted || ksocial?.ksocial}
          </Text>
        </View>
        <View style={themeStyles.textBanner}>
          <Text style={themeStyles.topHeaderTextFlex}>
            {appSettings.t('ShowScoreUserDetailsTitle')}
          </Text>
        </View>
        <View style={[styles.simpleHorizontalSplit]}>
          <Text>{appSettings.t('ProfileCedulaLabel')}</Text>
          <Text style={styles.textAlignRight}>{user?.citizenNumber}</Text>
        </View>
        <View style={[styles.simpleHorizontalSplit]}>
          <Text>{appSettings.t('ShowScoreFirstName')}</Text>
          <Text style={styles.textAlignRight}> {user?.firstName}</Text>
        </View>
        <View style={[styles.simpleHorizontalSplit]}>
          <Text>{appSettings.t('ShowScoreLastName')}</Text>
          <Text style={styles.textAlignRight}> {user?.secondName}</Text>
        </View>
        <View style={[styles.simpleHorizontalSplit]}>
          <Text>{appSettings.t('Email')}</Text>
          <Text
            style={styles.textAlignRightUnderlined}
            onPress={() => openURL(`mailto:${user?.email}`)}>
            {user?.email}
          </Text>
        </View>
        <View style={[styles.simpleHorizontalSplit]}>
          <Text>{appSettings.t('ShowScorePhoneNumber')}</Text>
          <Text
            style={styles.textAlignRightUnderlined}
            onPress={() => openURL(`tel:${user?.phoneNumber}`)}>
            {user?.phoneNumber ? user?.phoneNumber : 'N/A'}
          </Text>
        </View>
        <View style={[styles.simpleHorizontalSplit]}>
          <Text>{appSettings.t('ShowScoreDOB')}</Text>
          <Text style={styles.textAlignRight}>{user?.dateOfBirthFormatted}</Text>
        </View>
        <View style={[styles.simpleHorizontalSplit]}>
          <Text>{appSettings.t('MemberSinceLabel')}</Text>
          <Text style={styles.textAlignRight}>{user?.registerTimeFormatted}</Text>
        </View>
        {ksocial?.scoreBasis && (
          <View>
            <View style={themeStyles.textBanner}>
              <Text style={themeStyles.topHeaderTextFlex}>
                {appSettings.t('ShowScoreScoreDetailsTitle')}
              </Text>
            </View>
            <View style={[styles.simpleHorizontalSplit]}>
              <Text>{appSettings.t('ShowScoreDate')}</Text>
              <Text style={styles.textAlignRight}>
                {
                  (ksocial.timestampFormatted = new XDate(ksocial.timestamp).toString(
                    appSettings.t('LatestDeviceEventDateFormat'),
                  ))
                }
              </Text>
            </View>
            <View style={[styles.simpleHorizontalSplit]}>
              <Text>{appSettings.t('ShowScoreContactsDetail')}</Text>
              <Text style={styles.textAlignRight}>
                {ksocial.scoreBasis.contactsAuthorized
                  ? appSettings.t('ShowScoreDetailYes') + ', ' + contactsUploadTimeFormatted
                  : appSettings.t('ShowScoreDetailNo')}
              </Text>
            </View>
            <View style={[styles.simpleHorizontalSplit]}>
              <Text>{appSettings.t('ShowScoreCalendarDetail')}</Text>
              <Text style={styles.textAlignRight}>
                {ksocial.scoreBasis.eventsAuthorized
                  ? appSettings.t('ShowScoreDetailYes') + ', ' + eventsUploadTimeFormatted
                  : appSettings.t('ShowScoreDetailNo')}
              </Text>
            </View>
            <View style={[styles.simpleHorizontalSplit]}>
              <Text>{appSettings.t('ShowScorePersonalityDetail')}</Text>
              <Text style={styles.textAlignRight}>
                {ksocial.scoreBasis.tipiDone
                  ? appSettings.t('ShowScoreDetailYes')
                  : appSettings.t('ShowScoreDetailNo')}
              </Text>
            </View>
            <View style={[styles.simpleHorizontalSplit]}>
              <Text>{appSettings.t('ShowScoreDemographicsDetail')}</Text>
              <Text style={styles.textAlignRight}>
                {(ksocial.scoreBasis.demographicsSnapshotsRecorded ?? 0) > 0
                  ? appSettings.t('ShowScoreDetailYes')
                  : appSettings.t('ShowScoreDetailNo')}
              </Text>
            </View>
            <View style={[styles.simpleHorizontalSplit]}>
              <Text>{appSettings.t('ShowScoreEventStreamDetail')}</Text>
              <Text style={styles.textAlignRight}>{ksocial.scoreBasis.eventStreamCount}</Text>
            </View>
            <View style={[styles.simpleHorizontalSplit]}>
              <Text>{appSettings.t('ShowScoreEventStreamHistogram')}</Text>
              <LineChart
                data={{
                  labels: [],
                  datasets: [
                    {
                      data: ksocial.scoreBasis.eventStreamHistogram ?? [],
                    },
                  ],
                }}
                withDots={false}
                fromZero={true}
                withVerticalLabels={false}
                withVerticalLines={false}
                withHorizontalLabels={false}
                withHorizontalLines={false}
                withInnerLines={false}
                withOuterLines={false}
                width={250}
                height={50}
                chartConfig={{
                  backgroundGradientFrom: appSettings.currentTheme.colors.background,
                  backgroundGradientFromOpacity: 0,
                  backgroundGradientTo: appSettings.currentTheme.colors.background,
                  backgroundGradientToOpacity: 0.75,
                  color: () => appSettings.currentTheme.colors.primary, // (opacity = 1) => `rgba(0,0,0, ${opacity})`,
                  strokeWidth: 2, // optional, default 3
                  barPercentage: 0.5,
                  useShadowColorFromDataset: false, // optional
                }}
              />
            </View>
            <View style={[styles.simpleHorizontalSplit]}>
              <Text>{appSettings.t('ShowScoreGPSDetail')}</Text>
              <Text style={styles.textAlignRight}>{ksocial.scoreBasis.gpsLocationCount}</Text>
            </View>
            <View style={[styles.simpleHorizontalSplit]}>
              <Text>{appSettings.t('ShowScoreGPSHistogram')}</Text>
              <LineChart
                data={{
                  labels: [],
                  datasets: [
                    {
                      data: ksocial.scoreBasis.gpsLocationHistogram ?? [],
                    },
                  ],
                }}
                withDots={false}
                fromZero={true}
                withVerticalLabels={false}
                withVerticalLines={false}
                withHorizontalLabels={false}
                withHorizontalLines={false}
                withInnerLines={false}
                withOuterLines={false}
                width={250}
                height={50}
                chartConfig={{
                  backgroundGradientFrom: appSettings.currentTheme.colors.background,
                  backgroundGradientFromOpacity: 0,
                  backgroundGradientTo: appSettings.currentTheme.colors.background,
                  backgroundGradientToOpacity: 0.75,
                  color: () => appSettings.currentTheme.colors.primary, // (opacity = 1) => `rgba(0,0,0, ${opacity})`,
                  strokeWidth: 2, // optional, default 3
                  barPercentage: 0.5,
                  useShadowColorFromDataset: false, // optional
                }}
              />
            </View>
            <View style={[styles.simpleHorizontalSplit]}>
              <Text>{appSettings.t('ShowScoreDevicesDetail')}</Text>
              <Text style={styles.textAlignRight}>
                {ksocial.scoreBasis.deviceInfos?.length ?? 0} ({deviceInfoUploadTimeFormatted})
              </Text>
            </View>

            <View style={themeStyles.textBanner}>
              <Text style={themeStyles.topHeaderTextFlex}>
                {appSettings.t('ShowScoreScoreHistory')}
              </Text>
            </View>
            {!ksocial.scoreHistory && (
              <View>
                <Text>{appSettings.t('LoadingDataMessage')}</Text>
              </View>
            )}

            {ksocial.scoreHistory && (
              <>
                <View style={[styles.simpleHorizontalSplit]}>
                  <VictoryChart height={250} containerComponent={<VictoryVoronoiContainer />}>
                    <VictoryArea
                      interpolation={'natural'}
                      style={{
                        labels: {fill: appSettings.currentTheme.colors.primary},
                        data: {
                          fill: appSettings.currentTheme.colors.primary,
                          fillOpacity: 0.15,
                          strokeWidth: 2,
                        },
                      }}
                      data={scoreHistoryArrayTransform(ksocial.scoreHistory ?? [])}
                      domain={{y: [500, 1000]}}
                    />
                    <VictoryScatter
                      data={scoreHistoryArrayTransform(ksocial.scoreHistory ?? [])}
                      domain={{y: [500, 1000]}}
                      labels={({datum}) => {
                        return `${datum.y}\n  ${new XDate(new Date(datum.x)).toString(
                          appSettings.t('LatestDeviceEventDateFormat'),
                        )}  `;
                      }}
                      labelComponent={
                        <VictoryTooltip
                          // eslint-disable-next-line react-native/no-inline-styles
                          flyoutStyle={{
                            fill: appSettings.currentTheme.colors.background,
                            stroke: appSettings.currentTheme.colors.text,
                            fontSize: 8,
                            margin: 10,
                          }}
                          dy={-7}
                          constrainToVisibleArea
                        />
                      }
                      size={3}
                      style={{
                        data: {fill: appSettings.currentTheme.colors.text},
                        labels: {fontSize: 8, fill: appSettings.currentTheme.colors.text},
                      }}
                    />
                    <VictoryAxis
                      style={{
                        axis: {
                          stroke: appSettings.currentTheme.colors.text,
                          strokeWidth: 3,
                        },
                        grid: {
                          stroke: appSettings.currentTheme.colors.text,
                          strokeWidth: 1,
                        },
                        tickLabels: {
                          fill: appSettings.currentTheme.colors.text,
                          padding: 20,
                          fontSize: 10,
                        },
                      }}
                      dependentAxis
                    />
                    <VictoryAxis
                      // tickCount={12}
                      style={{
                        axis: {
                          stroke: appSettings.currentTheme.colors.text,
                          strokeWidth: 3,
                        },
                        grid: {
                          stroke: appSettings.currentTheme.colors.text,
                          strokeWidth: 1,
                        },
                        tickLabels: {
                          fill: appSettings.currentTheme.colors.text,
                          padding: 20,
                          fontSize: 10,
                          angle: -50,
                        },
                      }}
                      tickFormat={tick =>
                        new XDate(tick).toString(appSettings.t('ProfileBirthdateFormat'))
                      }
                    />
                    <VictoryAxis
                      tickCount={12}
                      orientation={'top'}
                      style={{
                        axis: {
                          stroke: appSettings.currentTheme.colors.text,
                          strokeWidth: 3,
                        },
                        tickLabels: {
                          fill: 'transparent',
                          padding: 0,
                          fontSize: 10,
                        },
                      }}
                      tickFormat={_ => undefined}
                    />
                  </VictoryChart>
                </View>
              </>
            )}

            <View style={themeStyles.textBanner}>
              <Text style={themeStyles.topHeaderTextFlex}>
                {appSettings.t('profile_btnDemographics_title')}
              </Text>
            </View>
            {!user.demographics && (
              <View>
                <Text>{appSettings.t('LoadingDataMessage')}</Text>
              </View>
            )}

            {/* // FIXME get the answer localization done */}
            {user.demographics && user.demographics.length > 0 && (
              <View>
                <View style={[styles.simpleHorizontalSplit]}>
                  <Text>{appSettings.t('ProfileUpdatedTitle')}</Text>
                  <Text style={styles.textAlignRight}>
                    {new XDate(user.demographics[0].timestamp).toString(
                      appSettings.t('ProfileBirthdateFormat'),
                    )}
                  </Text>
                </View>
                <View style={[styles.simpleHorizontalSplit]}>
                  <Text>{appSettings.t('DemographicsQuestion1')}</Text>
                  <Text style={styles.textAlignRight}>
                    {user.demographics[0].auto ? appSettings.t('True') : appSettings.t('False')}
                  </Text>
                </View>
                <View style={[styles.simpleHorizontalSplit]}>
                  <Text>{appSettings.t('DemographicsQuestion2')}</Text>
                  <Text style={styles.textAlignRight}>{user.demographics[0].gender}</Text>
                </View>
                <View style={[styles.simpleHorizontalSplit]}>
                  <Text>{appSettings.t('DemographicsQuestion3')}</Text>
                  <Text style={styles.textAlignRight}>{user.demographics[0].civilStatus}</Text>
                </View>
                <View style={[styles.simpleHorizontalSplit]}>
                  <Text>{appSettings.t('DemographicsQuestion4')}</Text>
                  <Text style={styles.textAlignRight}>{user.demographics[0].children}</Text>
                </View>
                <View style={[styles.simpleHorizontalSplit]}>
                  <Text>{appSettings.t('DemographicsQuestion5')}</Text>
                  <Text style={styles.textAlignRight}>
                    {'>' + user.demographics[0].salaryLowerBound}
                  </Text>
                </View>
                <View style={[styles.simpleHorizontalSplit]}>
                  <Text>{appSettings.t('DemographicsQuestion6')}</Text>
                  <Text style={styles.textAlignRight}>{user.demographics[0].housing}</Text>
                </View>
                <View style={[styles.simpleHorizontalSplit]}>
                  <Text>{appSettings.t('DemographicsQuestion7')}</Text>
                  <Text style={styles.textAlignRight}>{user.demographics[0].education}</Text>
                </View>
                <View style={[styles.simpleHorizontalSplit]}>
                  <Text>{appSettings.t('DemographicsQuestion8')}</Text>
                  <Text style={styles.textAlignRight}>
                    {user.demographics[0].creditCard
                      ? appSettings.t('True')
                      : appSettings.t('False')}
                  </Text>
                </View>
              </View>
            )}

            <View style={themeStyles.textBanner}>
              <Text style={themeStyles.topHeaderTextFlex}>
                {appSettings.t('profile_btnTIPI_title')}
              </Text>
            </View>
            {!user.tipi && (
              <View>
                <Text>{appSettings.t('LoadingDataMessage')}</Text>
              </View>
            )}

            {/* // FIXME get the answer localization done */}
            {user.tipi && (
              <>
                <View style={[styles.simpleHorizontalSplit]}>
                  <Text>{appSettings.t('QuizCompleteTitle')}</Text>
                  <Text style={styles.textAlignRight}>
                    {user.tipi.completionTime &&
                      new XDate(user.tipi.completionTime).toString(
                        appSettings.t('ProfileBirthdateFormat'),
                      )}
                  </Text>
                </View>
                <View style={[styles.simpleHorizontalSplit]}>
                  <Text style={styles.textAlignRight}>
                    0 = {appSettings.t('QuizAnswer1')} / 7 = {appSettings.t('QuizAnswer7')}
                  </Text>
                </View>
                {user.tipi.answers.map(answer => (
                  <View style={[styles.simpleHorizontalSplit]} key={answer.key}>
                    <Text>{appSettings.t(`QuizQuestion${answer.key}`)}</Text>
                    <Text style={styles.textAlignRight}>{answer.answer}</Text>
                  </View>
                ))}
              </>
            )}

            {ksocial?.scoreBasis?.deviceInfos && (
              <View>
                <View style={themeStyles.textBanner}>
                  <Text style={themeStyles.topHeaderTextFlex}>
                    {appSettings.t('ShowScoreDevicesDetailTitle')}
                  </Text>
                </View>

                {ksocial?.scoreBasis?.deviceInfos?.map((deviceInfo: DeviceInfo, i) => (
                  <View key={i}>
                    <View style={themeStyles.insetDivider} />
                    <View style={[styles.simpleHorizontalSplit]}>
                      <Text>{appSettings.t('ShowScorePhoneType')}</Text>
                      <Text style={styles.textAlignRight}>
                        {deviceInfo.brand} {deviceInfo.product}
                        {deviceInfo.isEmulator ? ' (emulator)' : ''}
                      </Text>
                    </View>
                    <View style={[styles.simpleHorizontalSplit]}>
                      <Text>{appSettings.t('ShowScorePhoneOSVersion')}</Text>
                      <Text style={styles.textAlignRight}>{deviceInfo.systemVersion}</Text>
                    </View>
                    <View style={[styles.simpleHorizontalSplit]}>
                      <Text>{appSettings.t('ShowScorePhoneAppVersion')}</Text>
                      <Text style={styles.textAlignRight}>{deviceInfo.readableVersion}</Text>
                    </View>
                    <View style={[styles.simpleHorizontalSplit]}>
                      <Text>{appSettings.t('ShowScoreCarrierInfo')}</Text>
                      <Text style={styles.textAlignRight}>{deviceInfo.carrier}</Text>
                    </View>
                    <View style={[styles.simpleHorizontalSplit]}>
                      <Text>{appSettings.t('ShowScoreEventStreamDetail')}</Text>
                      <Text style={styles.textAlignRight}>{deviceInfo.eventStreamCount}</Text>
                    </View>
                    <View style={[styles.simpleHorizontalSplit]}>
                      <Text>{appSettings.t('ShowScoreEventStreamHistogram')}</Text>
                      <LineChart
                        data={{
                          labels: [],
                          datasets: [
                            {
                              data: deviceInfo.eventStreamHistogram ?? [],
                            },
                          ],
                        }}
                        withDots={false}
                        fromZero={true}
                        withVerticalLabels={false}
                        withVerticalLines={false}
                        withHorizontalLabels={false}
                        withHorizontalLines={false}
                        withInnerLines={false}
                        withOuterLines={false}
                        width={250}
                        height={50}
                        chartConfig={{
                          backgroundGradientFrom: appSettings.currentTheme.colors.background,
                          backgroundGradientFromOpacity: 0,
                          backgroundGradientTo: appSettings.currentTheme.colors.background,
                          backgroundGradientToOpacity: 0.75,
                          color: () => appSettings.currentTheme.colors.primary, // (opacity = 1) => `rgba(0,0,0, ${opacity})`,
                          strokeWidth: 2, // optional, default 3
                          barPercentage: 0.5,
                          useShadowColorFromDataset: false, // optional
                        }}
                      />
                    </View>
                    <View style={[styles.simpleHorizontalSplit]}>
                      <Text>{appSettings.t('ShowScoreGPSDetail')}</Text>
                      <Text style={styles.textAlignRight}>{deviceInfo.gpsLocationCount}</Text>
                    </View>
                    <View style={[styles.simpleHorizontalSplit]}>
                      <Text>{appSettings.t('ShowScoreGPSHistogram')}</Text>
                      <LineChart
                        data={{
                          labels: [],
                          datasets: [
                            {
                              data: deviceInfo.gpsLocationHistogram ?? [],
                            },
                          ],
                        }}
                        withDots={false}
                        fromZero={true}
                        withVerticalLabels={false}
                        withVerticalLines={false}
                        withHorizontalLabels={false}
                        withHorizontalLines={false}
                        withInnerLines={false}
                        withOuterLines={false}
                        width={250}
                        height={50}
                        chartConfig={{
                          backgroundGradientFrom: appSettings.currentTheme.colors.background,
                          backgroundGradientFromOpacity: 0,
                          backgroundGradientTo: appSettings.currentTheme.colors.background,
                          backgroundGradientToOpacity: 0.75,
                          color: () => appSettings.currentTheme.colors.primary, // (opacity = 1) => `rgba(0,0,0, ${opacity})`,
                          strokeWidth: 2, // optional, default 3
                          barPercentage: 0.5,
                          useShadowColorFromDataset: false, // optional
                        }}
                      />
                    </View>
                  </View>
                ))}
              </View>
            )}
          </View>
        )}
        {/* <View style={styles.lowHorizontalSplit}>
                <RX.Button onPress={() => linkTo('ScoreInfo')}>
                  <Text style={styles.darkBlueText}>
                    {appSettings.t('ShowScoreMoreInfo')}
                  </Text>
                </RX.Button>
              </View> */}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  logoStyle: {
    height: 90,
    width: 180,
    resizeMode: 'contain',

    // paddingTop: 50,
    // padding: 18,
    alignItems: 'center',
    justifyContent: 'center',
  },
  simpleHorizontalSplit: {
    // flex: 1,
    paddingHorizontal: 30,
    // paddingTop: 40,
    // paddingBottom: 15,
    flexDirection: 'row',
    // alignSelf: 'center',
    // alignContent: 'center',
    // alignItems: 'center',
    justifyContent: 'space-between',
    // alignSelf: 'auto',
    // alignItems: 'flex-end',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  textAlignRightUnderlined: {
    textAlign: 'right',
    textDecorationLine: 'underline',
  },

  appBarButtonPadding: {
    padding: 10,
  },
  appSettingsButtonStyle: {
    // padding: 10,
    height: 30,
    width: '80%',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  content: {
    paddingHorizontal: 20,
  },
  centeredPadded: {
    alignSelf: 'center',
    alignContent: 'center',
    padding: 15,
  },
  fullyCenteredPageContainer: {
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageContainer: {
    flex: 1,
    padding: 20,
  },
  providers: {
    elevation: 4,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginVertical: 30,
    padding: 20,
  },
  divider: {
    height: 4,
  },
  profile: {
    marginTop: -50,
    paddingVertical: 10,
  },
  avatar: {
    borderWidth: 5,
    elevation: 4,
  },
});

export default ScoreDisplay;
