import {useContext} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {Button, Divider, Headline, Text} from 'react-native-paper';
import {useLinkTo} from '@react-navigation/native';

import {useAppSettings} from '../components/AppSettings';
import {displayNameFromUser} from '../hooks/useUserInfo';
import {ScoringInfoContext} from './Stack';
import {format} from '../translations';

export const BillingDashboard = () => {
  const linkTo = useLinkTo();
  const scoringInfo = useContext(ScoringInfoContext);
  const appSettings = useAppSettings();

  const scoringAsUser = scoringInfo?.scoringUserInfo?.scoringAsUser;
  let scoringAsUserDisplayName = displayNameFromUser(scoringAsUser);

  return (
    <ScrollView contentContainerStyle={styles.fullyCenteredPageContainer}>
      <Headline>{appSettings.t('BillingTitle')}</Headline>
      {scoringAsUser?.kullkiId !== scoringInfo?.scoringUserInfo?.authUser?.kullkiId && (
        <>
          <Divider style={{backgroundColor: appSettings.currentTheme.colors.primary}} />
          <View style={styles.centeredPadded} />
          <Text>
            {format(appSettings.t('ScoringDelegateGreeting'), [scoringAsUserDisplayName])}
          </Text>
        </>
      )}
      {/* <View style={styles.centeredPadded} />
      <Button mode="contained" onPress={() => linkTo('/scoring/batches/list')}>
        {appSettings.t('ScoreBatchesListTitle')}
      </Button> */}
      <View style={styles.centeredPadded} />
      <Button mode="contained" onPress={() => linkTo('/billing/report')}>
        {appSettings.t('BillingReport')}
      </Button>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  centeredPadded: {
    alignSelf: 'center',
    alignContent: 'center',
    padding: 15,
  },
  fullyCenteredPageContainer: {
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
