import {Fragment, useContext} from 'react';
import {StyleSheet, View} from 'react-native';
import {Button, Divider, Headline, Subheading} from 'react-native-paper';

import {ScoringInfoContext} from './Stack';
import {displayNameFromUser, useDelegatingUsers} from '../hooks/useUserInfo';
import {useAppSettings} from '../components/AppSettings';

export const AccountSwitchScreen = () => {
  const appSettings = useAppSettings();
  const scoringInfo = useContext(ScoringInfoContext);
  const delegatingUsers = useDelegatingUsers(scoringInfo?.scoringUserInfo?.authUser?.kullkiId);
  const scoringAsUser = scoringInfo?.scoringUserInfo?.scoringAsUser;
  let scoringAsUserDisplayName = displayNameFromUser(scoringAsUser);
  let authUserDisplayName = displayNameFromUser(scoringInfo?.scoringUserInfo?.authUser);
  // console.log('delegatingUser count ' + delegatingUsers?.length);

  return (
    <View style={styles.content}>
      <Divider style={{backgroundColor: appSettings.currentTheme.colors.primary}} />
      <Headline>{appSettings.t('ScoringCurrentProfileTitle')}: </Headline>
      <Subheading style={styles.centeredPadded}>{scoringAsUserDisplayName}</Subheading>
      <Divider style={{backgroundColor: appSettings.currentTheme.colors.primary}} />
      <Divider style={{backgroundColor: appSettings.currentTheme.colors.primary}} />
      <Headline>{appSettings.t('ScoringSelectProfile')}:</Headline>
      <View style={styles.divider} />
      <Button
        mode="contained"
        style={[styles.appSettingsButtonStyle]}
        key={scoringInfo?.scoringUserInfo?.authUser?.kullkiId}
        onPress={() =>
          scoringInfo?.setScoringUserInfo({
            ...scoringInfo.scoringUserInfo,
            scoringAsUser: scoringInfo?.scoringUserInfo?.authUser,
          })
        }>
        {authUserDisplayName}
      </Button>
      {delegatingUsers?.map(delegatingUser => {
        let delegatingUserDisplayName = displayNameFromUser(delegatingUser);
        return (
          <Fragment key={delegatingUser.kullkiId}>
            <View style={styles.divider} />
            <Button
              mode="contained"
              style={[styles.appSettingsButtonStyle]}
              key={delegatingUser.kullkiId}
              onPress={() =>
                scoringInfo?.setScoringUserInfo({
                  ...scoringInfo.scoringUserInfo,
                  scoringAsUser: delegatingUser,
                })
              }>
              {delegatingUserDisplayName}
            </Button>
          </Fragment>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  appSettingsButtonStyle: {
    // padding: 10,
    height: 30,
    width: '80%',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  centeredPadded: {
    alignSelf: 'center',
    alignContent: 'center',
    padding: 15,
  },
  content: {
    paddingHorizontal: 20,
  },
  divider: {
    height: 10,
  },
});
