import {Fragment, useContext, useEffect, useState} from 'react';
import {ActivityIndicator, Headline} from 'react-native-paper';

import {useQuery, useQueryClient} from 'react-query';

import analytics from '@react-native-firebase/analytics';
import auth, {FirebaseAuthTypes} from '@react-native-firebase/auth';
import firestore from '@react-native-firebase/firestore';
import crashlytics from '@react-native-firebase/crashlytics';
import inAppMessaging from '@react-native-firebase/in-app-messaging';

import {useAppSettings} from './AppSettings';
import {ScoringInfoContext} from '../signed-in/Stack';
import {StyleSheet, View} from 'react-native';
import {User} from '../models/IdentityModels';
import {cleanUserBeforeSaving, fetchAuthUserInfo} from '../hooks/useUserInfo';
import {v4} from 'uuid';

export const FirestoreUserGate = (props: {children: JSX.Element}) => {
  const authUser = auth().currentUser;
  // console.log('FirebaseUserGate - authUser: ' + authUser?.uid);

  const [gating, setGating] = useState(true);
  const [timedOut, setTimedOut] = useState(false);
  const [creatingUser, setCreatingUser] = useState(false);
  const [waitingToShowLoading, setWaitingToShowLoading] = useState(true);
  const queryClient = useQueryClient();
  const userQuery = useQuery(['authUserInfo', auth().currentUser], fetchAuthUserInfo);
  const appSettings = useAppSettings();
  const scoringInfo = useContext(ScoringInfoContext);
  useEffect(() => {
    if (!authUser) {
      return;
    }
    // console.log(
    //   `FirebaseUserGate ${gating ? 'gating' : 'passing'} ${
    //     userQuery.isFetching ? 'fetching' : 'settled'
    //   } user ${userQuery.data?.user.kullkiId}`,
    // );

    // If we are gating, is our query settled? we may be able to act
    if (gating && !userQuery.isFetching) {
      if (userQuery.isError) {
        // TODO handle the error case for the query
        console.log('FirebaseUserGate, query error? ', userQuery.error);
        return;
      }
      if (userQuery.isSuccess && userQuery.data !== undefined) {
        const user = userQuery.data.user;
        // console.log(`FirebaseUserGate pass: ${user.kullkiId}`);

        // breadcrumbs out to the cloud...
        analytics().setUserProperties({
          kullkiId: userQuery.data.user.kullkiId,
        });

        crashlytics().setUserId(user.kullkiId);
        crashlytics().log('FirestoreUserGate::firestore user load complete');
        inAppMessaging().setMessagesDisplaySuppressed(false);

        scoringInfo?.setScoringUserInfo({
          authUser: user,
          scoringAsUser: user,
          scoringOnUser: user,
        });

        // Let the user through
        setGating(false);
        setWaitingToShowLoading(true);
        return;
      }

      if (userQuery.isSuccess && userQuery.data === undefined) {
        // console.log('FirebaseUserGate no user');
        if (!creatingUser) {
          // console.log('FirebaseUserGate - no one else creating user');
          setCreatingUser(true);
          let newUser = copyAuthUsertoFirestore(authUser);
          newUser = cleanUserBeforeSaving(newUser);

          firestore()
            .collection('users')
            .doc(newUser.kullkiId)
            .set(newUser)
            .then(() => {
              queryClient.setQueryData(['authUserInfo', newUser.fbAuthId], newUser);
              userQuery.refetch();
              // setCreatingUser(false);

              // console.log(
              //   'FirestoreUserGate - Successfully added user:',
              //   JSON.stringify(newUser, null, 2),
              // );
              analytics().logEvent('successCreatedUser');
            })
            .catch(e => {
              console.log('FirebaseUserGate user create fail', e);
            });
        }
      }
    }
  }, [gating, userQuery, creatingUser, authUser, queryClient, scoringInfo]);

  if (gating && !timedOut) {
    setTimeout(() => {
      if (gating) {
        setWaitingToShowLoading(false);
      }
    }, 2000);
    setTimeout(() => {
      if (gating) {
        setTimedOut(true);
      }
    }, 30000);

    return (
      <View
        style={[
          styles.loadingContainer,
          {backgroundColor: appSettings.currentTheme.colors.background},
        ]}>
        {!waitingToShowLoading && (
          <Fragment>
            <Headline style={[styles.padded, {color: appSettings.currentTheme.colors.text}]}>
              {appSettings.t('loading')}...
            </Headline>
            <ActivityIndicator
              size={'large'}
              theme={{
                ...appSettings.currentTheme,
                colors: {primary: appSettings.currentTheme.colors.accent},
              }}
            />
          </Fragment>
        )}
      </View>
    );
  }

  if (gating && timedOut) {
    return (
      <View
        style={[
          styles.loadingContainer,
          {backgroundColor: appSettings.currentTheme.colors.background},
        ]}>
        {!waitingToShowLoading && (
          <Fragment>
            <Headline style={[styles.padded, {color: appSettings.currentTheme.colors.text}]}>
              {appSettings.t('unknownError')}
            </Headline>
          </Fragment>
        )}
      </View>
    );
  }
  //     // FIXME we need to detect if they are logging in to a new device
  //     // - purge old device records?
  //     // - ask them to verify new number?

  //     // Get the tipi
  //     this._tipi = await FirestoreUserGate.loadTipi(this._user.kullkiId);

  //     // Check all the permissions
  //     try {
  //       const permissionsStatus = await UserDataService.checkAllPermissions();
  //       console.log(
  //         'FirestoreUserGate::loadAuthenticatedUserData - got permissions results:',
  //         permissionsStatus
  //       );
  //       this._permissions = permissionsStatus;
  //     } catch (e) {
  //       console.log('FirestoreUserGate::loadAuthenticatedUserData - unable to get permissions: ', e);
  //     }
  //   }

  //   // Add their kullki id to analytics
  //   Analytics.setAnalyticsUserProperties({ kullkiId: this._user?.kullkiId ?? null });
  //   firebase.crashlytics().setUserId(this._user?.kullkiId ?? '');

  //   // We should be ready to show in-app-messages now that we have the user initialized
  //   await firebase.inAppMessaging().setMessagesDisplaySuppressed(false);
  //   firebase.crashlytics().log('FirestoreUserGate::loadAuthenticatedUserData complete');
  //   this.setIsCurrentDataStored(false);
  //   this._initialLoadComplete = true;
  // }

  return <>{props.children}</>;
};

// We want the source of truth to be the firestore user, copy relevant auth bits in
const copyAuthUsertoFirestore = (
  authUser: FirebaseAuthTypes.User,
  firestoreUser?: User,
  additionalUserInfo?: FirebaseAuthTypes.AdditionalUserInfo,
): User => {
  console.log(
    'FirestoreUserGate::copyAuthUsertoFirestore with user/userinfo:',
    JSON.stringify(authUser, null, 2),
    JSON.stringify(firestoreUser, null, 2),
    JSON.stringify(additionalUserInfo, null, 2),
  );
  if (firestoreUser === undefined) {
    firestoreUser = {
      kullkiId: v4(),
      fbAuthId: authUser.uid,
      registerTime: new Date().getTime(),
    };
  }

  if (authUser.email && !firestoreUser.email) {
    firestoreUser.email = authUser.email;
  }
  if (authUser.phoneNumber && !firestoreUser.phoneNumber) {
    firestoreUser.phoneNumber = authUser.phoneNumber;
  }
  if (authUser.uid && !firestoreUser.fbAuthId) {
    firestoreUser.fbAuthId = authUser.uid;
  }
  if (authUser.metadata.creationTime && !firestoreUser.registerTime) {
    firestoreUser.registerTime = parseInt(authUser.metadata.creationTime, 10);
  }

  // Maybe we have additional user info?
  if (additionalUserInfo?.profile) {
    if (!firestoreUser.firstName && additionalUserInfo.profile.hasOwnProperty('first_name')) {
      firestoreUser.firstName = additionalUserInfo.profile.first_name;
    }
    if (!firestoreUser.firstName && additionalUserInfo.profile.hasOwnProperty('given_name')) {
      firestoreUser.firstName = additionalUserInfo.profile.given_name;
    }
    console.log(
      `FirestoreUserGate::copyAuthUsertoFirestore first name? ${firestoreUser.firstName}`,
    );
    if (!firestoreUser.secondName && additionalUserInfo.profile.hasOwnProperty('last_name')) {
      firestoreUser.secondName = additionalUserInfo.profile.last_name;
    }
    if (!firestoreUser.secondName && additionalUserInfo.profile.hasOwnProperty('family_name')) {
      firestoreUser.secondName = additionalUserInfo.profile.family_name;
    }
    console.log(
      `FirestoreUserGate::copyAuthUsertoFirestore last name? ${firestoreUser.secondName}`,
    );

    if (!authUser.photoURL && additionalUserInfo.profile.hasOwnProperty('picture')) {
      console.log('FirestoreUserGate::copyAuthUserToFirestore attempting profile photoURL update');
      authUser
        .updateProfile({
          photoURL: additionalUserInfo.profile.picture.data.url,
        })
        .catch(e =>
          console.log('FirestoreUserGate::copyAuthusertoFirestore photo copy error: ', e),
        );
    }
    console.log(`FirestoreUserGate::copyAuthUsertoFirestore photo URL? ${authUser.photoURL}`);
  }

  return firestoreUser;
};

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    // alignSelf: 'center',
    alignItems: 'center',
    // textAlignVertical: true,
  },
  padded: {
    padding: 40,
  },
});
