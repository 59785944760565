import {Theme as PaperTheme} from 'react-native-paper/lib/typescript/types';
import {Theme as NavigatorionTheme} from '@react-navigation/native';
import {DarkTheme as PaperDarkTheme, DefaultTheme as PaperDefaultTheme} from 'react-native-paper';
import {
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
} from '@react-navigation/native';

// Customize the themes here as necessary

export const darkTheme: PaperTheme & NavigatorionTheme = {
  ...PaperDarkTheme,
  ...NavigationDarkTheme,
  dark: true,
  roundness: 10,
  colors: {
    ...PaperDarkTheme.colors,
    ...NavigationDarkTheme.colors,
    primary: '#84b8dd',
    accent: '#8cd6e6',
  },
};

// export const defaultTheme: PaperTheme & NavigatorionTheme = {
//   ...PaperDefaultTheme,
//   ...NavigationDefaultTheme,
//   roundness: 10,
//   dark: false,

//   colors: {
//     ...PaperDefaultTheme.colors,
//     ...NavigationDefaultTheme.colors,
//     primary: '#4D67AC',
//     accent: '#CCD3E8',
//   },
// };

// export const darkTheme: PaperTheme & NavigatorionTheme = {
//   ...PaperDarkTheme,
//   ...NavigationDarkTheme,
//   dark: true,
//   roundness: 10,
//   colors: {
//     ...PaperDarkTheme.colors,
//     ...NavigationDarkTheme.colors,
//     primary: '#84b8dd',
//     accent: '#8cd6e6',
//     background: '#eeeeee',
//     surface: '#EDF4FA',
//     text: '#000000',
//     disabled: '#818181',
//     placeholder: '#818181',
//     backdrop: '#6ba9d6',
//     onSurface: '#8cd6e6',
//     notification: '#344679',
//   },
// };

export const defaultTheme: PaperTheme & NavigatorionTheme = {
  ...PaperDefaultTheme,
  ...NavigationDefaultTheme,
  roundness: 10,
  dark: false,

  colors: {
    ...PaperDefaultTheme.colors,
    ...NavigationDefaultTheme.colors,
    primary: '#0971BB',
    accent: '#19ACCC',
    background: '#ffffff',
    surface: '#C5D2E6',
    text: '#344679',
    disabled: '#C5D2E6',
    placeholder: '#808BAB',
    backdrop: '#6ba9d6',
    onSurface: '#19ACCC',
    notification: '#344679',
  },
};
