import {english} from './en';
import {spanish} from './es';

export interface translation {
  [key: string]: string;
}

export interface translationMap {
  [key: string]: translation;
}

export const format = (input: string, replacements: string[]): string => {
  const args = replacements;
  return input.replace(/\{\{|\}\}|\{(\d+)\}/g, (m, n) => {
    if (m === '{{') {
      return '{';
    }
    if (m === '}}') {
      return '}';
    }
    return args[n];
  });
};

export const translations: translationMap = {en: english, es: spanish};
