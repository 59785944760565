/* eslint-disable react-native/no-inline-styles */
import XDate from 'xdate';
import {useEffect, useState} from 'react';
import {
  ActivityIndicator,
  Image,
  Linking,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import {Avatar, Button, Headline, Subheading, Text} from 'react-native-paper';
import analytics from '@react-native-firebase/analytics';
import functions from '@react-native-firebase/functions';

import {User} from '../models/IdentityModels';
import {mainNavigatorRef} from '../App';
import {useAppSettings} from './AppSettings';
import AppConfig from '../AppConfig';

import {v4} from 'uuid';
import {useAlerts} from 'react-native-paper-alerts';
import {NotFound} from './NotFound';
import Hero from './Hero';
import {displayNameFromUser} from '../hooks/useUserInfo';
import {format} from '../translations';

// Process a long link from profile name, to short deep link for the button onPress
const getShortProfileLink = async (longLink: string): Promise<string> => {
  console.log('getShortProfileLink on ' + longLink);
  const getProfileLink = functions().httpsCallable('getProfileLink');
  let result;
  try {
    result = await getProfileLink({
      longLink,
      'Correlation-ID': v4(),
    });
    if (result.data && result.data.result) {
      console.log('getShortProfileLink success! ' + JSON.stringify(result, null, 2));
      analytics().logEvent('successWebGetShortProfileLink');
      return result.data.result.shortLink;
    }
    throw new Error(`getShortProfileLink ${JSON.stringify(result)}`);
  } catch (e) {
    console.log('getShortProfileLink error:', e);
    analytics().logEvent('failureWebGetShortProfileLink');
    throw e;
  }
};

// This turns a User.profileName string into a User object
const resolveProfileName = async (profileName: string): Promise<User | null> => {
  console.log(`resolveProfileName ${profileName}`);
  const getPublicProfile = functions().httpsCallable('getPublicProfile');
  const result = await getPublicProfile({
    profileName,
    'Correlation-ID': v4(),
  });
  if (result.data && result.data.result) {
    console.log('resolveProfileName success! ' + JSON.stringify(result.data.result, null, 2));
    analytics().logEvent('successWebresolveProfileName');

    return result.data.result as User;
  }
  if (result.data.error && result.data.error === 'error/profile-not-found') {
    console.log('resolveProfileName not found');
    throw new Error(result.data.error);
  }
  throw new Error(`resolveProfileName failure ${JSON.stringify(result)}`);
};

export const PublicProfile = () => {
  // const [longProfileLink, setLongProfileLink] = useState<string | null>(null);
  const [profileName, setProfileName] = useState<string | undefined>(undefined);
  const [deepLink, setDeepLink] = useState<string | undefined>(undefined);
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState<User | undefined>(undefined);
  const appSettings = useAppSettings();
  const Alert = useAlerts();

  console.log('current route: ' + JSON.stringify(mainNavigatorRef.getCurrentRoute(), null, 2));

  const possibleProfileName = mainNavigatorRef.getCurrentRoute()?.path?.substr(1);
  console.log(`PublicProfile possibleProfileName: ${possibleProfileName}`);

  console.log(
    `initializing/user/deepLink/profileName: ${!!initializing}/${!!user}/${!!deepLink}/${!!profileName}`,
  );

  useEffect(() => {
    if (possibleProfileName === undefined) {
      console.warn('undefined possibleProfileName...');
      return;
    }
    // If not alphanumeric or has an unreasonable length, it's not a profile. Protect against billable functions calls
    if (!/^[a-zA-Z0-9]+$/.test(possibleProfileName ?? '!!') || possibleProfileName.length > 256) {
      console.warn('this is an illegal profile name. 404');
      setProfileName(possibleProfileName);
      setDeepLink(undefined);
      setUser(undefined);
      setInitializing(false);
      return;
    }
    // TODO if the path that came in is different then the current one, we need to reset, load new user...
    if (possibleProfileName !== undefined && possibleProfileName !== profileName) {
      setInitializing(true);
      setProfileName(possibleProfileName);

      const name = possibleProfileName;
      // const name = 'MikeHardy'; //'qbvQ';
      const longLink = `${AppConfig.getDynamicLinkProfileRoot()}/?link=${AppConfig.getWebAppURIRoot()}/${name}&apn=${AppConfig.getAndroidPackage()}&isi=${AppConfig.getIOSAppId()}&ibi=${AppConfig.getIOSBundle()}&st=${encodeURI(
        `${name}+${appSettings.t('ProfileShareSocialYour')}+Komp.ai`,
      )}&sd=${encodeURI(
        appSettings.t('ProfileShareSocialDescription'),
      )}&si=${AppConfig.getWebAppURIRoot()}/ms-icon-310x310.png`;

      getShortProfileLink(longLink)
        .then(link => {
          setDeepLink(link);
        })
        .catch(e => {
          console.error('Short profile error: ' + e);
        });

      console.log('PublicProfile resolving a profile');
      resolveProfileName(name)
        .then(resolvedUser => {
          if (resolvedUser) {
            setUser(resolvedUser);
          }
        })
        .catch(e => {
          if (e.message !== 'error/profile-not-found') {
            console.log('PublicProfile error:', e);
            Alert.alert(
              appSettings.t('FunctionErrorTitle'),
              appSettings.t('FunctionErrorMessage'),
              [{text: appSettings.t('OK')}],
            );

            console.log('PublicProfile resolution failed', e);
          }
        })
        .finally(() => {
          setInitializing(false);
        });
    }
  }, [initializing, user?.kullkiId, Alert, appSettings, possibleProfileName, profileName]);

  console.log(`profile deep link is: ${deepLink}`);
  let localeRegisterDateString = '';
  if (user && user.registerTime) {
    localeRegisterDateString = new XDate(user.registerTime).toString(
      appSettings.t('ProfileBirthdateFormat'),
    );

    console.log(`PublicProfile localeDateString: ${localeRegisterDateString}`);
  }

  // "firstName": "Mike",
  // "secondName": "Hardy",
  // "profileName": "MikeHardy",
  // "registerTime": 1614551132017,
  // localeRegisterDateString
  const displayName = displayNameFromUser(user);

  return (
    <View style={[styles.detailsContainer, {}]}>
      {/* {!initializing && !user && !profileLink && !profileName && <NotFound />} */}
      {!initializing && !user && !!profileName && <NotFound />}

      {initializing && (
        // <View
        //   style={{
        //     flex: 1,
        //     justifyContent: 'center',
        //     alignSelf: 'center',
        //     alignContent: 'center',
        //     alignItems: 'center',
        //   }}>
        <ActivityIndicator size="large" color="#0697F5" />
        // </View>
      )}

      {user && (
        <ScrollView
          style={{width: '100%'}}
          contentContainerStyle={{
            flex: 1,
            flexGrow: 1,
            width: '100%',
            // borderColor: 'green',
            // borderWidth: 1,
          }}>
          <Hero height={60} style={{backgroundColor: appSettings.currentTheme.colors.primary}} />
          <View style={[styles.content, styles.profile]}>
            {/* {user.photoURL ? (
          <Avatar.Image size={80} source={{uri: user.photoURL}} />
        ) : ( */}
            <Avatar.Text
              size={80}
              label={displayName.substr(0, 2).toUpperCase()}
              style={[styles.avatar, {borderColor: appSettings.currentTheme.colors.background}]}
            />
            {/* )} */}
          </View>
          <View
            style={[
              styles.centeredPadded,
              // {borderColor: 'red', borderWidth: 1},
            ]}>
            <Headline style={styles.appBarButtonPadding}>{displayName}</Headline>
            <View style={styles.content}>
              {!!user?.citizenNumber && (
                <Subheading>
                  {appSettings.t('ProfileCedulaLabel')}: {user.citizenNumber}
                </Subheading>
              )}
              {user?.profileName && user.profileName !== '' && (
                <Subheading>
                  {format(appSettings.t('ProfileProfileNameLabel'), [user.profileName])}
                </Subheading>
              )}
              {!!user?.dateOfBirth && (
                <Subheading>
                  {`${appSettings.t('ProfileBirthdateLabel')}: ${
                    user?.dateOfBirth &&
                    (user.dateOfBirthFormatted = new XDate(user.dateOfBirth).toString(
                      appSettings.t('ProfileBirthdateFormat'),
                    ))
                  }`}
                </Subheading>
              )}
              {!!user?.registerTime && (
                <Subheading>
                  {`${appSettings.t('ProfileRegisterDateLabel')}: ${
                    user?.registerTime &&
                    (user.registerTimeFormatted = new XDate(user.registerTime).toString(
                      appSettings.t('ProfileBirthdateFormat'),
                    ))
                  }`}
                </Subheading>
              )}
              <Subheading>Komp.ai Score: ###</Subheading>
            </View>
            <View style={styles.centeredPadded} />
            <Button
              uppercase={false}
              style={styles.appSettingsButtonStyle}
              mode="contained"
              onPress={() => !!deepLink && Linking.openURL(deepLink)}>
              {appSettings.t('ProfileVisitProfileInApp')}
            </Button>
          </View>
        </ScrollView>
      )}

      {user && false && (
        <View
          style={{
            alignContent: 'stretch',
          }}>
          <View
            style={{
              alignSelf: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}>
            {/* Dimas start */}

            <View style={{flexDirection: 'column', flexGrow: 1, flex: 1}}>
              <Image
                fadeDuration={0}
                style={{
                  flexDirection: 'row',
                  flex: 2,
                  justifyContent: 'center',
                  alignSelf: 'center',
                  padding: 0,
                  marginBottom: 0,
                  minWidth: 571,
                  minHeight: 92,
                  maxWidth: 571,
                  maxHeight: 91,
                }}
                source={require('../../static/assets/images/newProfile/webProfile_header.png')}
              />
            </View>
            <View
              style={{
                flexDirection: 'row',
                flexGrow: 1,
                flex: 1,
                maxWidth: 571,
                alignContent: 'stretch',
                alignSelf: 'stretch',
              }}>
              <View
                style={{
                  flexDirection: 'column',
                  flex: 1,
                  backgroundColor: 'transparent',
                }}>
                <Image
                  fadeDuration={0}
                  style={{
                    flexDirection: 'column',
                    flex: 0,
                    justifyContent: 'flex-start',
                    alignSelf: 'flex-start',
                    padding: 0,
                    marginBottom: 0,
                    minWidth: 47,
                    minHeight: 540,
                    maxWidth: 47,
                    maxHeight: 540,
                  }}
                  source={require('../../static/assets/images/newProfile/webProfile_left.png')}
                />
              </View>
              {/* aqui empieza el meollo */}
              <View
                style={{
                  backgroundColor: 'transparent',
                  flexDirection: 'column',
                  flexGrow: 1,
                  alignItems: 'center',
                  alignContent: 'center',
                  alignSelf: 'stretch',
                  flex: 3,
                }}>
                <Text
                  style={[
                    styles.topHeaderText,
                    {
                      flex: 1,
                      marginTop: 10,
                      padding: 10,
                      marginBottom: 10,
                      maxHeight: 55,
                      alignSelf: 'stretch',
                      color: '#FFFFFF',
                      backgroundColor: '#0697F5',
                    },
                  ]}>
                  {user?.firstName} {user?.secondName}
                </Text>

                <View>
                  {/* KScore */}

                  <View
                    style={{
                      flex: 0,
                      flexDirection: 'row',
                      // alignItems: 'flex-end',
                      // alignContent: 'flex-end',
                      alignSelf: 'flex-end',
                      borderWidth: 0,
                    }}>
                    <View style={{flex: 3, flexDirection: 'column'}}>
                      <Image
                        fadeDuration={0}
                        style={[
                          {
                            height: 45,
                            width: 90,
                            alignSelf: 'flex-start',
                          },
                        ]}
                        source={require('../../static/assets/images/logos/logoKompaiScore.png')}
                      />
                    </View>

                    <View style={{flex: 2, flexDirection: 'column'}}>
                      <Text
                        style={[
                          styles.labelTitle,
                          {
                            color: '#0697F5',
                            fontSize: 30,
                            marginTop: 0,
                            paddingTop: 0,
                          },
                        ]}>
                        ##
                        {/*
                          985

                          hey Mike, can we assign the color of user?.currentScore based on the score ?
                          scoreColor = ??

                          default --> color: '#AA1F23'
                          > 750 --> color: '#E73125'
                          > 850 --> color: '#F37820'
                          > 900 --> color: '#FDBF0F'
                          > 925 --> color: '#79AE42'
                          > 950 --> color: '#2B8140'

                          and use scoreColor on { color: '#0697F5', fontSize: 30, marginTop: 0, paddingTop: 0 },

                          */}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      flex: 0,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <View style={{flex: 1, flexDirection: 'column'}}>
                      <Text style={[styles.labelTitle, {color: '#0697F5'}]}>
                        {appSettings.t('ProfileProfileNameLabel')}
                        {user?.profileName}
                        {/* <Text style={[rnStyles.labelSubtitle, { color: '#0697F5' }]}></Text> */}
                      </Text>
                    </View>
                  </View>

                  {/* just true or false to show some extra information - Dimas */}
                  {true && ( // this.state.profileViewType === 'connected' && (
                    <View>
                      {/* new style, from Quiz */}

                      {/* CI */}
                      <View
                        style={{
                          flex: 0,
                          flexDirection: 'row',
                          alignItems: 'center',
                          borderStyle: 'solid',
                          marginTop: 10,
                          borderColor: '#00ACE6',
                          borderWidth: 0,
                          borderTopWidth: 1,
                        }}>
                        <View style={{flex: 1, flexDirection: 'column'}}>
                          <Text style={[styles.labelTitle, {color: '#0697F5'}]}>
                            {appSettings.t('ProfileCedulaLabel')}
                            {': '}
                            <Text
                              style={[
                                styles.labelTitle,
                                {
                                  color: '#0697F5',
                                  fontStyle: 'normal',
                                  fontWeight: 'normal',
                                },
                              ]}>
                              {user?.citizenNumber ?? '#########-#'}
                            </Text>
                          </Text>
                        </View>
                      </View>
                      {/* eMail */}
                      <View
                        style={{
                          flex: 0,
                          flexDirection: 'row',
                          alignItems: 'center',
                          borderStyle: 'solid',
                          marginTop: 10,
                          borderColor: '#00ACE6',
                          borderWidth: 0,
                          borderTopWidth: 1,
                        }}>
                        <View style={{flex: 1, flexDirection: 'column'}}>
                          <Text style={[styles.labelTitle, {color: '#0697F5'}]}>
                            {appSettings.t('Email')}
                            {': '}
                            <Text
                              style={[
                                styles.labelTitle,
                                {
                                  color: '#0697F5',
                                  fontStyle: 'normal',
                                  fontWeight: 'normal',
                                },
                              ]}>
                              {user?.email ?? 'xxxx@xxxxx.xxx'}
                            </Text>
                          </Text>
                        </View>
                      </View>

                      {/* Phone number, if available */}
                      {user?.phoneNumber && (
                        <View
                          style={{
                            flex: 0,
                            flexDirection: 'row',
                            alignItems: 'center',
                            borderStyle: 'solid',
                            marginTop: 10,
                            borderColor: '#00ACE6',
                            borderWidth: 0,
                            borderTopWidth: 1,
                          }}>
                          <View style={{flex: 1, flexDirection: 'column'}}>
                            <Text style={[styles.labelTitle, {color: '#0697F5'}]}>
                              {appSettings.t('ShowScorePhoneNumber')}
                              {': '}
                              <Text style={[styles.labelSubtitle, {color: '#0697F5'}]}>
                                {user?.phoneNumber}
                              </Text>
                            </Text>
                          </View>
                        </View>
                      )}

                      {/* Member since */}
                      <View
                        style={{
                          flex: 0,
                          flexDirection: 'row',
                          alignItems: 'center',
                          borderStyle: 'solid',
                          marginTop: 10,
                          marginBottom: 25,
                          borderColor: '#00ACE6',
                          borderWidth: 0,
                          borderTopWidth: 1,
                        }}>
                        <View style={{flex: 1, flexDirection: 'column'}}>
                          <Text style={[styles.labelTitle, {color: '#0697F5'}]}>
                            {appSettings.t('MemberSinceLabel')}
                            {': '}
                            <Text style={[styles.labelSubtitle, {color: '#0697F5'}]}>
                              {localeRegisterDateString}
                            </Text>
                          </Text>
                        </View>
                      </View>
                      {/* news tyle END */}
                    </View>
                  )}
                </View>

                {deepLink !== undefined && (
                  <Pressable onPress={() => Linking.openURL(deepLink ?? '')}>
                    <View style={styles.loginIngresarView}>
                      <Text style={[styles.buttonText]}>
                        {appSettings.t('ProfileVisitProfileInApp')}
                      </Text>
                    </View>
                  </Pressable>
                )}
              </View>
              {/* aqui termina el meollo */}
              <View
                style={{
                  flexDirection: 'column',
                  flex: 1,
                  // borderWidth: 1,
                  // borderColor: 'red',
                  justifyContent: 'flex-end',
                  backgroundColor: 'transparent',
                }}>
                <Image
                  fadeDuration={0}
                  style={{
                    flexDirection: 'column',
                    // flex: 0,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    alignSelf: 'flex-end',
                    padding: 0,
                    marginBottom: 0,
                    minWidth: 53,
                    minHeight: 540,
                    maxWidth: 53,
                    maxHeight: 540,
                  }}
                  source={require('../../static/assets/images/newProfile/webProfile_right.png')}
                />
              </View>
            </View>
            <View
              style={{
                flexDirection: 'column',
                flexGrow: 1,
                flex: 1,
                // borderWidth: 1,
                // borderColor: 'green',
              }}>
              <Image
                fadeDuration={0}
                style={{
                  flexDirection: 'row',
                  flex: 2,
                  justifyContent: 'center',
                  alignSelf: 'center',
                  padding: 0,
                  marginTop: 0,
                  minWidth: 571,
                  minHeight: 135,
                  maxWidth: 571,
                  maxHeight: 135,
                }}
                source={require('../../static/assets/images/newProfile/webProfile_footer.png')}
              />
            </View>

            {/* Dimas end first block */}
          </View>
          {/* Dimas end */}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  detailsContainer: {
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    // paddingHorizontal: 24,
  },
  loginIngresarView: {
    margin: 6,
    // padding: 6,
    borderRadius: 6,
    backgroundColor: '#4D67AC',
  },
  buttonText: {
    fontSize: 18,
    marginVertical: 6,
    marginHorizontal: 12,
    color: 'white',
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center',
  },
  labelTitle: {
    fontSize: 17,
    fontWeight: 'bold',
    marginTop: 10,
    marginHorizontal: 12,
    color: 'white',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    alignContent: 'flex-start',
  },
  labelSubtitle: {
    fontSize: 12,
    marginBottom: 10,
    marginHorizontal: 12,
    color: 'white',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    alignContent: 'flex-start',
  },
  topHeaderText: {
    fontSize: 32,
    maxHeight: 40,
    color: 'white',
    textAlign: 'center',
  },
  content: {
    // paddingHorizontal: 20,
  },
  centeredPadded: {
    alignSelf: 'center',
    alignContent: 'center',
    padding: 15,
  },
  pageContainer: {
    flex: 1,
    // padding: 20,
  },
  profile: {
    marginTop: -50,
    paddingVertical: 10,
  },
  avatar: {
    borderWidth: 5,
    elevation: 4,
  },
  appBarButtonPadding: {
    // padding: 10,
  },
  appSettingsButtonStyle: {
    height: 30,
    width: '80%',
    alignSelf: 'center',
    justifyContent: 'center',
  },
});
