import {Linking, Platform} from 'react-native';
import packageJson from '../../package.json';

export enum ReleaseStreams {
  dev = 'dev',
  test = 'test',
  staging = 'staging',
  prod = 'prod',
}

class AppConfig {
  private _frontendHost: string = '';

  private _platformType: string;

  private _startupTime: number;

  private _buildNumber: string | null = null;

  private _androidPackage: string | null = null;

  private _iosBundle: string | null = null;

  private _appVersion: string | null = null;

  constructor() {
    this._platformType = Platform.OS;
    this._startupTime = Date.now();
    this.initialize();
  }

  initialize() {
    Linking.getInitialURL().then(url => {
      console.log('got an initial URL of ' + url);
      if (url) {
        const urlObject = new URL(url);
        this._frontendHost = urlObject.host;
      }
      console.log('frontEndURL is now ' + this._frontendHost);
    });
  }

  isDebugBuild(): boolean {
    return __DEV__;
  }

  isPilot(): boolean {
    // TODO make this more dynamic, but right now, yes it is the pilot build
    return true;
  }

  getPlatformType(): string {
    return this._platformType;
  }

  getStartupTime(): number {
    return this._startupTime;
  }

  getAppVersion(): string | null {
    if (!this._appVersion) {
      // FIXME need to plumb this through
      this._appVersion = (packageJson.version as string) ?? '1.0.0-pre1';
      console.log(`AppConfig::getAppVersion - version starting as ${this._appVersion}`);

      // If it is not a pre-release, or if it is prod, tear off the build number
      if (
        this._appVersion.indexOf('pre') === -1 ||
        this.getReleaseStream() === ReleaseStreams.prod
      ) {
        this._appVersion = this._appVersion.substring(0, this._appVersion.indexOf('-'));
        console.log(
          `AppConfig::getAppVersion - version not pre-release? version now ${this._appVersion}`,
        );
      }

      // For all but prod, take the release stream on the end
      if (this.getReleaseStream() !== ReleaseStreams.prod) {
        this._appVersion += `.${this.getReleaseStream()}`;
        console.log(`AppConfig::getAppVersion - version not prod? version now ${this._appVersion}`);
      }
    }
    // console.log('AppConfig::getAppVersion - returning ' + this._appVersion);
    return this._appVersion;
  }

  getFrontendHost(): string {
    return this._frontendHost;
  }

  getProtocol(): string {
    if (
      this.getPlatformType() === 'web' &&
      // @ts-ignore
      typeof location !== 'undefined' &&
      // @ts-ignore
      typeof location.protocol !== 'undefined'
    ) {
      // @ts-ignore
      return location.protocol;
    }
    return 'https:';
  }

  getFrontendBaseUrl(): string {
    return `${this.getProtocol()}//${this._frontendHost}`;
  }

  getDocRoot(): string {
    return '';
  }

  getImagePath(imageName = ''): string {
    return `${this.getDocRoot()}/assets/images/${imageName}`;
  }

  getUserSessionDuration(): number {
    // Note that this is disabled for the moment
    return 1000 * 60 * 60;
  }

  getFAQLink(): string {
    return `${this.getHostingURIRoot()}/FAQ.html`;
  }

  getHostingURIRoot(): string {
    if (process.env.REACT_APP_FIREBASE_EMULATOR === 'true') {
      return 'http://localhost:5000';
    }

    switch (this.getReleaseStream()) {
      case ReleaseStreams.prod:
        return 'https://docs.komp.ai';
      default:
        return `https://docs${this.getReleaseStream()}.komp.ai`;
    }
  }

  getWebAppURIRoot(): string {
    if (process.env.REACT_APP_FIREBASE_EMULATOR === 'true') {
      return 'http://localhost:5005';
    }

    switch (this.getReleaseStream()) {
      case ReleaseStreams.prod:
        return 'https://app.komp.ai';
      default:
        return `https://app${this.getReleaseStream()}.komp.ai`;
    }
  }

  getAPIURIRoot(): string {
    if (process.env.REACT_APP_FIREBASE_EMULATOR === 'true') {
      return 'http://localhost:5006';
    }

    switch (this.getReleaseStream()) {
      case ReleaseStreams.prod:
        return 'https://api.komp.ai';
      default:
        return `https://api${this.getReleaseStream()}.komp.ai`;
    }
  }

  getDynamicLinkProfileRoot(): string {
    return `${this.getWebAppURIRoot()}/me`;
  }

  getDynamicLinkPrefixes(): string[] {
    return ['me', 'link', 'promo'];
  }

  getTermsAndConditionsLink(): string {
    return `${this.getHostingURIRoot()}/legal/TyC_Kullki.html`;
  }

  getSharingTermsAndConditionsLink(): string {
    return `${this.getHostingURIRoot()}/legal/TyC_Sharing.html`;
  }

  getNewsLinkBase(): string {
    return `${this.getHostingURIRoot()}/news/`;
  }

  getGoogleWebClientId(): string {
    switch (this.getReleaseStream()) {
      case ReleaseStreams.dev:
        return '77207930184-jc54rhsdrvn6ofr5v2g3o4cbcav2i2cm.apps.googleusercontent.com';
      case ReleaseStreams.test:
        return '1064266679206-k0kq6s3bo2q76dufg3qen3p8arntr74r.apps.googleusercontent.com';
      case ReleaseStreams.staging:
        return '454766603170-n2gvi6mh2pv49kapqq47itd9cniartl5.apps.googleusercontent.com';
      default:
        // default to prod, it's a safe choice at minimum
        return '629722894643-lnnhcpu01r1chbtiui73g2j0006fr4bj.apps.googleusercontent.com';
    }
  }

  getIOSAppId(releaseStream?: string): string {
    let stream = releaseStream;
    if (!stream) {
      stream = this.getReleaseStream();
    }
    switch (stream) {
      case ReleaseStreams.dev:
        return '1498043778';
      case ReleaseStreams.test:
        return '1498109596';
      case ReleaseStreams.staging:
        return '1498259693';
      default:
        // default to prod, it's a safe choice at minimum
        return '1498259800';
    }
  }

  getAndroidPackage(releaseStream?: string): string {
    let stream = releaseStream;
    if (!stream) {
      stream = this.getReleaseStream();
    }
    let androidPackage = 'com.kullki.kscore';
    if (!this._androidPackage || releaseStream) {
      switch (stream) {
        case ReleaseStreams.dev:
          androidPackage = 'com.kullki.kscore.dev';
          break;
        case ReleaseStreams.test:
          androidPackage = 'com.kullki.kscore.test';
          break;
        case ReleaseStreams.staging:
          androidPackage = 'com.kullki.kscore.staging';
          break;
        default:
          // default to prod, it's a safe choice at minimum
          androidPackage = 'com.kullki.kscore';
          break;
      }
    }
    if (releaseStream || !this._androidPackage) {
      this._androidPackage = androidPackage;
    }
    return this._androidPackage;
  }

  getIOSBundle(releaseStream?: string): string {
    let stream = releaseStream;
    if (!stream) {
      stream = this.getReleaseStream();
    }
    let iosBundle = 'ai.komp.score';
    if (!this._iosBundle || releaseStream) {
      switch (stream) {
        case ReleaseStreams.dev:
          iosBundle = 'ai.komp.score.dev';
          break;
        case ReleaseStreams.test:
          iosBundle = 'ai.komp.score.test';
          break;
        case ReleaseStreams.staging:
          iosBundle = 'ai.komp.score.staging';
          break;
        default:
          // default to prod, it's a safe choice at minimum
          iosBundle = 'ai.komp.score';
          break;
      }
    }
    if (releaseStream || !this._iosBundle) {
      this._iosBundle = iosBundle;
    }
    return this._iosBundle;
  }

  getBuildNumber(): string {
    if (!this._buildNumber) {
      // this._buildNumber = DeviceInfoService.getEssentialDeviceInfo()!.buildNumber;
      return '323232';
    }
    return this._buildNumber;
  }

  getReleaseStream(): string {
    // For web, this is passed in via a special environment variable
    if (this.getPlatformType() === 'web') {
      return process.env.REACT_APP_RELEASE_STREAM ?? 'dev';
    }

    // FIXME Need to plumb this through for native
    return ReleaseStreams.dev;
  }

  getAppVersionUrl(): string {
    switch (this.getReleaseStream()) {
      case ReleaseStreams.prod:
        return 'https://kscoreapp-dist.web.app/KScoreApp-version-ios.json';
      default:
        return `https://kscoreapp${this.getReleaseStream()}-dist.web.app/KScoreApp-version-ios-${this.getReleaseStream()}.json`;
    }
  }

  getApkVersionUrl(): string {
    switch (this.getReleaseStream()) {
      case ReleaseStreams.prod:
        return 'https://kscoreapp-dist.web.app/KScoreApp-version.json';
      default:
        return `https://kscoreapp${this.getReleaseStream()}-dist.web.app/KScoreApp-version-${this.getReleaseStream()}.json`;
    }
  }

  getFirebaseConfig() {
    switch (this.getReleaseStream()) {
      case ReleaseStreams.dev:
        return {
          apiKey: 'AIzaSyDSmVBrxUs5V7wZxNrMQw2ex4R1Ecl2650',
          authDomain: 'kscoreappdev.firebaseapp.com',
          databaseURL: 'https://kscoreappdev.firebaseio.com',
          projectId: 'kscoreappdev',
          storageBucket: 'kscoreappdev.appspot.com',
          messagingSenderId: '77207930184',
          appId: '1:77207930184:web:6feec0ba8c8365e9a45f7d',
          measurementId: 'G-CN8BKNTQ8K',
        };
      case ReleaseStreams.test:
        return {
          apiKey: 'AIzaSyBW7v7Xq7PXWf4Y4kdihRjJyeLh1_zpC7w',
          authDomain: 'kscoreapptest.firebaseapp.com',
          databaseURL: 'https://kscoreapptest.firebaseio.com',
          projectId: 'kscoreapptest',
          storageBucket: 'kscoreapptest.appspot.com',
          messagingSenderId: '1064266679206',
          appId: '1:1064266679206:web:160af24e09874af0554567',
          measurementId: 'G-BKQKTJTVRL',
        };
      case ReleaseStreams.staging:
        return {
          apiKey: 'AIzaSyBdxqrJckdQx2bdLC7tQE0t7xipfT3rpyc',
          authDomain: 'kscoreappstaging.firebaseapp.com',
          databaseURL: 'https://kscoreappstaging.firebaseio.com',
          projectId: 'kscoreappstaging',
          storageBucket: 'kscoreappstaging.appspot.com',
          messagingSenderId: '454766603170',
          appId: '1:454766603170:web:76b75d5031cbb00a4520fb',
          measurementId: 'G-8PWH9C4VJJ',
        };
      default:
        // Prod is the default - maybe unexpected, but already public...
        return {
          apiKey: 'AIzaSyDwgEF4EvzRGkBUlFveHoOF9M_bA9Bs-nk',
          authDomain: 'kscoreapp.firebaseapp.com',
          databaseURL: 'https://kscoreapp.firebaseio.com',
          projectId: 'kscoreapp',
          storageBucket: 'kscoreapp.appspot.com',
          messagingSenderId: '629722894643',
          appId: '1:629722894643:web:5c5a2fda0250c1977dae46',
          measurementId: 'G-VZ229CHVK1',
        };
    }
  }
}

export default new AppConfig();
