import auth from '@react-native-firebase/auth';
import analytics from '@react-native-firebase/analytics';
import firestore, {FirebaseFirestoreTypes} from '@react-native-firebase/firestore';
import {useQuery} from 'react-query';

import config from '../AppConfig';
import {
  DelegatedUser,
  Demographics,
  KSocialScore,
  ScoreBatch,
  TIPI,
  User,
  UserType,
} from '../models/IdentityModels';
import {v4} from 'uuid';

export interface UserHolder {
  userIds?: FirebaseFirestoreTypes.DocumentReference<User>[];
  users?: User[];
  userId?: FirebaseFirestoreTypes.DocumentReference<User>;
  user?: User;
}

export type UserInfo = {
  user: User;
  isAdmin: boolean;
  isBusiness: boolean;
};

export async function fetchAuthUserInfo(): Promise<UserInfo | undefined> {
  // console.log('fetchAuthUserInfo()');
  const authUser = auth().currentUser;
  const userSnapshot = await firestore()
    .collection('users')
    .where('fbAuthId', '==', authUser?.uid)
    .get();

  // console.log('fetchAuthUserInfo got results w/count: ' + userSnapshot.size);
  let user;
  if (userSnapshot.size === 1) {
    user = userSnapshot.docs[0].data() as User;
  }

  // FIXME clean up userbase and make sure there are no duplicates anymore.
  if (userSnapshot.size > 1) {
    console.warn(`FirestoreUserGate >1 firestore entry for ${authUser?.uid}`);

    // Sort them by register time (which we generate in a way that should spread entries)
    userSnapshot.docs.sort((a, b) => a.data().registerTime ?? 0 - b.data().registerTime ?? 0);

    analytics().logEvent('errorAuthUIDduplicatedInUsers', {
      fbAuthId: authUser?.uid,
    });
    user = userSnapshot.docs[0].data() as User;
  }
  if (user === undefined) {
    console.log('No firestore user for current auth user');
    return undefined;
  }
  // console.log('user is: ' + JSON.stringify(user));

  return {
    user,
    isBusiness: user.roles?.includes(UserType.Business) ?? false,
    isAdmin: user.roles?.includes(UserType.Admin) ?? false,
  };
}

export function useAuthUserInfo(): UserInfo | undefined {
  const userQueryResult = useQuery(['authUserInfo', auth().currentUser], fetchAuthUserInfo);
  if (userQueryResult.isLoading) {
    return;
  }
  if (userQueryResult.isError) {
    console.warn('we got an error? ' + userQueryResult.error);
    return;
  }
  return userQueryResult.data;
}

// async loadScores(kullkiId: string): Promise<KSocialScore[]> {
//   console.log(`UserStore::loadScore - loading scores for user ${kullkiId}`);
//   // Get a firm handle on the user storage
//   const userDocRef = firebase.firestore().collection('users').doc(kullkiId);

//   // Get the collection of scores for this kullkiId
//   const scoreObjects = [];
//   const docRef = userDocRef.collection('user_data').doc('scores');
//   const collectionRef = docRef.collection('snapshots');
//   try {
//     const scores = await collectionRef.get();
//     for (let i = 0; i < scores.size; i++) {
//       const score = scores.docs[i].data() as KSocialScore;
//       if (score && score.ksocial) {
//         score.ksocial = parseInt(score.ksocial, 10) >= 999 ? '998' : score.ksocial;
//         if (!score.ksocialAdjusted) {
//           score.ksocialAdjusted = score.ksocial;
//         }
//       }
//       if (score) score.timestamp = parseInt(scores.docs[i].id, 10);
//       if (score)
//         score.timestampFormatted = new XDate(score.timestamp).toString(
//           I18NService.translate('ProfileBirthdateFormat')
//         );
//       if (score) scoreObjects.push(score);
//       // console.log('UserStore::loadScores - got score ' + JSON.stringify(score) + ' at ' + scores.docs[i].id);
//     }
//     scoreObjects.sort((a, b) => b.timestamp - a.timestamp);

//     return Promise.resolve(scoreObjects);
//   } catch (e) {
//     console.log('UserStore::loadScores - unable to fetch scores?', e);
//     return Promise.reject(e);
//   }
// }
async function fetchScores(kullkiId?: string): Promise<KSocialScore[]> {
  // console.log(`fetchScores for user ${kullkiId}`);
  const userDocRef = firestore().collection('users').doc(kullkiId);
  const scoreObjects = [];
  const docRef = userDocRef.collection('user_data').doc('scores');
  const collectionRef = docRef.collection('snapshots');
  try {
    const scores = await collectionRef.get();
    for (let i = 0; i < scores.size; i++) {
      const score = scores.docs[i].data() as KSocialScore;
      if (score && score.ksocial) {
        score.ksocial = parseInt(score.ksocial, 10) >= 999 ? '998' : score.ksocial;
        if (!score.ksocialAdjusted) {
          score.ksocialAdjusted = score.ksocial;
        }
      }
      if (score) {
        score.timestamp = parseInt(scores.docs[i].id, 10);
      }
      // if (score)
      //   score.timestampFormatted = new XDate(score.timestamp).toString(
      //     I18NService.translate('ProfileBirthdateFormat'),
      //   );
      if (score) {
        scoreObjects.push(score);
      }
      // console.log(
      //   'fetchScores score ' +
      //     JSON.stringify(score) +
      //     ' at ' +
      //     scores.docs[i].id,
      // );
    }
    scoreObjects.sort((a, b) => b.timestamp - a.timestamp);

    return scoreObjects;
  } catch (e) {
    console.log('fetchScores error?', e);
    throw e;
  }
}

export function useScores(kullkiId?: string): KSocialScore[] | undefined {
  const queryResult = useQuery(['scores', kullkiId], () => fetchScores(kullkiId), {
    enabled: !!kullkiId,
  });
  if (queryResult.isLoading) {
    return;
  }
  if (queryResult.isError) {
    console.warn('we got an error? ' + queryResult.error);
    return;
  }
  return queryResult.data;
}

async function fetchTIPI(kullkiId?: string): Promise<TIPI> {
  // console.log(`fetchTIPI for user ${kullkiId}`);
  const userDocRef = firestore().collection('users').doc(kullkiId);
  const tipiDocRef = userDocRef.collection('user_data').doc('tipi');
  const tipiDoc = await tipiDocRef.get();
  return tipiDoc.data() as TIPI;
}

export function useTIPI(kullkiId?: string): TIPI | undefined {
  const queryResult = useQuery(['tipi', kullkiId], () => fetchTIPI(kullkiId), {
    enabled: !!kullkiId,
  });
  if (queryResult.isLoading) {
    return;
  }
  if (queryResult.isError) {
    console.warn('we got an error? ' + queryResult.error);
    return;
  }
  return queryResult.data;
}

async function fetchDemographics(kullkiId?: string): Promise<Demographics[]> {
  // console.log(`fetchDemographics for user ${kullkiId}`);
  const userDocRef = firestore().collection('users').doc(kullkiId);
  const demographicObjects = [];
  const docRef = userDocRef.collection('user_data').doc('demographics');
  const collectionRef = docRef.collection('snapshots');
  const demographics = await collectionRef.get();
  for (let i = 0; i < demographics.size; i++) {
    const demographic = demographics.docs[i].data() as Demographics;
    if (demographic) {
      demographicObjects.push(demographic);
    }
    // console.log(
    //   `fetchDemographics got ${JSON.stringify(demographic)} at ${
    //     demographics.docs[i].id
    //   }`,
    // );
  }

  demographicObjects.sort((a, b) => b.timestamp - a.timestamp);
  return demographicObjects;
}

export function useDemographics(kullkiId?: string): Demographics[] | undefined {
  const queryResult = useQuery(['demographics', kullkiId], () => fetchDemographics(kullkiId), {
    enabled: !!kullkiId,
  });
  if (queryResult.isLoading) {
    return;
  }
  if (queryResult.isError) {
    console.warn('we got an error? ' + queryResult.error);
    return;
  }
  return queryResult.data;
}

async function fetchDelegatingUsers(kullkiId?: string): Promise<User[] | undefined> {
  // console.log('fetchDelegatingUsers fetching for ' + kullkiId);

  const delegatesSnapshot = await firestore()
    .collectionGroup('delegates')
    .where('kullkiId', '==', kullkiId)
    .get();
  if (delegatesSnapshot.empty === true) {
    return [];
  }

  // This pulls /users/${kullkiId}/delegates/${kullkiId}/<DelegateInfo>, stores all the data away for post-processing
  const delegatingKullkiIds: string[] = [];
  const delegateInfo: {[key: string]: DelegatedUser} = {};
  delegatesSnapshot.docs.map(doc => {
    const data = doc.data() as DelegatedUser;
    const delegatingKullkiId = doc.ref.path.split('/')[1];
    // console.log('  fetchDelegatingUsers got ' + delegatingKullkiId);
    delegatingKullkiIds.push(delegatingKullkiId);
    data.delegatingUser = delegatingKullkiId;
    delegateInfo.delegatingKullkiId = data;
  });

  const delegatingUsersSnapshot = await firestore()
    .collection('users')
    .where('kullkiId', 'in', delegatingKullkiIds)
    .get();
  return delegatingUsersSnapshot.docs.map(doc => {
    const data = doc.data() as User;
    // install the delegate information here
    data.delegatedUsers = [delegateInfo[data.kullkiId]];
    return data;
  });
}

// Fetch the users connected to a given user, undefined until the query runs, then 0 or more
export function useConnectedUsers(kullkiId?: string): User[] | undefined {
  const queryResult = useQuery(['connectedUsers', kullkiId], () => fetchConnectedUsers(kullkiId), {
    enabled: !!kullkiId,
  });
  if (queryResult.isLoading) {
    return;
  }
  if (queryResult.isError) {
    console.warn('we got an error? ' + queryResult.error);
    return;
  }
  return queryResult.data;
}

async function fetchConnectedUsers(kullkiId?: string): Promise<User[] | undefined> {
  // console.log('fetchConnectedUsers fetching for ' + kullkiId);

  const userCollectionRef = firestore().collection('users');
  const users = userCollectionRef.where('connectedUsers', 'array-contains', kullkiId);
  try {
    const userData = await users.get();
    // console.log(`fetchConnectedUsers userData size? ${userData.size}`);
    // console.log('fetchConnectedUsers userDocs', JSON.stringify(userData.docs));
    const userDocs: User[] = [];
    for (let i = 0; i < userData.size; i++) {
      const userDoc = userData.docs[i];
      const userDocData = userDoc.data();
      // console.log('fetchConnectedUsers got doc:', JSON.stringify(userDoc));
      userDocs.push(userDocData as User);
    }
    const userDocResults = await Promise.resolve(userDocs);
    return sortUserArray(userDocResults);
  } catch (e) {
    console.log('fetchConnectedUsers - unable to get users?', e);
    return Promise.reject(e);
  }
}

// Fetch the score batches connected to a given user, undefined until the query runs, then 0 or more
export function useScoreBatches(kullkiId?: string): ScoreBatch[] | undefined {
  const queryResult = useQuery(['scoreBatches', kullkiId], () => fetchScoreBatches(kullkiId), {
    enabled: !!kullkiId,
    retry: false,
    cacheTime: 0,
  });
  if (queryResult.isLoading) {
    return;
  }
  if (queryResult.isError) {
    console.warn('we got an error? ' + queryResult.error);
    return;
  }
  return queryResult.data;
}

async function fetchScoreBatches(kullkiId?: string): Promise<ScoreBatch[] | undefined> {
  console.log('fetchScoreBatches fetching for ' + kullkiId);

  try {
    // We should use functions().getHttpsCallableFromURL but that is firebase-js-sdk v9 only
    // So call directly:
    const token = await auth().currentUser!!.getIdToken();

    const functionsAPIURL = `${config.getWebAppURIRoot()}/api/v1/scoring/batches`;
    const result = await fetch(functionsAPIURL, {
      headers: {
        'Content-Type': 'application/json',
        'X-Correlation-ID': v4(),
        Authorization: 'Bearer ' + token,
      },
      method: 'get',
    });

    let data;
    try {
      data = await result.json();
    } catch (e) {
      console.log('response was not JSON');
    }
    if (result.status !== 200 || data?.error) {
      if (data?.error) {
        console.log('data: ', JSON.stringify(data, null, 2));
        console.log('error: ', JSON.stringify(data.error, null, 2));
      }
      throw new Error(data?.error ?? result.status);
    }
    console.log('batches result:', JSON.stringify(data.data, null, 2));
    return data.data.scoreBatches;
  } catch (e) {
    console.log('fetchScoreBatches - unable to get scoreBatches?', e);
    return Promise.reject(e);
  }
}

// Fetch the score batch connected to a given user, undefined until the query runs, then 0 or 1
export function useScoreBatch(kullkiId?: string, batchId?: string): ScoreBatch | undefined {
  console.log(`useScoreBatch for ${kullkiId} batch ${batchId}`);
  const queryResult = useQuery(
    ['scoreBatch', kullkiId, batchId],
    () => fetchScoreBatch(kullkiId, batchId),
    {
      enabled: !!kullkiId && !!batchId,
      retry: false,
      cacheTime: 0,
    },
  );
  if (queryResult.isLoading) {
    return;
  }
  if (queryResult.isError) {
    console.warn('we got an error? ' + queryResult.error);
    return;
  }
  return queryResult.data;
}

async function fetchScoreBatch(
  kullkiId?: string,
  batchId?: string,
): Promise<ScoreBatch | undefined> {
  console.log('fetchScoreBatch fetching ' + batchId + ' for ' + kullkiId);

  try {
    // We should use functions().getHttpsCallableFromURL but that is firebase-js-sdk v9 only
    // So call directly:
    const token = await auth().currentUser!!.getIdToken();

    const functionsAPIURL = `${config.getWebAppURIRoot()}/api/v1/scoring/batches/${batchId}`;
    const result = await fetch(functionsAPIURL, {
      headers: {
        'Content-Type': 'application/json',
        'X-Correlation-ID': v4(),
        Authorization: 'Bearer ' + token,
      },
      method: 'get',
    });

    let data;
    try {
      data = await result.json();
    } catch (e) {
      console.log('response was not JSON');
    }
    if (result.status !== 200 || data?.error) {
      if (data?.error) {
        console.log('data: ', JSON.stringify(data, null, 2));
        console.log('error: ', JSON.stringify(data.error, null, 2));
      }
      throw new Error(data?.error ?? result.status);
    }
    console.log('batch result:', JSON.stringify(data.data, null, 2));
    return data.data;
  } catch (e) {
    console.log('fetchScoreBatch - unable to get scoreBatch?', e);
    return Promise.reject(e);
  }
}

// Fetch the users that delegate to the given user, undefined until query done, then 0 or more users
export function useDelegatingUsers(kullkiId?: string): User[] | undefined {
  console.log('useDelegatingUsers called');
  const queryResult = useQuery(
    ['delegatingUsers', kullkiId],
    () => fetchDelegatingUsers(kullkiId),
    {enabled: !!kullkiId},
  );
  if (queryResult.isLoading) {
    return;
  }
  if (queryResult.isError) {
    console.warn('we got an error? ' + queryResult.error);
    return;
  }
  return queryResult.data;
}

async function fetchUserInfo(kullkiId: string): Promise<UserInfo | undefined> {
  const userSnapshot = await firestore()
    .collection('users')
    .where('kullkiId', '==', kullkiId)
    .get();

  if (userSnapshot.size !== 1) {
    return undefined;
  }
  // console.log('user is: ' + JSON.stringify(userSnapshot.docs[0].data()));

  const user = userSnapshot.docs[0].data() as User;
  return {
    user,
    isBusiness: user.roles?.includes(UserType.Business) ?? false,
    isAdmin: user.roles?.includes(UserType.Admin) ?? false,
  };
}

export function useUserInfo(kullkiId: string): UserInfo | undefined {
  const userQueryResult = useQuery(['userInfo', kullkiId], () => fetchUserInfo(kullkiId));
  if (userQueryResult.isLoading) {
    return;
  }
  if (userQueryResult.isError) {
    console.warn('we got an error? ' + userQueryResult.error);
    return;
  }
  return userQueryResult.data;
}

async function fetchUsers(kullkiIds: string[]): Promise<User[]> {
  const snapshot = await firestore()
    .collection('users')
    .where('kullkiId', 'in', kullkiIds)
    .orderBy('firstName')
    .get();
  return (
    snapshot.docs.map(doc => {
      const data = doc.data() as User;
      return data as User;
    }) ?? []
  );
}

export function useUsers(kullkiIds: string[]): User[] | undefined {
  const queryResult = useQuery(['users', kullkiIds], () => fetchUsers(kullkiIds));
  if (queryResult.isLoading) {
    return;
  }
  if (queryResult.isError) {
    console.warn('we got an error? ' + queryResult.error);
    return;
  }
  return queryResult.data;
}

async function searchUsersByCitizenNumber(
  citizenNumber?: string,
  scoringAsUser?: string,
): Promise<User[]> {
  const snapshot = await firestore()
    .collection('users')
    .where('citizenNumber', '==', citizenNumber)
    .where('connectedUsers', 'array-contains', scoringAsUser)
    .get();
  return (
    snapshot.docs.map(doc => {
      const data = doc.data() as User;
      return data as User;
    }) ?? []
  );
}

export function useUsersByCitizenNumber(
  citizenNumber?: string,
  scoringAsUser?: string,
): User[] | undefined {
  const queryResult = useQuery(
    ['users', citizenNumber],
    () => searchUsersByCitizenNumber(citizenNumber, scoringAsUser),
    {
      enabled: citizenNumber !== undefined && scoringAsUser !== undefined,
    },
  );
  if (queryResult.isLoading) {
    return;
  }
  if (queryResult.isError) {
    console.warn('we got an error? ' + queryResult.error);
    return;
  }
  return queryResult.data;
}

// export function injectPeople(
//   holders: UserHolder[],
//   user?: User[],
// ): UserHolder[] {
//   let data = users;
//   if (data === undefined) {
//     const query = useQuery('users', fetchUsers);
//     if (query.isLoading || query.isError) return holders;
//     data = query.data;
//   }
//   return holders?.map(holder => {
//     if (holder.userId !== undefined) {
//       holder.user = data?.find(user =>
//         user.ref.path === holder.userId?.path ? true : false,
//       );
//     }
//     if (holder.userIds !== undefined) {
//       holder.users = [];
//     }
//     holder.userIds?.forEach(userId => {
//       if (holder.users === undefined) holder.users = [];
//       const match = data?.find(user =>
//         user.ref.path === userId.path ? true : false,
//       );
//       if (match !== undefined) holder.users.push(match);
//     });

//     return holder;
//   });
// }

export const cleanUserBeforeSaving = (user: User): User => {
  // clean out certain things from the user that might be set but that we don't want
  // to put in that record, since we store them seperately
  const cleanedUser = user;
  delete cleanedUser.tipi;
  delete cleanedUser.dateOfBirthFormatted;
  delete cleanedUser.registerTimeFormatted;
  delete cleanedUser.canCalculate;
  delete cleanedUser.currentScore;
  delete cleanedUser.profilePowerScore;
  delete cleanedUser.demographics;
  delete cleanedUser.delegatingUsers;
  return cleanedUser;
};

export const displayNameFromUser = (user?: User): string => {
  let displayName = user?.email ?? '';
  if (user?.firstName) {
    displayName = user.firstName + (user.secondName ? ' ' + user.secondName : '');
  }
  return displayName;
};

// Sort a user array alphabetically by "User.firstName User.lastName"
const sortUserArray = (users: User[]): User[] => {
  users.sort((a: User, b: User) => {
    const textA =
      (a.firstName ? `${a.firstName.toLocaleUpperCase()} ` : '') +
      a.secondName?.toLocaleUpperCase();
    const textB =
      (b.firstName?.toLocaleUpperCase() ? `${b.firstName.toLocaleUpperCase()} ` : '') +
      b.secondName?.toLocaleUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
  return users;
};
