import AppConfig from '../AppConfig';
import {translation} from './index';
require('dayjs/locale/en');

// Also check iOS permissions string translations in the file:
// <root>/ios/KScoreApp/en.lproj/InfoPlist.strings

export const english: translation = {
  loading: 'Loading',
  Success: 'Success',
  OK: 'OK',
  forgotPassword: 'Forgot Password?',
  createAnAccount: 'Create an Account',
  phoneSignIn: 'Sign in with phone number',
  phoneSignInTitle: 'Phone Sign In',
  signIn: 'Sign In',

  home: 'Home',
  NotFound: 'Page Not Found',
  PageNotFoundText: "We tried really hard, but we couldn't find that page.",
  gettingStarted: 'Getting Started',
  userInfo: 'User Info',
  settings: 'Settings',

  createAccountError: 'Create Account - Error',
  createAccountPasswordsDifferent: 'Passwords do not match',
  createAccountInstructions:
    'Create an account with your email and password. Once created, you will be automatically logged in to your profile.',
  emailLabel: 'Email Address',
  passwordLabel: 'Password',
  createAccountPasswordConfirmLabel: 'Confirm Password',
  passwordsDoNotMatch: 'Passwords do not match',
  createAccountCreating: 'Creating Account',
  createAccountCreate: 'Create Account',

  forgotPasswordInstructions: 'Enter your email address below to send a password reset email:',
  forgotPasswordLabel: 'Email Address',
  forgotPasswordError: 'Forgot Password - Error',
  forgotPasswordSending: 'Sending Password Reset',
  forgotPasswordSend: 'Send Password Reset',

  phoneVerificationCode: 'Verification Code',
  phoneVerificationConfirm: 'Confirm',
  phoneVerificationCountryInstructions: 'Touch to select phone number country',
  phoneVerificationNumberInstructions: 'Enter your phone number:',
  phoneVerificationNumberLabel: 'Phone Number',
  phoneVerificationNumberSubmit: 'Submit',

  signInSigningIn: 'Signing In',
  signInSignIn: 'Sign In',

  profileLastSignIn: 'Last sign-in',

  userUpdateError: 'User Update Error',
  userEmailVerify: 'Re-send',
  userEmailVerification: 'Verification',
  userEmailVerificationInstructions1: 'A verification email has been sent to',
  userEmailVerificationInstructions2: 'Please follow the instructions to verify your email address',
  userEmailVerificationBanner:
    'Please verify your email address to use the full features of this app. Click the re-send button below to re-send the verification email. If you did verify, press re-verify to update your status here.',
  userEmailVerifyTitle: 'Email Verifification',
  userEmailVerificationSuccess: 'You have successfully verified your email address.',
  userEmailVerificationFailure:
    'It appears your email is still not verified. Try re-sending the verification email and following the instructions in the email.',
  userEmailVerificationVerifyButton: 'Re-verify',
  userDisplayLabel: 'Display Settings:',
  userNameDisplayLabel: 'Display Name',
  userNameDisplayInstructions: 'Set a custom display name for a personalized greeting.',
  userNameDisplaySave: 'Save',
  userNameDisplayUpdatedTitle: 'Display Name Changed',
  userNameDisplayUpdateMessage: 'Your display name has been changed successfully.',
  userPasswordUpdateLabel: 'Password Update:',
  userPasswordInstructions:
    'Update your account password. For security purposes, please enter your current account password.',
  userPasswordCurrent: 'Current Password',
  userPasswordNew: 'New Password',
  userPasswordConfirm: 'Confirm New Password',
  userPasswordUpdate: 'Update',
  userSignOut: 'Sign Out',

  // Google auth messages
  googleAuthErrorTitle: 'Google Auth Error',
  googleAuthCancelled: 'Google authentication cancelled.',
  googleAuthInProgress: 'Google authentication already in progress.',
  googleAuthPlayServices: 'Google authentication requires Google Play services.',
  googleAuthConfigError: 'Google authentication is not configured correctly for this application.',
  // TODO get catalog of google error messages and translate them

  // Facebook auth messages
  facebookAuthErrorTitle: 'Facebook Auth Error',
  facebookAuthCancelled: 'Facebook authentication cancelled.',
  facebookAuthErrorMessage: 'We did not obtain an access token from Facebook.',
  // TODO get catalog of facebook error messages and translate them

  // Apple auth messages
  appleAuthErrorTitle: 'Apple Auth Error',
  appleAuthErrorMessage: 'Unable to obtain an identity token from Apple.',
  // TODO get catalog of Apple auth error codes and translate them

  // Firebase auth error messages - from firebase error catalog
  unknownError: 'An unexpected error occurred. Please try again.',
  'invalid-session': 'Invalid Session',
  'invalid-session-message': 'Your session is invalid, please login again',
  'registration-error': 'Registration Error',
  'user-created': 'User Registered',
  'user-created-message': 'New user account registered successfully',
  'change-password-email': 'Password Reset Link Sent',
  'change-password-email-message':
    'We have successfully sent a password reset link to your address. Please check your email to change your password, then try to login again.',
  'change-password-email-error': 'Password Reset Email Error',
  'change-password-email-error-message':
    'There was an error sending the email to reset your password. Please check the address and try again.',
  'change-password-error': 'Password Reset Error',
  'change-password-successful': 'Password Change Successful',
  'change-password-successful-message': 'You have successfully changed your password.',
  'logout-error': 'Logout Error',
  'login-error': 'Login Error',
  'email-send': 'Verification Email Sent',
  'email-send-message': 'A new verification link was sent to your email',
  'email-send-error': 'Error Verifying Email',
  'email-not-verified': 'Email Not Verified',
  'email-not-verified-message':
    'We are sorry, your email is still not verified. Please re-send and check your email for a link',
  'email-verified': 'Email Verified',
  'email-verified-message': 'Your email has been verified successfully',
  'phone-link-error': 'Error Linking Phone',
  'phone-verify-error': 'Error Verifying Phone',
  'phone-auth-error':
    'You must enter your full number, with country and area code, for example +14155551212',
  'phone-code-sent': 'SMS Code Sent',
  'phone-code-sent-message': 'The SMS verification code was sent successfully',
  'phone-code-auto': 'SMS Code Received',
  'phone-code-auto-message': 'The SMS verification code was retrieved successfully',
  'phone-link-success': 'Phone Verified',
  'phone-link-success-message': 'Your phone number has been successfully verified',
  'auth/firebase-auth': 'This application only works on devices with Google Play Services',
  'auth/captcha-check-failed': 'The CAPTCHA check failed. Please wait a moment, then try again',
  'auth/app-not-authorized':
    'This application is not permitted to use authentication. Contact Customer Support',
  'auth/credential-already-in-use':
    'That login is in use by another account already. You should login with the other account, then link this one.',
  'auth/invalid-action-code': 'Code expired, please try again',
  'auth/session-expired': 'Session expired, please try again',
  'auth/code-expired': 'Code expired, please try again',
  'auth/network-request-failed': 'Network request failed, please try again',
  'auth/expired-action-code': 'Code already used or malformed, please try again',
  'auth/invalid-phone-number': 'Invalid phone number',
  'auth/provider-already-linked': 'You have already connected this sign in method',
  'auth/invalid-verification-code':
    'The SMS code you entered was invalid. Please send the code again',
  'auth/invalid-verification-id':
    'The SMS code you entered was invalid. Please send the code again',
  'auth/invalid-email': 'Incorrect email format',
  'auth/email-already-in-use':
    'The email you entered is already in use. Please use a different email or recover the account with that email with "forgot password"',
  'auth/quota-exceeded':
    "This application has exceeded it's SMS quota! Please contact customer support",
  'auth/user-disabled': 'This account has been disabled',
  'auth/user-not-found': 'Email or password incorrect',
  'auth/unknown':
    'We are sorry, there was an error on the server with your request. Usually that means try again in a few minutes',
  'auth/wrong-password': 'Email or password incorrect',
  'auth/weak-password': 'Password must be at least 6 characters',
  'auth/too-many-requests':
    'Too many codes requested too quickly. Please wait before trying again.',
  'auth/invalid-credential':
    'Authentication failure. Please close the app, re-open, and try again.',

  Close: 'Close',
  GenderLabel: 'Gender',
  FAQTitle: 'Komp.ai FAQ',
  UpdateNotificationChannel: 'App Updates',
  UpdateNotificationChannelDesc: 'Notifications related to application software updates',
  NoUpdateAvailableTitle: 'Application up to date',
  NoUpdateAvailableText: 'You already have the most recent application version',
  UpdateAvailableTitle: `New Komp.ai${
    AppConfig.getReleaseStream() === 'prod' ? '' : ` ${AppConfig.getReleaseStream()}`
  } version available!`,
  UpdateAvailableText: 'There is a new version available. Please update.',
  UpdateLater: 'Later',
  NewsPageName: 'News',
  Cancel: 'Cancel',
  Update: 'Update',
  GPSNotificationChannel: 'Komp.ai GPS',
  GPSNotificationTitle: `Komp.ai${
    AppConfig.getReleaseStream() === 'prod' ? '' : ` ${AppConfig.getReleaseStream()}`
  } GPS`,
  GPSNotificationText: 'Improving your score with your movements...',
  About: 'About',
  NotificationsLabel: 'News',
  DashboardLabel: 'Home',
  DashboardProfileTitle: 'Update your profile',
  DashboardProfileText: 'When we know more about you, it is easier to generate a Komp.ai score.',
  Quiz: 'Quiz',
  DashboardQuizText:
    'In just 2 minutes, you can improve your Komp.ai score answering 10 questions.',
  Participate: 'Participate',
  Permissions: 'Permissions',
  DashboardPermissionsText: 'We need certain permissions to calculate a Komp.ai score.',
  Authorize: 'Authorize',
  EmailVerify: 'Verify Email',
  EmailVerifyTitle: 'Verify Email',
  EmailSent: 'Sent email to ',
  EmailCheckMail: 'Please check your email to complete registration',
  EmailCheckButton: 'Verified, continue',
  EmailResendButton: 'Re-send Email',
  EmailCorrectAddress: 'Change email address?',
  NeedHelp: 'Need help?',
  ForgotPassword: 'Forgot Password',
  ForgotPasswordTitle: 'Password Forgotten',
  EnterEmail: 'Enter your email',
  ForgotPasswordSend: 'Send password change email',
  AlreadyRegisteredLink: 'Already registered?',
  History: 'History',
  RegisterButton: 'Sign up with email',
  LandingPhrase1: 'Building ',
  LandingPhrase2: 'trust',
  LandingPhrase3: 'everywhere\nyou go',
  LandingButtonPreamble: "Register. It's easy and free!",
  LoginGoogleButton: 'Continue with Google',
  LoginFacebookButton: 'Continue with Facebook',
  SigninGoogleButton: 'Continue with Google',
  SigninFacebookButton: 'Continue with Facebook',
  LoginButton: 'Login',
  LoginTitle: 'Login',
  HomePageGreeting: 'Welcome',
  LoginFirstLoginTitle: 'Welcome to Komp.ai!',
  LoginFirstLoginText:
    'If this is your first time here, click "Create Account" below.\n\nHowever, if you already have an account with a different login method you should click "Cancel", login using your existing login methods, then use "Connect Account" to link them to this method. Otherwise you will have two separate Komp.ai accounts',
  LoginFirstLoginOtherAccountsButton: 'Cancel',
  LoginFirstLoginPleaseContinueButton: 'Create Account',
  LoginApplePrivacyTitle: 'Apple Privacy Notification',
  LoginApplePrivacyText:
    'Komp.ai allows you to generate a score that is useful in the real world, based on non-anonymous information such as your phone number, and possibly connected with other social accounts that are also not anonymous. Clicking "OK" means you accept connecting your Apple ID with non-anonymous information.',
  LoginEmailRequiredTitle: 'Email Address Required',
  LoginEmailRequiredText:
    'Use of Komp.ai services requires a valid email address, please make sure you allow permission for your email address and try this login method again.',
  Email: 'Email',
  Password: 'Password',
  Enter: 'Enter',
  ForgotPasswordLink: 'Forgot your password?',
  NotRegisteredLink: "Don't have an account? Register here",
  MandatoryFieldError: 'required field',
  Logout: 'Logout',
  LogoutText: 'End your session?',
  LogoutButton: 'Logout',
  DeleteAccount: 'Delete Account',
  DeleteAccountText: 'Delete your account and all associated data',
  DeleteAccountButton: 'Delete Account',
  DeleteAccountWarning:
    'Please be aware our privacy-oriented encryption means there is no possibility of account or data recovery.\n\nThe account will be deleted immediately but full data deletion takes approximately one day. Data related to specific transactions with another user will still be visible to that user as part of their history.',
  DeleteAccountSuccess:
    'Account deletion request successful. Account deletion in progress. You are welcome back any time you need a score in the future.',
  DeleteAccountReauthenticateTitle: 'Fresh Session Required',
  DeleteAccountReauthenticateText:
    'Sorry, some sensitive operations require a recent login. This session is old enough that you will have to logout, login again and re-try.',
  DeleteAccountPopup:
    'Because this action is irrevocable, we require a confirmation to make sure it is what you want.',
  PermissionDialogText:
    'The more sources of information we can use to calculate, the better our calculation will be',
  PermissionBlockedDialogText:
    'Some permissions are blocked. Komp.ai is able to generate much higher quality scores if all permissions are granted. Please alter permissions in the System Preferences, then grant permissions here again',
  PermissionTermsLink: 'Komp.ai score Terms and Conditions',
  SharingPermissionTermsLink: 'Sharing Komp.ai score Terms and Conditions',
  PermissionText:
    'Komp.ai score is based on device information - we need permission to access it. Permissions are optional, but fewer data sources generally means a lower score.\n\nWe fiercely guard your privacy. We only sell score reports not data. Data older than 6 weeks is deleted. You may delete your account and all data at any time.',
  PermissionAcceptStart: 'Accept',
  PermissionAcceptFinish: 'terms & conditions?',
  PermissionGrant: 'Grant Permission',
  PhoneVerify: 'Verify Phone',
  PhoneVerifySwitch:
    'You have already verified a phone. Enter a new number if your number has changed',
  DashboardPhoneVerifyText: 'Verify your phone number to increase confidence in the mobile data',
  PhoneNumberPrefix: '+1',
  PhoneVerifyNotLoggedIn: 'You should be logged in before verifying your phone. Log in...',
  PhoneVerifyLogin: 'Login',
  PhoneVerifySendCodeError: 'send code before verifying',
  PhoneNumberInstructions: 'Phone Number (format +1xxxyyyzzzz)',
  PhoneNumberSendCode: 'Send Code',
  PhoneNumberCodeWait:
    'Wait a few minutes for the SMS code before re-trying. If it never arrives, contact customer service.',
  PhoneNumberEnterCode: 'Enter your code',
  PhoneNumberVerify: 'Verify',
  ReturnToLogin: 'Return to Login',
  Register: 'Register',
  True: 'True',
  False: 'False',
  DemographicsCompleteTitle: 'Demographics Complete',
  DemographicsCompleteText: 'We have saved your answers successfully',
  DemographicsAnswers: 'Answers',
  DemographicsInstructions:
    'Please tell us more about yourself by answering the following demographic questions',
  DemographicsStartButton: 'Enter Demographic Info',
  DemographicsQuestion1: 'Do you have your own car?',
  DemographicsQuestion1Answer1: 'No',
  DemographicsQuestion1Answer2: 'Yes',
  DemographicsQuestion2: 'What is your gender?',
  DemographicsQuestion2Answer1: 'Female',
  DemographicsQuestion2Answer2: 'Male',
  DemographicsQuestion3: 'What is your relationship status?',
  DemographicsQuestion3Answer1: 'Married',
  DemographicsQuestion3Answer2: 'Divorced',
  DemographicsQuestion3Answer3: 'Single',
  DemographicsQuestion3Answer4: 'Civil Union',
  DemographicsQuestion4: 'How many children do you have?',
  DemographicsQuestion4Answer1: '0',
  DemographicsQuestion4Answer2: '1',
  DemographicsQuestion4Answer3: '2',
  DemographicsQuestion4Answer4: '3',
  DemographicsQuestion4Answer5: '4+',
  DemographicsQuestion5: 'What is your approximate salary?',
  DemographicsQuestion5Answer1: '$0-500USD/month',
  DemographicsQuestion5Answer2: '501-1000USD/month',
  DemographicsQuestion5Answer3: '1001-2000USD/month',
  DemographicsQuestion5Answer4: '2001+/USD/month',
  DemographicsQuestion5Answer5: 'Decline to answer',
  DemographicsQuestion6: 'Do you rent or own your own house?',
  DemographicsQuestion6Answer1: 'Rent',
  DemographicsQuestion6Answer2: 'Own house, no mortgage',
  DemographicsQuestion6Answer3: 'Own house, with mortgage',
  DemographicsQuestion6Answer4: 'Other (Family house, etc)',
  DemographicsQuestion6Answer5: 'Decline to answer',
  DemographicsQuestion7: 'What is your education level?',
  DemographicsQuestion7Answer1: 'Elementary',
  DemographicsQuestion7Answer2: 'High school',
  DemographicsQuestion7Answer3: 'Technical / Associates',
  DemographicsQuestion7Answer4: 'Bachelors',
  DemographicsQuestion7Answer5: 'Post-graduate',
  DemographicsQuestion8: 'Have you had a credit card?',
  DemographicsQuestion8Answer1: 'No',
  DemographicsQuestion8Answer2: 'Yes',
  QuizQuestion1: 'Extroverted, enthusiastic',
  QuizQuestion2: 'Irritable, argumentative',
  QuizQuestion3: 'Faithful, self-disciplined',
  QuizQuestion4: 'Anxious, easily upset',
  QuizQuestion5: 'Open to new experiences',
  QuizQuestion6: 'Reserved, quiet',
  QuizQuestion7: 'Thoughful, friendly',
  QuizQuestion8: 'Unorganized, careless',
  QuizQuestion9: 'Serene, emotionally stable',
  QuizQuestion10: 'Traditional, unimaginative',
  QuizAnswer1: 'Totally disagree',
  QuizAnswer2: 'Really disagree',
  QuizAnswer3: 'Disagree',
  QuizAnswer4: 'Neither agree nor disagree',
  QuizAnswer5: 'Agree',
  QuizAnswer6: 'Really agree',
  QuizAnswer7: 'Totally agree',
  QuizCompleteTitle: 'Quiz Complete',
  QuizCompleteText: 'We have saved your answers successfully',
  QuizAnswers: 'Answers',
  QuizInstructionsQuestion: 'What type of personality do you have?',
  QuizInstructionsQuestionPrompt: 'I see myself as a person that is...',
  QuizInstructions:
    'In under 2 minutes you can improve your Komp.ai score by answering 10 questions.\n\nJust move the slider to the answer that feels right, like the example below.',
  QuizLegend: 'Answer Key:',
  QuizStartButton: 'Begin Quiz',
  Send: 'Send',
  QuizResults: 'Quiz Results',
  QuizOTitle: 'Openness',
  QuizOInformation: 'Openness reflects your disposition to accept new and unusual experiences',
  QuizOHigh:
    'You appreciate creative, original, and innovative ideas. You look for experiences that take you out of your routine, and you enjoy thinking differently.',
  QuizOMedium:
    "Your way of thinking isn't especially unconventional or too conventional. You tend to think of yourself as a practical person, but open to new ideas and points of view.",
  QuizOLow:
    "You are a pragmatic person, direct, and conventional. You focus on facts and have little interest en theories and abstract ideas that don't have practical applications in the real world. In your free time you prefer activities where you can find tangible rewards.",
  QuizCTitle: 'Conscientiousness',
  QuizCInformation:
    'Conscientiousness is about how we control ourselves and how we act on our impulses.',
  QuizCHigh:
    'You are organized and self-disciplined. You like to make plans, follow routines and schedules. When you make decisions, you prefer to take time to consider all the alternatives and the possible outcomes.',
  QuizCMedium:
    'You can be distracted, which causes you to postpone your tasks or lose interest in completing them. But when the situation requires it, you are able to control your impulses and focus your attention to achieve your goals.',
  QuizCLow:
    "You are spontaneous and handle changes and unexpected situations well. You often find that rules are arbitrary and suffocating, so you don't mind breaking them",
  QuizETitle: 'Extraversion',
  QuizEInformation: 'Extraversion reflects the energy with which you relate to the outside world.',
  QuizEHigh:
    'You like being in constant motion, enjoy great parties and crowds. You are cheerful, optimistic and make friends easily. Because positive emotions move you so easily, you are particularly interested in achieving successes that bring you happiness or emotion.',
  QuizEMedium:
    'Public speaking does not bother you, if the objective is to express your opinion to defend your point of view. You prefer quiet places you can enjoy with your close circle of friends.',
  QuizELow:
    'You are reserved, quiet, and discreet. You tend to avoid noisy environments because you find them overwhelming. it is often difficult for you to express yourself and you prefer that others speak.',
  QuizATitle: 'Agreeableness',
  QuizAInformation: 'Agreeableness indicates your concern for the happiness of social groups.',
  QuizAHigh:
    'You are very empathic and you naturally identify with the emotions of others. You value cooperation, social harmony and assume that most people are fair and honest.',
  QuizAMedium:
    'You are respectful of the opinions of others so people turn to you to be heard. You prefer to avoid conflicts, to a point, but you can confront people if necessary.',
  QuizALow:
    'You prioritize your own needs and interests. You are direct in your communication style and do not mind openly confronting or challenging others. You value truth, justice and objective judgements based solely on reason.',
  QuizNTitle: 'Emotional Stability',
  QuizNInformation: 'Emotional stability shows your tendency to respond emotionally to events.',
  QuizNHigh:
    'You tend to stay calm and relaxed even in stressful situations that cause anxiety for others. You rarely worry about how things will turn out, and you face situations without fear of negative consequences.',
  QuizNMedium:
    'On more than one occasion you have felt the need to anchor yourself to reality through the example you see in other people. The emotional state of the moment greatly influences you, but you do not allow this to affect completion of your daily activities.',
  QuizNLow:
    'You tend to be more vulnerable in situations of stress and less able to overcome problematic situations. You are sensitive to what other people think about you, and their opinion can easily discourage you.',
  QuizYourResult: 'Your result:',
  QuizAverageResult: 'Average result:',
  QuizPersonalizedInformation: 'Personalized Information',
  UpdateLabel: 'Update App',
  UpdateAlertText: 'There is a new version available. Would you like to update?',
  ErrorAlert: 'There was an error',
  UpdateApplicationText: 'Update the application',
  UpdateInstalledVersion: 'Installed version:',
  UpdateProgress: 'Download Progress;',
  UpdateButton: 'Attempt Update',
  WelcomeTitle: 'Welcome!',
  Actions: 'Actions',
  WelcomeText:
    'The Komp.ai score is a new way to build trust between people.\n\nIn just a few minutes you can complete the basic tasks to calculate your score and share it with others.\n\nFollow these steps, with more explanation in each step:',
  WelcomeTaskList:
    '1. Complete your profile\n2. Grant permissions\n3. Enter demogragphic information\n4. Take a short quiz\n5. Use your score for deals',
  WelcomeContinueButton: "Let's Go!",
  OnboardingCompleteTitle: 'Congratulations!',
  OnboardingCompleteText:
    'You made it! Welcome to a new world where you can easily calculate and share scores on demand, all under your complete control.\n\nHere are some things you can do:',
  OnboardingCompleteQuizText: 'See your Quiz Results',
  OnboardingCompleteQuizButton: 'View Quiz',
  OnboardingCompleteScoreNotYet: 'Calculate your first Komp.ai score',
  OnboardingCompleteScoreShow: 'You have your first score!',
  ProfileLabel: 'Profile',
  ProfileUpdatedTitle: 'Profile Updated',
  ProfileUpdatedText: 'We have saved your profile successfully',
  ProfileCedulaLengthError: 'only 10 digits',
  ProfileCedulaInvalid: 'invalid format',
  ProfileBirthdateFormat: 'MM/dd/yyyy',
  ProfileFirstNameLabel: 'First Name',
  ProfileSecondNameLabel: 'Last Name',
  ProfileFullNameLabel: 'Full Name',
  ProfileProfileNameLabel: `Username: ${AppConfig.getWebAppURIRoot()}/{0}`,
  ProfileNameNotUnique: 'Name taken',
  ProfileNameTooShort: 'Minimum 8 letters',
  ProfileNameInvalidChars: 'Only use letters a-z, A-Z, y 0-9',
  ProfileNameResolutionFailure: 'Error checking availability',
  ProfileCedulaLabel: 'Passport or DL #',
  ProfileCedulaJustNumbersLabel: '(numbers only)',
  ProfileBirthdateLabel: 'Birthdate',
  ProfileRegisterDateLabel: 'Registration Date',
  ProfileYearLabel: ' Y',
  ProfileMonthLabel: ' M',
  ProfileDayLabel: ' D',
  ProfileNoPhone: '(Add this later in Connected Accounts)',
  ProfileAlreadyPermitted: 'This user may see your score',
  ProfileAlreadyGranted: "You may see this user's score",
  ProfileOwnPublicProfile: 'This is your profile',
  ProfileVisitProfileInApp: 'Open in the app to get the details!',
  ProfileReadRedirectExternalTitle: 'External Link',
  ProfileReadRedirectExternalText:
    "This does not look like a Komp.ai Profile link. Open '{0}' in default browser?",
  ProfileReadNotALinkTitle: 'Not a Link',
  ProfileReadNotALinkText: 'No URL in code. Please scan a Komp.ai Public Profile code',

  // MemberSinceLabel: 'Member since',
  MemberSinceLabel: '#SocialmenteKonfiable since',
  ProfilePower: 'Profile Power',

  LatestDeviceEventDateLabel: 'Most Recent Device Contact',
  LatestDeviceEventDateFormat: 'MM/dd/yyyy HH:mm', //:ss', // 'UTC'zz",
  ProfileSave: 'Update Profile',
  BirthdayQuestion: 'What is your birthday?',
  Calculate: 'Calculate',
  CalculateDisabled:
    'The dynamic calculation function is only available to select audiences at the moment. Please try again later.',
  CalculateText:
    'Calculate a Komp.ai score for {0} right now!\n\nPlease be patient, it may take a couple minutes.',
  CalculateInsufficientData:
    'Komp.ai score calculation requires a complete profile, a complete quiz, and demographics.',
  CalculateInsufficientDataSecondaryUser:
    'User {0} must complete those minimum requirements before you can calculate a score',
  CalculateButton: 'Calculate Now',
  CalculatingMessage: 'Calculating score now...',
  CalculatingError:
    'We are sorry, there was an error calculating your score. Please try again later.',
  CalculatingErrorNotEnoughData:
    'We are sorry, there was not enough data to calculate your score. The app must be installed with permissions granted.',
  CalculatingErrorDeletedUser:
    'We are sorry, this user has deleted their account, and it is no longer possible to calculate new scores.',
  CalculateCostExplanation:
    'Press "Calculate Now" to generate a fresh report based on the most recent data from the user. Your account will be debited for one calculation',
  ScoringTitle: 'Scoring',
  ScoringCurrentProfileTitle: 'Current Scoring Profile',
  ScoringSelectProfile: 'Select Scoring Profile',
  ScoringDelegateGreeting: 'You are scoring on behalf of {0}',
  ScoringDelegateList: 'Scoring Delegates',
  ScoringDelegateDetails: 'Scoring Delegate Details',
  ScoreTitle: 'Score',
  ShowScore: 'View Report',
  ShowScoreTitle: 'Score Report',
  ShowScoreBeta: 'in training',
  ShowScoreDate: 'Calculation Date',
  ShowScoreFirstName: 'Name',
  ShowScoreLastName: 'Last Name',
  ShowScoreDOB: 'Date of Birth',
  ShowScorePhoneNumber: 'Phone Number',
  ShowScoreNoScore: 'No Komp.ai scores for',
  ShowScoreUserDetailsTitle: 'User Details',
  ShowScoreScoreDetailsTitle: 'Score Details',
  ShowScoreContactsDetail: 'Contacts Analyzed',
  ShowScoreCalendarDetail: 'Calendar Analyzed',
  ShowScoreGPSDetail: 'GPS Events Analyzed',
  ShowScoreGPSHistogram: 'GPS Event Histogram',
  ShowScoreEventStreamDetail: 'Total Events Analyzed',
  ShowScoreEventStreamHistogram: 'Event Histogram',
  ShowScorePersonalityDetail: 'Personality Quiz Analyzed',
  ShowScoreDemographicsDetail: 'Demographic Information Analyzed',
  ShowScoreDevicesDetailTitle: 'Device Details',
  ShowScoreDevicesDetail: 'Devices Used in Analysis',
  ShowScorePhoneType: 'Device Type',
  ShowScorePhoneOSVersion: 'Operating System Version',
  ShowScorePhoneAppVersion: 'Komp.ai app Version',
  ShowScoreCarrierInfo: 'Cellular Carrier',
  ShowScoreDetailYes: 'Yes',
  ShowScoreDetailNo: 'No',
  ShowScoreScoreHistory: 'Score History',
  ShowScoreDataUploadDate: 'Updated',
  ShowScoreDataStale: 'Data Expired, App Inactive',
  ShowScoreMoreInfo: 'What does it mean? How can I improve it?',
  ScoreInfoTitle: 'Score Information',
  ScoreInfoMeaningTitle: 'What does the Komp.ai score mean?',
  ScoreInfoMeaningText:
    'The Kompai score is intended to predict whether a person will comply with their agreements or not. In a range from 0 to 1000, 850 and lower means a high risk. Between 851 and 900 the risk is medium-high. Between 901 and 950 the risk is medium, and if the score is higher than 951 we consider the risk to be low',
  ScoreInfoImproveTitle: 'How can I improve my score?',
  ScoreInfoImproveText:
    'The first step is to make sure there is enough data to calculate well, which means enabling permissions in the app if possible. The other way is old-fashioned hard work - having regular life patterns where possible and using your phone to help organize your life',
  ScoreInfoButton: 'Back to Report',
  Alliances: 'Deals',
  AllianceEstablish: 'Establish Alliance',
  UserAllianceEstablish: 'Permit To See Your Score',
  AllianceDescription: 'Get special offers with your Komp.ai score. Only valid in ',
  alliances_btnAlliancesEC: 'Ecuador',
  alliances_btnAlliancesCR: 'Costa Rica',

  Sorteamos: 'Giveaway:',
  DOS_PASAJES_GRATIS: '2 FREE TICKETS',

  Super_descuento_en_tus: 'Ready for your ',
  AIRPODS_NUEVOS: 'NEW AIRPODS?',

  AmazonEcho_Gratis: 'FREE Amazon Echo',
  Bono50_Gratis: '$50 Gift Card',
  PremiosSemanales: 'Weekly prizes',

  // CTA
  Los_Quiero: 'I want it',
  Participar: 'Participate',
  Me_interesa: "I'm interested",

  SoliciteCreditoAqui: 'Apply for your loan here',

  Completar: "Let's do it",
  KnowOur: 'See our',
  GrantApp: 'Grant',
  Promotions: 'PROMOTIONS',
  CompleteYour: 'Complete your',
  CompleteThe: 'Complete the',
  ProfileCaps: 'PROFILE',
  QuizCaps: 'QUIZ',

  Deal: 'Deal',
  ApplyForDeal: 'Apply For Deal',
  AllianceDetailSuccessTitle: 'Deal Connection Successful',
  AllianceDetailSuccessText:
    "Successfully granted permission for the company promoting this deal to run your score. Manage permission any time in 'Permitted Users'",
  AllianceEstablishedSuccessTitle: 'Connected Komp.ai!',
  AllianceEstablishedSuccessText: "You can manage these permissions any time in 'Permitted Users'",

  label_ValidOnly: 'Only valid in',

  Alliance_Andalucia_ValidOnly: 'Only valid in Ecuador',
  Alliance_CuencaCLUB_ValidOnly: 'Only valid in Ecuador',
  Alliance_Pago46_ValidOnly: 'Only valid in Chile',

  PermittedUsers: 'Permitted Users',
  PermittedUsersDesc: 'Users with permission to run your score',
  PermittedUsersNoUsers: 'You have not granted permission to anyone',
  PermittedUsersRemoveConnection: 'Revoke Permission',
  ConsultScoresRemoveConnectionText:
    "If you remove permission, you will not be able to consult this user's score anymore",
  ConsultScores: 'Consult Scores',
  ConsultScoresDesc: 'Users who have granted you permission to see their score',
  ConsultScoresUserListTitle: 'Connected Users List',
  ConsultScoresUserCount: '{0} users connected to {1}',
  ConsultScoresNoUsers: 'You have no connected users',
  ConsultScoresSearchPlaceholder: 'Search by {0}',
  ConsultScoresSearchNoResults: 'Search returned no results',
  ConsultScoresViewDetails: 'View Details',
  ConsultScoresConsult: "See User's Score",
  ConsultScoresRemoveConnection: 'Remove',
  ConsultScoresGetProfileLinkStats: 'Get Profile Stats',
  ConsultScoresSilentPush: 'Contact device',
  ConsultScoresSendNotification: 'Send Notification',

  ScoreBatchesListTitle: 'Score Batches List',
  ScoreBatchId: 'Batch ID',
  ScoreBatchCreatedDate: 'Created Date',
  ScoreBatchDetailsTitle: 'Scoring Batch Details',
  ScoreBatchCreate: 'Create New Score Batch',

  LoadingDataMessage: 'Loading data from the server, please be patient...',
  FetchingUsersMessage: 'fetching users',
  FunctionErrorTitle: 'Server error',
  FunctionErrorMessage: 'There was a problem communicating with the server, please try again.',
  ConnectedAccountsLabel: 'Connect Accounts',
  ConnectedAccountsConnect: 'Connect',
  ConnectedAccountsDisconnect: 'Disconnect',
  ConnectedAccountsPhone: 'SMS Verification',
  ConnectedAccountsEmail: 'Email Verification',
  ConnectedAccountsTitle: 'Social Login',
  ConnectedAccountsDesc:
    'Use your social media logins to quickly, easily, and securely access your Komp.ai services',
  ConnectedAccountsNoticeTitle: 'Disconnect a social account',
  ConnectedAccountsNoticeText:
    "If you disconnect this account, you'll no longer be able to use it to sign in to your Komp.ai account",
  ConnectedAccountsMustHaveLoginTitle: 'Login required',
  ConnectedAccountsMustHaveLoginText: "You can't delete the only login method.",
  ConnectedAccountsConnectionError: 'Network error',

  DebugChannel: 'Debug Notifications',
  DebugChannelDescription: 'Where debug notifications go in Dev and all debug builds',
  ConnectionRequestChannel: 'Connection Requests',
  ConnectionRequestChannelDescription:
    'We send notifications when other Komp.ai users want to connect with you to share their score',
  ImportantChannel: 'Important Notifications',
  ImportantChannelDescription: 'Used for important notifications from users or Komp.ai',

  ProfileShareSocialYour: '@',
  ProfileShareSocialDescription: 'Connect with me on Komp.ai',

  BillingTitle: 'Billing',
  BillingReport: 'Billing Report',
  BillingTransaction: 'Transaction',
  BillingTransactionId: 'ID',
  BillingTransactionDate: 'Date',
  BillingTransactionQuantity: 'Quantity',
  BillingTransactionBalance: 'Current Balance',
  BillingTransactionType: 'Type',
  BillingTransactionUserBy: 'Purchaser',
  BillingTransactionUserFor: 'Subject',
  BillingTransactionNote: 'Note',
  BillingTransactionDocument: 'Relation',
  BillingTransactionCount: '{0} transactions for {1}',
  BillingTransactionTypeCalculateReport: 'Calculate',
  BillingTransactionTypePurchaseReport: 'Purchase',
  BillingTransactionTypePurchaseExpiration: 'Expiration',

  // Dashboard Profile

  ProfileUserAppSettingsTitle: 'App and Profile Settings',
  ProfileTitle: 'User Details',
  ProfileSwitchTitle: 'Scoring Profiles',
  ProfileUserSettingsTitle: 'User Profile Settings',
  ProfileUserSettingsUserHeader: 'Personal Profile',
  profile_btnEditProfile: 'Edit Profile',
  profile_btnSharingProfile: 'Share / Read',
  profile_btnSharingProfileShare: 'Share',
  profile_btnSharingProfileRead: 'Read',
  profile_btnViewPublicProfile: 'View Public Profile',
  profile_btnViewPublicConnectedProfile: 'Permitted User View',
  profile_btnViewPublicUnconnectedProfile: 'General Public View',

  profile_btnTIPI_title: 'Personality Quiz',
  profile_btnTIPI_subtitle: 'Improve your Komp.ai score in 2 minutes',

  profile_btnDemographics_title: 'Demographics Info',
  profile_btnDemographics_subtitle: 'Tell us more about yourself',

  profile_btnUsuariosAliados_title: 'Permitted Users',
  profile_btnUsuariosAliados_subtitle: 'Can consult your Komp.ai score ',

  profile_btnCuentasConectadas_title: 'Connected Accounts',
  profile_btnCuentasConectadas_subtitle: 'Login easily',

  profile_btnPermisosAsignados_title: 'Assigned Permissions',
  profile_btnPermisosAsignados_subtitle: 'What data we consult',

  quiz_btnTakeQuiz_title: 'Take Quiz',
  quiz_btnTakeQuiz_subtitle: '10 questions to improve your score',

  quiz_btnRetakeQuiz_title: 'Retake Quiz',
  quiz_btnRetakeQuiz_subtitle: 'Ok, you have one more chance',

  quiz_btnQuizResults_title: 'Quiz Results',
  quiz_btnQuizResults_subtitle: "Let's see how you did",

  Score_congratulations_title: 'Congratulations',
  Score_detail_subtitle: 'Your Komp.ai score is',

  Score_YouAreReady_title: 'You are ready',
  Score_socialmentekonfiable_subtitle: 'To be #socialmentekonfiable',

  Score_CalculateKS_title: 'Calculate your Komp.ai Score',
  Score_YouAreReady_subtitle: 'You are ready to calculate',

  Score_NotReady_title: 'Something is missing...',
  Score_ReviewSteps_subtitle: 'Complete steps to calculate your score',

  Score_CalculateScores_title: "Consult other's scores",
  Score_CalculateScores_subtitle: 'See users that granted you permission',

  Score_ViewReport_subtitle: 'See your report',
  Score_Calculate_subtitle: 'Use current data to calculate a score',

  NoPermittedUsers_title: 'Apply for deals',
  NoPermittedUsers_subtitle: 'Use your Komp.ai score to get deals',

  ConsultScoresWebMissingLabel: 'Missing',
  DownloadConnectedUsers: 'Export Spreadsheet',
  MeetsCalculationRequirements: 'Calculation Requirements Met?',

  // card Conocer mas sobre ti
  cardConocerMas_title: 'You are the',
  cardConocerMas_subtitle: 'STAR',
  cardConocerMas_CTA: 'Tell us more about you',

  calculate_wait_0: 'Gathering data for calculation...',
  calculate_wait_1: 'Sending data to calculator...',
  calculate_wait_2: 'Calculating score...',

  // If you add jokes, change array of joke indices to match in ../components/controls/WaitingText.tsx
  waiting_joke_0: 'Contemplating the meaning of life...',
  waiting_joke_1: 'Calculating distance to the sun...',
  waiting_joke_2: 'Eating lunch...',
  waiting_joke_3: 'Checking email...',
  waiting_joke_4: 'Swiping away notifications...',
  waiting_joke_5: 'Making balloon animals...',
  waiting_joke_6: 'Binge-watching Tiger Kingdom...',
  waiting_joke_7: 'Correcting people on the internet...',
  waiting_joke_8: 'Negotiating international peace treaty...',
  waiting_joke_9: 'Firing up the tokomak...',

  // Notification translations
  NewConnectionNotificationTitle: 'You have a new Komp.ai!',
  NewConnectionNotificationBody: 'Now you can calculate scores for {0}',
};
