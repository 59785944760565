import {useQuery} from 'react-query';
import auth from '@react-native-firebase/auth';
// import analytics from '@react-native-firebase/analytics';

import config from '../AppConfig';
import {BillingTransaction} from '../models/IdentityModels';

// Fetch the users connected to a given user, undefined until the query runs, then 0 or more
export function useBillingTransactions(kullkiId?: string): BillingTransaction[] | undefined {
  const queryResult = useQuery(
    ['billingTransactions', kullkiId],
    () => fetchBillingTransactions(kullkiId),
    {
      enabled: !!kullkiId,
    },
  );
  if (queryResult.isLoading) {
    return;
  }
  if (queryResult.isError) {
    console.warn('we got an error? ' + queryResult.error);
    return;
  }
  return queryResult.data;
}

async function fetchBillingTransactions(
  kullkiId?: string,
): Promise<BillingTransaction[] | undefined> {
  // console.log('fetchConnectedUsers fetching for ' + kullkiId);

  // const userCollectionRef = firestore().collection('users');
  // const users = userCollectionRef.where('connectedUsers', 'array-contains', kullkiId);
  // try {
  //   const userData = await users.get();
  //   // console.log(`fetchConnectedUsers userData size? ${userData.size}`);
  //   // console.log('fetchConnectedUsers userDocs', JSON.stringify(userData.docs));
  //   const userDocs: User[] = [];
  //   for (let i = 0; i < userData.size; i++) {
  //     const userDoc = userData.docs[i];
  //     const userDocData = userDoc.data();
  //     // console.log('fetchConnectedUsers got doc:', JSON.stringify(userDoc));
  //     userDocs.push(userDocData as User);
  //   }
  //   const userDocResults = await Promise.resolve(userDocs);
  //   return sortUserArray(userDocResults);
  // } catch (e) {
  //   console.log('fetchConnectedUsers - unable to get users?', e);
  //   return Promise.reject(e);
  // }
  console.log(`fetching billing transactions for kullkiId: ${kullkiId}`);

  // We should use functions().getHttpsCallableFromURL but that is firebase-js-sdk v9 only
  // So call directly:
  const token = await auth().currentUser!!.getIdToken();

  const functionsAPIURL = `${config.getWebAppURIRoot()}/api/v1/billing/transactions/user/${kullkiId}`;
  // if (AppConfig.isDebugBuild()) {
  //   functionsAPIURL = 'http://localhost:5000/kscoreappdev/us-central1/calculate');
  //   console.log('emulator set');
  // }
  const result = await fetch(functionsAPIURL, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    method: 'get',
  });
  const data = (await result.json()).data;
  console.log('billing transactions result:', data);

  if (data?.error) {
    console.log('data: ', JSON.stringify(data, null, 2));
    console.log('error: ', JSON.stringify(data.error, null, 2));
    // FIXME need to log this for analytics + reproduction - kullki-id? correlation-id ?
    throw new Error(data.error);
  }

  return data.transactions as BillingTransaction[];
}
